import React, { useEffect, useState } from 'react';
import InfinityLoader from '../dropdownSearch/infinityLoader';
import API from '../../services/api';
import utils from '../../utils';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/user/actions';
import { getSelectedCompany } from '../../redux/company/actions';

const SiteGroupSelector = ({ onSelect, filterObject, selected, disabled }) => {
  const [currentItems, setCurrentItems] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const user = useSelector(getUser);
  const isAdmin = user.permissions['support'] || user.impersonator;
  const company = useSelector(getSelectedCompany);

  useEffect(() => {
    if (isOpen) {
      loadMoreItems();
    }
  }, [isOpen]);

  const handleApiResult = (filter, results) => {
    const fetchedData = results.projects.map(result => ({
      label: `${result.displayName}`,
      value: `"${result.id}"`
    }));

    // Reset list when filter changes
    if (filter !== currentFilter) {
      setCurrentFilter(filter);
      setCurrentItems(fetchedData);
    } else {
      const newSiteGroup = utils.mergeArraysByField(fetchedData, currentItems, 'value');
      setCurrentItems(newSiteGroup);
    }

    return results.nextPageToken;
  };

  const loadMoreItems = (nextPageToken = '', searchingBy = '') => {
    const query = encodeURI(`displayName ILIKE "%${searchingBy}%" OR id ILIKE "%${searchingBy}%"`);
    const filter = searchingBy !== '' ? query : '';

    if (filter !== currentFilter) {
      setCurrentItems([]);
    }
    if (isAdmin) {
      return API.siteGroups(40, nextPageToken, filter).then(results => {
        return handleApiResult(filter, results);
      });
    } else {
      return API.siteGroupsByCompany(company.id, 40, nextPageToken, filter).then(results => {
        return handleApiResult(filter, results);
      });
    }
  };

  return (
    <div className="sitegroup-selector">
      <InfinityLoader
        title={'Search Site Group'}
        leftAlign
        disabled={disabled}
        selected={selected}
        onSelect={selectedValue => onSelect(selectedValue, { ...filterObject, options: currentItems })}
        onLoading={loadMoreItems}
        onOpenChange={setIsOpen}
        totalSize={9999}
        options={currentItems}
      />
    </div>
  );
};

export default SiteGroupSelector;
