import config from '../../config';
import API from '../api';

const { baseUrl } = config.coreApi;

//#region Types
/**
 * @typedef Preferences
 * @type {Object}
 * @property {0 | 1 | 2 | 3} speedUnit - Speed unit preference.
 * @property {number} maxSessionTime - Maximum session time preference.
 * @property {number} maxDwellTime - Maximum dwell time preference.
 * @property {number} viewThreshold - View threshold preference.
 * @property {string} viewMetric - View metric preference.
 * @property {string} timezone - Timezone preference.
 * @property {number} minConfidenceGender - Minimum confidence level for gender detection.
 * @property {number} maxConfidenceGender - Maximum confidence level for gender detection.
 * @property {number} minConfidenceAge - Minimum confidence level for age detection.
 * @property {number} maxConfidenceAge - Maximum confidence level for age detection.
 * @property {number} minConfidenceEmotion - Minimum confidence level for emotion detection.
 * @property {number} maxConfidenceEmotion - Maximum confidence level for emotion detection.
 * @property {boolean} enableAnomalyDetection - Enable anomaly detection.
 * @property {Object} vehicleMultiplier - Vehicle multiplier preferences.
 * @property {number} vehicleMultiplier.bus - Multiplier for bus.
 * @property {number} vehicleMultiplier.car - Multiplier for car.
 * @property {number} vehicleMultiplier.motorcycle - Multiplier for motorcycle.
 */

/**
 * @typedef SiteGroup
 * @type {Object}
 * @property {string} id - ID of the project.
 * @property {string} name - Name of the project.
 * @property {string} displayName - Display name of the project.
 * @property {string} ownerId - Owner ID of the project.
 * @property {Object} labels - Labels associated with the project.
 * @property {string[]} tags - Tags associated with the project.
 * @property {number} devicesCount - Count of devices associated with the project.
 * @property {string} createTime - Creation time of the project.
 * @property {string} updateTime - Update time of the project.
 * @property {"INTEGRATION_NAME_UNSPECIFIED" | "BRIGHTAUTHORFACEV0" | "AYUDAFACEV1" | "BROADSIGNFACEV1" | "SIGNAGELIVEFACEV1" | "BRIGHTAUTHORFACEV1" | "BROADSIGNVEHICLEV1" | "SCALAFACEV0" | "HIVESTACKFACEV1"[]} enabledIntegrations - Enabled integrations for the project.
 * @property {"VEHICLERECOGNITIONV1" | "FACEV2" | "VEHICLECROWD" | "CROWDV3" | "VEHICLEDETECTIONV1"[]} enabledSolutions - Enabled solutions for the project.
 * @property {Preferences} preferences - Preferences for the project.
 * @property {string} provisioningToken - Provisioning token for the project.
 * @property {number} devicesCountLimit - Limit on the number of devices for the project.
 * @property {boolean} isSiteGroup - Indicates if the project is a site group.
 * @property {number} sitesCount - Count of sites associated with the project.
 * @property {string} companyId - Company ID associated with the project.
 * @property {string} description - Description of the project.
 * @property {string} companyDisplayName - Display name of the company associated with the project.
 */

/**
 * @typedef Binding
 * @type {Object}
 * @property {string[]} members - Array of members associated with the binding.
 * @property {string} role - Role associated with the binding.
 */

/**
 * @typedef Policy
 * @type {Object}
 * @property {Binding[]} bindings - Array of bindings associated with the resource.
 * @property {string} id - ID of the resource.
 * @property {string} resource - Resource identifier.
 */
//#endregion

//#region CREATE
/**
 * Creates a new site group.
 * @param {string} cid - Company ID.
 * @param {SiteGroup} siteGroup - Site group to be created.
 * @returns {SiteGroup} Created site group.
 */
const createSiteGroup = async (cid, siteGroup) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: JSON.stringify(siteGroup)
  }).then(res => res.json());
};
//#endregion

//#region READ
/**
 * Lists existing site groups by company.
 * @param {string} cid - Company ID.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @param {string} orderBy - Order expression.
 * @param {number} pageSize - Max amount of projects returned.
 * @returns {{projects: SiteGroup[], nextPageToken: string}} List of site groups by company.
 */
const getSiteGroupsByCompany = async (cid, pageSize = 40, page = '', filter = '', orderBy = 'displayName, id') => {
  await API.refreshTokenWhenNeeded();

  // Filter sample `displayName LIKE "%Defau%"`
  const url = `${baseUrl}/companies/${cid}/projects?pageSize=${pageSize}&pageToken=${page}&filter=${filter}&orderBy=${orderBy}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Lists existing site groups.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @param {string} orderBy - Order expression.
 * @returns {{projects: SiteGroup[], nextPageToken: string}} List of site groups.
 */
const getSiteGroups = async (pageSize = 40, page = '', filter = '', orderBy = 'displayName, id') => {
  await API.refreshTokenWhenNeeded();

  // Filter sample `displayName LIKE "%Defau%"`
  const url = `${baseUrl}/projects?pageSize=${pageSize}&pageToken=${page}&filter=${filter}&orderBy=${orderBy}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Gets a single site group.
 * @param {string} cid - Company ID.
 * @param {string} pid - Site group ID.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @returns {SiteGroup} Site group.
 */
const getSiteGroup = async (cid, pid) => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/companies/${cid}/projects/${pid}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Gets project unique application versions for admprovider or malos
 * @param {string} cid - Company ID.
 * @param {string} pid - Site group ID.
 * @param {'admprovider' | 'malos'} appName - Name of the app.
 * @returns {{applicationVersions: string[], nextPageToken: string}} version.
 */
const getVersion = async (cid, pid, appName, page, filter) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/appversions/${appName}?pageSize=40&pageToken=${page}&filter=${filter}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Gets datasources
 * @param {string} cid - Company ID.
 * @param {string} pid - Site group ID.
 * @return {{datasources: "DATASOURCE_UNSPECIFIED" | "TRAFFIC_M" | "AUDIENCE_M" | "WIFI_M" | "MOBILE_M"[] , nextPageToken: string}} datasources in the site group
 */
const getDatasources = async (cid, pid) => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/companies/${cid}/projects/${pid}/datasources`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Gets a single site group stats
 * @param {string} cid - Company ID.
 * @param {string} pid - Site group ID.
 * @return {{ deviceCountByStatus: { detecting: number; downloadingModels: number; inactive: number; offline: number; online: number; total: number }; licenseCountByStatus: { expired: number; valid: number } }}
 */

const getSiteGroupStats = async (cid, pid) => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/companies/${cid}/projects/${pid}/stats`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/** Gets a sitegroup policy
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @returns { {id: string, resource: string, bindings: [{role:"admobilize.owner" | "admobilize.user", members: string[]}]}} sitegroup policies.
 */
const getPolicy = async (cid, pid) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}:getPolicy`;

  return fetch(url, {
    method: 'PATCH',
    headers: API.headers
  }).then(res => res.json());
};
//#endregion

//#region UPDATE

/** Sets a sitegroup policy
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {Policy} policy - Sitegroup policy.
 * @returns { {id: string, resource: string, bindings: [{role:"admobilize.owner" | "admobilize.user", members: [string]}]}} sitegroup policies.
 */
const setPolicy = async (cid, pid, policy) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}:setPolicy`;

  return fetch(url, {
    method: 'PATCH',
    headers: API.headers,
    body: JSON.stringify(policy)
  }).then(res => res.json());
};

/** Updates a single site group
 * @param {string} cid - Company ID.
 * @param {SiteGroup} siteGroup - Site group to be updated
 * @param {string[]} fields - Site group fields being updated. Comma separated.
 * @returns {SiteGroup} updated site group.
 */
const updateSiteGroup = async (cid, siteGroup, fields) => {
  await API.refreshTokenWhenNeeded();
  const paths = `updateMask=${fields.join(',')}`;
  const body = {};
  fields.forEach(field => {
    body[field] = siteGroup[field];
  });
  const url = `${baseUrl}/companies/${cid}/projects/${siteGroup.id}?${paths}`;

  return fetch(url, {
    method: 'PATCH',
    headers: API.headers,
    body: JSON.stringify(body)
  }).then(res => res.json());
};
//#endregion

//#region DELETE
/**
 * Deletes a site group.
 * @param {string} cid - Company ID.
 * @param {string} pid - Site group ID.
 * @returns {{}}
 */
const deleteSiteGroup = async (cid, pid) => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/companies/${cid}/projects/${pid}`;
  return fetch(url, {
    method: 'DELETE',
    headers: API.headers
  }).then(res => res.json());
};
//#endregion

export {
  createSiteGroup,
  getSiteGroupsByCompany,
  getSiteGroups,
  getSiteGroup,
  getVersion,
  getDatasources,
  getSiteGroupStats,
  getPolicy,
  setPolicy,
  updateSiteGroup,
  deleteSiteGroup
};
