import { appendState, setState } from '../../utils/redux.js';
import types from '../types.js';

const initialState = {
  devices: [],
  allDevices: [],
  licenses: []
};

const deviceReducer = (state = initialState, action) => {
  const { CREATE_DEVICE, LOAD_DEVICES, UPDATE_DEVICES, LOAD_LICENSES, LOAD_ALL_DEVICES } = types;
  switch (action.type) {
    case LOAD_DEVICES:
    case UPDATE_DEVICES:
      return setState(state, 'devices', action.payload);
    case LOAD_ALL_DEVICES:
      return setState(state, 'allDevices', action.payload);
    case CREATE_DEVICE:
      return appendState(state, 'devices', action.payload);
    case LOAD_LICENSES:
      return setState(state, 'licenses', action.payload);
    default:
      return state;
  }
};

export default deviceReducer;
