import config from '../../config';
import API from '../api';

const { baseUrl } = config.deviceManagementApi;

const listLicenses = () => {
  const url = `${baseUrl}/licenses/?page_size=-1`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

const getLicensesFromProject = projectId => {
  const url = `${baseUrl}/projects/${projectId}/licenses`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

const createLicenses = licenses => {
  const url = `${baseUrl}/licenses`;
  return fetch(url, {
    headers: API.headers,
    method: 'POST',
    body: JSON.stringify(licenses)
  }).then(res => API.generateResponse(res));
};

const updateLicense = (license, fields) => {
  const url = `${baseUrl}/licenses/${license.id}?${fields.map(field => `update_mask=${field}`).join('&')}`;
  return fetch(url, {
    method: 'PUT',
    headers: API.headers,
    body: JSON.stringify(license)
  }).then(res => API.generateResponse(res));
};

export { listLicenses, createLicenses, updateLicense, getLicensesFromProject };
