import React from 'react';
import icons from '../../assets/icons';
import API from '../../services/api';
import { isBefore, add } from 'date-fns';

const actionButtons = (companyId, report, loadReports, displayDeleteDialog, displayAlertMessage) => {
  const buttons = [
    // RESTART/PAUSE REPORT
    <div
      key={'act-btn-stop'}
      className={'btn btn-secondary action-btn'}
      onClick={e => {
        if (isReportActive(report)) {
          // Pause report routine
          API.pauseReport(companyId, report.projectId, report.table, report.id)
            .then(res => {
              displayAlertMessage(`The report has been paused.`, 'success');
              loadReports(res.projectId); // Refresh report list
            })
            .catch(err => {
              displayAlertMessage(`Something went wrong. Please try again.`, 'error');
              console.error(err);
            });
        } else {
          // Restart report routine
          API.restartReport(companyId, report.projectId, report.table, report.id)
            .then(res => {
              displayAlertMessage(`The report is now unpaused.`, 'success');
              loadReports(res.projectId); // Refresh report list
            })
            .catch(err => {
              displayAlertMessage(`Something went wrong. Please try again.`, 'error');
              console.error(err);
            });
        }
        e.stopPropagation();
      }}
    >
      <img src={isReportActive(report) ? icons.stop : icons.play} alt="" height={15} />
    </div>
  ];

  // DOWNLOAD REPORT
  const download = (
    <div
      key={'act-btn-download'}
      className={'btn btn-secondary action-btn'}
      onClick={e => {
        if (report.lastReportUrl) {
          window.open(report.lastReportUrl);
        }
        e.stopPropagation();
      }}
    >
      <img src={icons.download} alt="" height={15} />
    </div>
  );

  // CANCEL REPORT
  const cancel = (
    <div
      key={'act-btn-delete'}
      className={'btn btn-secondary action-btn'}
      onClick={e => {
        displayDeleteDialog(report);
        e.stopPropagation();
      }}
    >
      <img src={icons.del} alt="" height={15} />
    </div>
  );

  // Download btn should be available only if report was sent already and has not expired
  if (report.lastSent) {
    const reportExpired = isBefore(add(new Date(report.lastSent), { days: 30 }), new Date());
    if (!reportExpired) {
      buttons.push(download);
    }
  }
  buttons.push(cancel);

  return <div className="action-buttons-options">{buttons}</div>;
};

const isReportActive = report => {
  if (report) {
    return report.status === 'active';
  }
  return false;
};

export default actionButtons;
