import React, { useState, useEffect, useCallback, useRef } from 'react';
import components from '../../components';
import './styles.scss';
import utils from '../../utils';
import icons from '../../assets/icons';
import reportUtils from '../../utils/report';
import API from '../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import types from '../../redux/types';

import { getSelectedProject, getProjects } from '../../redux/projects/actions';

import actionButtons from './actionButtons';
import columns from './columns';
import reportSections from './reportSections';
import { getSelectedCompany } from '../../redux/company/actions';

const intervals = {
  Mi: 'Minute',
  H: 'Hour',
  D: 'Day',
  W: 'Week',
  M: 'Month',
  Y: 'Year'
};

const { Table, DetailsDrawer, CustomConfirm } = components;

const ReportsContainer = ({ lastUpdated }) => {
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [selectedReport, setSelectedReport] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [displayReportDetails, setDisplayReportDetails] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [toBeDeletedReport, setToBeDeletedReport] = useState(null); // Report you want to delete

  const projects = useSelector(getProjects);
  const project = useSelector(getSelectedProject);
  const company = useSelector(getSelectedCompany);

  const dispatch = useDispatch();

  const fetchIdRef = useRef(0);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && project) {
      setLoading(true);
      API.getReports(company.id, project.id)
        .then(res => {
          // Load table with report data
          const reports = reportUtils.parse(res.reports, projects);
          setData(reports);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    }
    return () => {
      isMounted = false;
    };
  }, [project, projects, lastUpdated]);

  const loadReports = projectId => {
    setLoading(true);
    API.getReports(company.id, projectId)
      .then(res => {
        // Load table with report data
        const reports = reportUtils.parse(res.reports, projects);
        setData(reports);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  };

  const displayAlertMessage = (message, type) => {
    dispatch({
      type: types.SET_ALERT,
      payload: utils.generateAlert(message, type)
    });
  };

  const displayDeleteDialog = report => {
    // Add the report to be deleted on the state.
    // The action to actually delete it will be called by the confirm dialog
    setToBeDeletedReport(report);
    setOpenDeleteDialog(true);
  };

  const deleteReport = () => {
    // Soft delete report - It's called cancel from API side but for the user report won't be available anymore
    API.cancelReport(company.id, toBeDeletedReport.projectId, toBeDeletedReport.table, toBeDeletedReport.id)
      .then(res => {
        loadReports(res.projectId);
        displayAlertMessage('The Report has been deleted.', 'success');
        setDisplayReportDetails(false);
      })
      .catch(err => {
        displayAlertMessage('Something went wrong. Please try again.', 'error');
        console.error(err);
      })
      .finally(() => setOpenDeleteDialog(false));
  };

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      if (data.length === 0) return;
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Generate sort config object
      const sortByQuery = sortBy[0];
      const sortByConfig = utils.generateSortByConfig(sortByQuery, 'deviceName', columns);

      // Perform data sort
      const items = utils.sort(data, sortByConfig);

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;

        // Retrieve fetched data
        setItems(items.slice(startRow, endRow));

        // Compute total number of items
        setItemsCount(items.length);

        // Compute total page count
        setPageCount(Math.ceil(items.length / pageSize));
      }
    },
    [data]
  );

  const getReportInterval = report => {
    const interval = { id: '', name: '' };

    Object.keys(intervals).forEach(key => {
      if (report.interval === String(intervals[key]).toUpperCase()) {
        interval.id = key;
        interval.name = intervals[key];
      }
    });

    return interval;
  };

  return (
    <div className="reports-container">
      <div className="toolbar">
        <div className="title">
          <img src={icons.reportOutlineDark} alt="Reports" />
          <h2>Reports</h2>
        </div>
        <a href="/#/charts" className="btn btn-secondary">
          Go to Analytics Charts
        </a>
      </div>
      {!loading && (!data || data.length === 0) && (
        <div className="no-data-message">
          <h1>No reports</h1>
        </div>
      )}
      {data && data.length > 0 && (
        <div className="reports-content">
          <Table
            dataLabel={'reports'}
            columns={columns}
            data={items}
            fetchData={fetchData}
            loading={loading}
            pageCount={pageCount}
            itemsCount={itemsCount}
            hasInteractiveRows
            isItemDetailsClosed={!displayReportDetails}
            onItemSelected={(report, selectRowCb) => {
              setDisplayReportDetails(true);
              setSelectedReport({ ...report, interval: getReportInterval(report) });
              // case user choose to change selected item and cancel editing
              // the table will change the selected row
              if (selectRowCb) selectRowCb();
            }}
            actionButtons={report =>
              actionButtons(company.id, report, loadReports, displayDeleteDialog, displayAlertMessage)
            }
          />
          <DetailsDrawer
            title="Report Details"
            isEditing={false}
            onFocusChange={() => {}}
            onClose={() => {
              setDisplayReportDetails(false);
              setSelectedReport(null);
            }}
            sections={reportSections(company.id, selectedReport, loadReports, project)}
            hidden={!displayReportDetails}
          />
        </div>
      )}
      <CustomConfirm
        messageType="deleteReport"
        cancelCb={() => setOpenDeleteDialog(false)}
        confirmCb={deleteReport}
        messageContext={toBeDeletedReport ? toBeDeletedReport.name : ''}
        isOpen={openDeleteDialog}
      />
    </div>
  );
};

export default ReportsContainer;
