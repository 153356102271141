import config from '../../config';
import API from '../api';

const { baseUrl } = config.notificationApi;

const getNotifications = () => {
  const url = `${baseUrl}/notifications?page_size=-1`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

const getNotificationTypes = () => {
  const url = `${baseUrl}/notifications:types`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

const getNotificationPreferences = () => {
  const url = `${baseUrl}/notifications:preferences`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

const setNotificationPreferences = newPreferences => {
  const url = `${baseUrl}/notifications:preferences`;
  return fetch(url, {
    headers: API.headers,
    method: 'PUT',
    body: JSON.stringify({
      preferences: newPreferences
    })
  }).then(res => API.generateResponse(res));
};

const archiveNotification = (notificationsIds, archive) => {
  const url = `${baseUrl}/notifications:archive`;
  return fetch(url, {
    headers: API.headers,
    method: 'PATCH',
    body: JSON.stringify({
      ids: notificationsIds,
      archive: archive
    })
  }).then(res => API.generateResponse(res));
};

const deleteNotification = notificationsIds => {
  const url = `${baseUrl}/notifications`;
  return fetch(url, {
    headers: API.headers,
    method: 'DELETE',
    body: JSON.stringify({
      ids: notificationsIds
    })
  }).then(res => API.generateResponse(res));
};

export {
  getNotifications,
  getNotificationTypes,
  getNotificationPreferences,
  setNotificationPreferences,
  archiveNotification,
  deleteNotification
};
