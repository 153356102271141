import React from 'react';
import CustomTextArea from '../../../components/customTextArea';
import CustomButton from '../../../components/customButton';
import IntegrationSection from './integrationSection';

export default function osSteps(os, newDevices, setNewDevices) {
  //TODO: Update install and provision commands for each OS.
  const commandStrings = {
    windows: {
      install: `# Add the AdMobilize APT repository key and source list
      $ curl -L https://apt.admobilize.com/doc/apt-key.gpg | sudo tee /usr/share/keyrings/admobilize-archive-keyring.gpg && $ echo "deb [signed-by=/usr/share/keyrings/admobilize-archive-keyring.gpg] https://apt.admobilize.com/ubuntu $(lsb_release -sc) main" | sudo tee /etc/apt/sources.list.d/admobilize.list && # Update the package index and install
      $ sudo apt-get update && sudo apt-get install admobilize-vision-balena`,
      provision: `admprovider --stdout --loglevel=DEBUG config set --provision=ajehaj204 --application-id=traffic --device-name=Street 1`
    },
    linux: {
      install: `# Add the AdMobilize APT repository key and source list
      $ curl -L https://apt.admobilize.com/doc/apt-key.gpg | sudo tee /usr/share/keyrings/admobilize-archive-keyring.gpg && $ echo "deb [signed-by=/usr/share/keyrings/admobilize-archive-keyring.gpg] https://apt.admobilize.com/ubuntu $(lsb_release -sc) main" | sudo tee /etc/apt/sources.list.d/admobilize.list && # Update the package index and install
      $ sudo apt-get update && sudo apt-get install admobilize-vision-balena`,
      check: 'systemctl is-active admupdater',
      provision: `admprovider --stdout --loglevel=DEBUG config set --provision=ajehaj204 --application-id=traffic --device-name=Street 1`
    }
  };
  switch (os) {
    case 'windows':
      return [
        {
          title: 'Installing AdMobilize Vision on Windows',
          label: 'Before running the command, make sure to be on the devices that will run the application.',
          body: (
            <div className="os-step">
              <div className="instruction">
                <span>1.</span>
                <p>
                  Press <b>Windows + R</b> type <b>“cmd”</b> and press <b>ctrl + shift + enter</b> to open{' '}
                  <b>Command Prompt</b> (or use the <b>Windows Search</b> to find it).
                </p>
              </div>
              <div className="instruction">
                <span>2.</span>
                <p>
                  <b>Copy and paste</b> the code bellow and then press <b>Enter</b> to install the programs:
                </p>
              </div>
              <div className="code-snippet">
                <CustomTextArea
                  id="provision-device-step-1"
                  value={commandStrings.windows.install}
                  isEditable={false}
                  rows={4}
                />
                <CustomButton title="Copy code" icon={'copy'} classes={'btn btn-secondary'} />
              </div>
              <div className="instruction">
                <span>3.</span>
                <p>
                  After running the AdmUpdater installation, wait around <b>5 minutes</b> to confirm if{' '}
                  <b>admupdater</b> and <b>malos-vision</b> are running in <b>Task Manager</b>.
                </p>
              </div>
              <div className="instruction">
                <p>
                  If you had any trouble with previous steps, check out our{' '}
                  <a href="https://documentation.admobilize.com">documentation</a>
                </p>
              </div>
            </div>
          ),
          previousButtonLabel: 'Previous',
          nextButtonLabel: 'Next Step'
        },
        {
          title: 'Provisioning Your Device',
          label: "Before running the command, make sure you're on the device that will run the application.",
          body: (
            <div className="os-step">
              <div className="instruction">
                <span>1.</span>
                <p>
                  Press <b>Windows + R</b> type <b>“cmd”</b> and press <b>ctrl + shift + enter</b> to open{' '}
                  <b>Command Prompt</b> (or use the <b>Windows Search</b> to find it).
                </p>
              </div>
              <div className="instruction">
                <span>2.</span>
                <p>
                  <b>Copy and paste</b> the code bellow and then press <b>Enter</b> to provision the device:
                </p>
              </div>
              <div className="code-snippet">
                <CustomTextArea
                  id="provision-device-step-2"
                  value={commandStrings.windows.provision}
                  isEditable={false}
                  rows={2}
                />
                <CustomButton title="Copy code" icon={'copy'} classes={'btn btn-secondary'} />
              </div>
              <div className="instruction">
                <p>
                  If you had any trouble with previous steps, check out our{' '}
                  <a href="https://documentation.admobilize.com">documentation</a>
                </p>
              </div>
            </div>
          ),
          previousButtonLabel: 'Previous Step',
          nextButtonLabel: 'Confirm'
        }
      ];
    case 'linux':
      return [
        {
          title: 'Installing AdMobilize Vision on Linux',
          label: "Before running the command, make sure you're on the device that will run the application",
          body: (
            <div className="os-step">
              <div className="instruction">
                <span>1.</span>
                <p>
                  Press <b>Ctrl + Alt + T</b> to open Linux terminal
                </p>
              </div>
              <div className="instruction">
                <span>2.</span>
                <p>
                  <b>Copy and paste</b> the code bellow and then press <b>Enter</b> to install the programs:
                </p>
              </div>
              <div className="code-snippet">
                <CustomTextArea
                  id="provision-device-step-1"
                  value={commandStrings.linux.install}
                  isEditable={false}
                  rows={4}
                />
                <CustomButton title="Copy code" icon={'copy'} classes={'btn btn-secondary'} />
              </div>
              <div className="instruction">
                <span>3.</span>
                <p>
                  To confirm if <b>admupdater</b> and <b>malos-vision</b> are running by pasting this code bellow and
                  see shows <b>“active”</b> status:
                </p>
              </div>
              <div className="code-snippet">
                <CustomTextArea
                  id="provision-device-step-2"
                  value={commandStrings.linux.check}
                  isEditable={false}
                  rows={1}
                />
                <CustomButton title="Copy code" icon={'copy'} classes={'btn btn-secondary'} />
              </div>
              <div className="instruction">
                <p>
                  If you had any trouble with previous steps, check out our{' '}
                  <a href="https://documentation.admobilize.com">documentation</a>
                </p>
              </div>
            </div>
          ),
          previousButtonLabel: 'Previous',
          nextButtonLabel: 'Next step'
        },
        {
          title: 'Provisioning Your Device',
          label: "Before running the command, make sure you're on the device that will run the application",
          body: (
            <div className="os-step">
              <div className="instruction">
                <span>1.</span>
                <p>
                  Press <b>Ctrl + Alt + T</b> to open Linux terminal
                </p>
              </div>
              <div className="instruction">
                <span>2.</span>
                <p>
                  <b>Copy and paste</b> the code bellow and then press <b>Enter</b> to provision the device:
                </p>
              </div>
              <div className="code-snippet">
                <CustomTextArea
                  id="provision-device-step-2"
                  value={commandStrings.linux.provision}
                  isEditable={false}
                  rows={2}
                />
                <CustomButton title="Copy code" icon={'copy'} classes={'btn btn-secondary'} />
              </div>
              <div className="instruction">
                <p>
                  If you had any trouble with previous steps, check out our{' '}
                  <a href="https://documentation.admobilize.com">documentation</a>
                </p>
              </div>
            </div>
          ),
          previousButtonLabel: 'Previous Step',
          nextButtonLabel: 'Confirm'
        }
      ];
    case 'balena':
      return newDevices.length === 1 
        ? [
            {
              title: 'Installing AdMobilize Vision on Balena',
              label: 'To set up each device, AdMobilize needs Device Name, APP and Balena UUID',
              body: (
                <IntegrationSection
                  step={'install'}
                  integration={'balena'}
                  newDevices={newDevices}
                  setNewDevices={setNewDevices}
                />
              ),
              previousButtonLabel: 'Previous',
              nextButtonLabel: 'Confirm',
              isNextDisabled: firstDevice => {
                return !firstDevice || firstDevice.integration.hasErrors;
              }
            }
          ]
      : [
          {
            title: 'Installing Your Device',
            label: 'To set up many devices, AdMobilize needs each Device Name, APP and Balena UUID',
            body: (
              <IntegrationSection
                step={'provision'}
                integration={'balena'}
                newDevices={newDevices}
                setNewDevices={setNewDevices}
              />
            ),
            previousButtonLabel: 'Previous Step',
            nextButtonLabel: 'Confirm'
          }
      ]
    case 'brightsign':
      return newDevices.length === 1 
      ? [
          {
            title: 'Installing AdMobilize Vision on BrightSign',
            label: 'To set up each device, AdMobilize needs the mac address and serial number from the device',
            body: (
              <IntegrationSection
                step={'install'}
                integration={'brightsign'}
                newDevices={newDevices}
                setNewDevices={setNewDevices}
              />
            ),
            previousButtonLabel: 'Previous',
            nextButtonLabel: 'Confirm',
            isNextDisabled: firstDevice => {
              return !firstDevice || firstDevice.integration.hasErrors;
            }
          }
        ]
      : [
          {
            title: 'Provisioning Your Device',
            label: 'To set up many devices, AdMobilize needs each name, mac address and serial number from the device.',
            body: (
              <IntegrationSection
                step={'provision'}
                integration={'brightsign'}
                newDevices={newDevices}
                setNewDevices={setNewDevices}
              />
            ),
            previousButtonLabel: 'Previous Step',
            nextButtonLabel: 'Confirm'
          }
        ];
    default:
      return [];
  }
}
