import types from '../types.js';

export const loadProjects = () => {
  return { type: types.LOAD_PROJECTS, payload: null };
};

export const createProject = payload => {
  return { type: types.CREATE_PROJECT, payload };
};

export const selectProject = payload => {
  return { type: types.SELECT_PROJECT, payload };
};

export const clearProject = () => {
  return { type: types.CLEAR_PROJECT, payload: null };
};

export const updateSelectedProject = payload => {
  return { type: types.UPDATE_SELECTED_PROJECT, payload };
};

export const updateProjects = payload => {
  return { type: types.UPDATE_PROJECTS, payload };
};

export const getProjects = state => {
  return state.projectReducer.projects;
};

export const getSolutionsConfig = state => {
  return state.projectReducer.solutionsConfig;
};

export const updateSolutionConfig = payload => {
  return { type: types.UPDATE_SOLUTION_CONFIG, payload };
};

export const createSolutionConfig = payload => {
  return { type: types.CREATE_SOLUTION_CONFIG, payload };
};

export const getSelectedProject = state => {
  return state.projectReducer.selectedProject;
};

export const getSelectedSolution = state => {
  return state.projectReducer.selectedSolution;
};

export const updateSelectedSolution = state => {
  return state.projectReducer.selectedSolution;
};

export const selectSolution = payload => {
  return { type: types.SELECT_SOLUTION, payload };
};

export const getSolutions = state => {
  return state.projectReducer.solutions;
};
