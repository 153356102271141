import React, { useEffect, useState } from 'react';
import InfinityLoader from '../dropdownSearch/infinityLoader';
import API from '../../services/api';
import utils from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/actions';
import {
  getSelectedAnalyticsDashboard,
  getSelectedSummaryDashboard,
  selectSummaryDashboard,
  selectAnalyticsDashboard
} from '../../redux/templates/actions';

const DashboardSelector = ({ collection = 'SUMMARY' }) => {
  const [currentDashboards, setCurrentDashboards] = useState([]);
  const [selectedOption, setSelectedOption] = useState({ label: 'Select Template' });
  const [datasources, setDatasources] = useState([]);
  const [currentFilter, setCurrentFilter] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const siteGroup = useSelector(getSelectedProject);
  const selectedDashboard = useSelector(getSelectedSummaryDashboard);
  const selectedAnalyticsDashboard = useSelector(getSelectedAnalyticsDashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteGroup) {
      API.getDatasources(siteGroup.companyId, siteGroup.id).then(res => {
        setDatasources(res.datasources);
      });
    }
  }, [siteGroup]);

  useEffect(() => {
    if (isOpen) {
      loadMoreItems();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedDashboard && collection === 'SUMMARY') {
      setSelectedOption({
        label: selectedDashboard.displayName ? selectedDashboard.displayName : selectedDashboard.id
      });
    }
  }, [selectedDashboard]);

  useEffect(() => {
    if (selectedAnalyticsDashboard && collection === 'ANALYTICS') {
      setSelectedOption({
        label: selectedAnalyticsDashboard.displayName
          ? selectedAnalyticsDashboard.displayName
          : selectedAnalyticsDashboard.id
      });
    }
  }, [selectedAnalyticsDashboard]);

  const getQueryFromDataSource = datasource => {
    return `'${datasource}' = ANY(compatibleDatasources)`;
  };

  const getFilters = searchBy => {
    const filterList = datasources.map(getQueryFromDataSource);
    const filterQuery = datasources.length > 0 ? filterList.join(' OR ') : '';
    const searchQuery = searchBy !== '' ? `(id ILIKE "%${searchBy}%" OR displayName ILIKE "%${searchBy}%")` : '';

    return `${searchQuery !== '' ? `${searchQuery} AND ` : ''}${
      filterQuery !== '' ? `(${filterQuery}) AND ` : ''
    }(collection = '${collection}' AND enabled = true)`;
  };

  const loadMoreItems = (nextPageToken = '', searchingBy = '') => {
    const query = encodeURI(getFilters(searchingBy));
    const filter = query !== '' ? query : '';

    if (filter !== currentFilter) {
      setCurrentDashboards([]);
    }

    return API.getTemplates(nextPageToken, filter).then(res => {
      const fetchedVersions = res.templates.map(template => ({
        label: template.displayName ? template.displayName : template.id,
        value: `"${template.id}"`,
        data: template
      }));

      // Reset list when filter changes
      if (filter !== currentFilter) {
        setCurrentFilter(filter);
        setCurrentDashboards(fetchedVersions);
      } else {
        const newVersions = utils.mergeArraysByField(fetchedVersions, currentDashboards, 'value');
        setCurrentDashboards(newVersions);
      }

      return res.nextPageToken;
    });
  };

  const onSelect = selectedValue => {
    const selected = currentDashboards.find(dashboard => dashboard.value === selectedValue);
    if (collection === 'SUMMARY') {
      dispatch(selectSummaryDashboard(selected.data));
    } else {
      dispatch(selectAnalyticsDashboard(selected.data));
    }
  };

  return (
    <div className="version-selector">
      <InfinityLoader
        title={'Search Version'}
        leftAlign
        outline={false}
        selected={selectedOption}
        label={'Dashboard'}
        onSelect={selectedValue => onSelect(selectedValue)}
        onLoading={loadMoreItems}
        onOpenChange={setIsOpen}
        totalSize={9999}
        options={currentDashboards}
      />
    </div>
  );
};

export default DashboardSelector;
