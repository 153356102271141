import { takeLatest } from 'redux-saga/effects';
import types from '../redux/types.js';
import { fetchFeatureFlags } from './featureFlag.js';
import { fetchCompanies } from './company.js';
import { fetchDevices, loadProjects, updateUserDefaultProject } from './project.js';
import { fetchTemplates } from './template.js';
import { fetchLicenses, fetchSolutionSchema, fetchTables, updateUserDefaultSolution } from './solution.js';
import { postDeviceUpdate, updateDeviceStatus } from './device.js';
import { updateLocalStore } from './user.js';
import { fetchNotificationPreferences, fetchNotifications, fetchNotificationTypes } from './notification.js';
import { dismissAlert, finishLoading } from './dashboard.js';
import { fetchIntegrations } from './integeration.js';

function* sideFX() {
  yield takeLatest(types.SET_USER, fetchFeatureFlags);
  yield takeLatest(types.SET_USER, fetchCompanies);
  yield takeLatest(types.SET_USER, fetchIntegrations);
  yield takeLatest(types.SET_USER, loadProjects);
  yield takeLatest(types.SELECT_PROJECT, updateUserDefaultProject);
  yield takeLatest(types.SELECT_PROJECT, fetchDevices);
  yield takeLatest(types.SELECT_PROJECT, fetchTemplates);
  yield takeLatest(types.UPDATE_USER, fetchLicenses);
  yield takeLatest(types.SELECT_SOLUTION, updateUserDefaultSolution);
  yield takeLatest(types.SELECT_SOLUTION, fetchSolutionSchema);
  yield takeLatest(types.LOAD_SOLUTIONS, fetchTables);
  yield takeLatest(types.UPDATE_DEVICE_STATUS, updateDeviceStatus);
  yield takeLatest(types.POST_DEVICE_UPDATE, postDeviceUpdate);
  yield takeLatest(types.SET_PROJECT_TABLES, finishLoading);
  yield takeLatest(types.SET_ALERT, dismissAlert);
  yield takeLatest(types.UPDATE_USER, fetchNotifications);
  yield takeLatest(types.UPDATE_USER, fetchNotificationTypes);
  yield takeLatest(types.LOAD_NOTIFICATION_TYPES, fetchNotificationPreferences);
  yield takeLatest(types.UPDATE_USER, updateLocalStore);
}

export default sideFX;
