import { combineReducers } from 'redux';
import deviceReducer from './devices/reducer';
import projectReducer from './projects/reducer';
import dashboardReducer from './dashboard/reducer';
import notificationReducer from './notification/reducer';
import companyReducer from './company/reducer';
import templateReducer from './templates/reducer';
import userReducer from './user/reducer';
import integrationReducer from './integration/reducer';

const dashboard = combineReducers({
  deviceReducer,
  projectReducer,
  dashboardReducer,
  notificationReducer,
  companyReducer,
  templateReducer,
  userReducer,
  integrationReducer
});

export default dashboard;
