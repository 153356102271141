import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../redux/dashboard/actions';
import components from '../../../../components';
import InfoSection from '../../../../components/infoSection';
import API from '../../../../services/api';
import utils from '../../../../utils';
import { roles } from '../../../../utils/user/permissions';

const { CustomButton, CustomInput, CustomDropdown } = components;

const InviteUserModal = ({ toggle, modalOpen, project }) => {
  const [inviteEmail, setInviteEmail] = useState('');
  const [selectedPermission, setSelectedPermission] = useState(roles[0]);
  const [infoState, setInfoState] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const validation = {
      'invite-email': [
        { key: 'required', value: true },
        { key: 'email', value: true }
      ]
    };

    const errorList = {
      'invite-email': utils.fieldValidation(inviteEmail, validation['invite-email']).join(', ')
    };
    setErrors(errorList);
  }, [inviteEmail]);

  const inviteUser = () => {
    setIsLoading(true);

    API.inviteUser(inviteEmail, project.id, selectedPermission.id)
      .then(() => {
        dispatch(setAlert(utils.generateAlert(`Invite was sent to ${inviteEmail}!`, 'success')));
      })
      .catch(err => {
        dispatch(setAlert(utils.generateAlert(`Something went wrong and your email was not sent!`, 'error')));
        console.error(err);
      })
      .finally(() => {
        toggle();
        setIsLoading(false);
      });
  };

  return (
    <Modal className="modal-invite-user" isOpen={modalOpen} fade={false} toggle={toggle} centered>
      <div className="modal-header">
        <h2>Invite users</h2>
        <h4>{`Type the email to receive an invitation link for "${project.displayName}"`}</h4>
      </div>
      <ModalBody>
        <div className={`${infoState ? 'info-open' : ''}`}>
          <form className="row">
            <div className="form-group col-6">
              <label>Email*</label>
              <CustomInput
                id="invite-email"
                type="text"
                value={inviteEmail}
                isEditable={true}
                autoComplete={'email'}
                onChange={e => {
                  setInviteEmail(e.target.value);
                }}
                errorMessage={errors['invite-email']}
              />
            </div>
            <div className="form-group col-6">
              <label>Role</label>
              <CustomDropdown
                items={roles}
                displayField="name"
                valueField="id"
                selectedItem={roles.find(role => role.id === selectedPermission.id)}
                handleSelection={event => setSelectedPermission(roles.find(role => role.id === event.target.value))}
              />
            </div>
          </form>
          <InfoSection
            title={'How do roles work:'}
            content={
              <div>
                <p>
                  <b>Dashboard User:</b> Can access/use Profile, Summary, Analytics and Report modules. Also can
                  modify/edit settings (Devices, Projects and Reports)
                </p>
                <p>
                  <b>Data Viewer:</b> Can access/use Profile, Summary, Analytics and Report modules. Cannot modify/edit
                  any settings, only view data.
                </p>
              </div>
            }
            onClickCB={setInfoState}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="button-container">
          <CustomButton classes={'btn-secondary'} title={'Cancel'} handleClick={toggle} />
          <CustomButton classes={'btn-primary'} title={'Save'} handleClick={inviteUser} loading={isLoading} />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default InviteUserModal;
