import types from '../types';
import { setState } from '../../utils/redux';

const initialState = {
  integrations: [],
  selectedIntegration: null
};

const integrationReducer = (state = initialState, action) => {
  const { LOAD_INTEGRATIONS, SELECT_INTEGRATION } = types;
  switch (action.type) {
    case LOAD_INTEGRATIONS:
      return setState(state, 'integrations', action.payload);
    case SELECT_INTEGRATION:
      return setState(state, 'selectedIntegration', action.payload);
    default:
      return state;
  }
};

export default integrationReducer;
