import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import API from '../../services/api';
import React from 'react';
import './styles.scss';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isBusy: false,
      isInvalid: false,
      sent: false
    };
  }

  handleInputChange = event => {
    const target = event.target;

    this.setState({
      [target.name]: target.value,
      isInvalid: false
    });
  };

  submit = event => {
    event.preventDefault();

    if (this.state.isBusy) {
      return;
    }

    this.setState({
      isBusy: true,
      isInvalid: false
    });

    API.resetPassword({ email: this.state.email })
      .then(() => {
        this.setState({
          isBusy: false,
          sent: true
        });
      })
      .catch(err => {
        this.setState({
          invalidMessage: err.message,
          isBusy: false,
          isInvalid: true
        });
      });
  };

  render() {
    return (
      <>
        <form action="#" onSubmit={this.submit} className="forgot-password-component">
          <div className="form-group d-flex flex-column">
            <label htmlFor="email" className="align-self-start mb-0">
              Password Assistance
            </label>
            <div className="help text-left">
              Enter your email to recover your password, you will receive an email with instructions.
            </div>
            <input
              className={`form-control form-control-lg ${this.state.isInvalid ? 'is-invalid' : ''}`}
              placeholder="Enter your email"
              type="email"
              id="email"
              name="email"
              value={this.state.email}
              onChange={this.handleInputChange}
              autoFocus
              required
              disabled={this.state.isBusy || this.state.sent}
            />
            <div className="invalid-feedback text-left">{this.state.invalidMessage}</div>
          </div>
          {this.state.sent && (
            <p className="text-success text-left success-message mb-4">
              An e-mail with instructions to create a new password has been sent to you.
            </p>
          )}
          {this.state.sent ? null : (
            <div className="form-group">
              <button className="btn btn-primary btn-lg btn-block" disabled={this.state.isBusy}>
                {this.state.isBusy ? (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    <span className="sr-only">Loading...</span>
                  </>
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          )}
        </form>
        <div className="text-center">
          <Link to="/login">
            &lt; <span className="text-decoration-underline">Back to Login</span>
          </Link>
        </div>
      </>
    );
  }
}

export default withRouter(ForgotPassword);
