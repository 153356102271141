import { delay, put } from 'redux-saga/effects';
import types from '../redux/types';

export function* finishLoading() {
  yield put({ type: types.SET_LOADING, payload: false });
}

export function* dismissAlert({ payload: alert }) {
  // Default alerts don't set duration
  // If an alert should stay on screen until closed, set duration to -1.
  if (!alert.duration) {
    yield delay(5000); // Default alert duration
    yield put({ type: types.DISMISS_ALERT });
  } else if (alert.duration > 0) {
    yield delay(alert.duration);
    yield put({ type: types.DISMISS_ALERT });
  }
}
