import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateUserPreferences } from '../../../redux/user/actions';

import components from '../../../components';
import WidgetGrid from './widgetGrid';
const { CustomButton, IconButton, CustomCheckBox } = components;

import utils from '../../../utils';

import API from '../../../services/api';

import './styles.scss';

const CustomWidgets = ({
  onChange, // Callback for widget changes
  defaultWidgets, // Array with all widget objects
  selectedDashboard // Current selected dashboard (old solution), sort of a template id
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Controls modal display
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false); // Controls save button based on changes
  const [selectedWidgets, setSelectedWidgets] = useState([]); // Widgets selected from user
  const [widgetList, setWidgetList] = useState([]); // List with all possible widgets for current dashboard
  const [userCustomWidgets, setUserCustomWidgets] = useState([]); // List with all possible widgets for current dashboard

  const user = useSelector(getUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const customWidgets = user.preferences.customWidgets;
    if (selectedDashboard) {
      setUserCustomWidgets(customWidgets[selectedDashboard.id]);
    } else {
      setUserCustomWidgets([]);
    }
  }, [user]);

  useEffect(() => {
    if (defaultWidgets) {
      let widgetsArray = []; // Unidimentional array of widget IDs
      widgetsArray = Object.values(defaultWidgets); // Start list with default widgets

      // Remove widgets that requires missing columns from current schema
      if (selectedDashboard && selectedDashboard.schema) {
        widgetsArray = widgetsArray.filter(
          widget =>
            !widget.requiredColumns || widget.requiredColumns.every(column => selectedDashboard.schema.includes(column))
        );
      }
      // Store the widget array on state
      setWidgetList(widgetsArray);

      // Update the list of selected widgets
      filterEnabledWidgets(widgetsArray);
    }
  }, [defaultWidgets, userCustomWidgets]);

  useEffect(() => {
    if (isModalOpen) {
      filterEnabledWidgets(widgetList);
    }
  }, [isModalOpen]);

  useEffect(() => {
    const selectedWidgetsCount = selectedWidgets.filter(widget => widget.selected).length;
    if (selectedWidgetsCount > 0) {
      setSaveButtonEnabled(true);
    } else {
      setSaveButtonEnabled(false);
    }
  }, [selectedWidgets]);

  // Set only user chosen widgets on selected list
  const filterEnabledWidgets = widgetsArray => {
    if (userCustomWidgets && userCustomWidgets.length > 0) {
      const filteredWidgets = widgetsArray.map(widget => {
        widget.selected = userCustomWidgets.some(widgetId => widgetId === widget.id);
        return widget;
      });

      setSelectedWidgets(filteredWidgets);
    } else {
      // Select all possible widgets
      setSelectedWidgets(
        widgetsArray.map(widget => {
          return { ...widget, selected: true };
        })
      );
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const saveChanges = () => {
    // Filter selected widget array from widget matrix
    const selectedArray = selectedWidgets.filter(widget => widget.selected).map(widget => widget.id);

    const updatedUser = utils.deepClone(user);
    utils.set(updatedUser, `preferences.customWidgets.${selectedDashboard.id}`, selectedArray);

    // Update User preferences in API
    API.updateUser(user.id, {
      preferences: updatedUser.preferences
    });

    // Update user with new widget preferences on Redux
    dispatch(updateUserPreferences(updatedUser.preferences));

    onChange(selectedArray);
    toggleModal();
  };

  const onWidgetClick = widgetId => {
    const widgetIndex = selectedWidgets.findIndex(currentWidget => currentWidget.id === widgetId);
    const newSelectedWidgets = [...selectedWidgets];
    newSelectedWidgets[widgetIndex].selected = !selectedWidgets[widgetIndex].selected;
    setSelectedWidgets(newSelectedWidgets);
  };

  const applySelectionToAll = selected => {
    const newSelectedWidgets = [...selectedWidgets];
    newSelectedWidgets.forEach(widget => {
      widget.selected = selected;
    });
    setSelectedWidgets(newSelectedWidgets);
  };

  const handleSelectAllCB = () => {
    const selectedWidgetsCount = selectedWidgets.filter(widget => widget.selected).length;

    if (selectedWidgetsCount === selectedWidgets.length) {
      // Deselect all widget if they were all selected
      applySelectionToAll(false);
    } else if (selectedWidgetsCount) {
      // Select all widgets if some were already selected
      applySelectionToAll(true);
    } else {
      // Select all widgets if none were selected
      applySelectionToAll(true);
    }
  };

  return (
    <div>
      <IconButton
        id="custom-widget-button"
        icon="settingsDark"
        btnClasses="outline"
        onPress={() => setIsModalOpen(true)}
        tooltip="Customize Widgets"
      />
      <Modal className="custom-widget-modal" isOpen={isModalOpen} fade={false} toggle={toggleModal} centered>
        <ModalHeader className="header-gray" toggle={toggleModal} tag="div">
          <h2>Customize Your Dashboard</h2>
          <div className="modal-subtitle">Select which widgets you would like to be displayed in your Dashboard</div>
        </ModalHeader>
        <ModalBody>
          <div>
            <h3>Changes will be reflected on your PDF report</h3>
            <CustomCheckBox
              label="Select all the widgets"
              onClick={handleSelectAllCB}
              multiple
              selectedItems={selectedWidgets.filter(widget => widget.selected).length}
              totalItems={selectedWidgets.length}
            />
            <WidgetGrid widgets={selectedWidgets} onWidgetClick={onWidgetClick} />
          </div>
          <div className="button-container">
            <CustomButton title="Cancel" classes="btn-secondary" handleClick={toggleModal} />
            <CustomButton disabled={!saveButtonEnabled} title="Save" classes="btn-primary" handleClick={saveChanges} />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CustomWidgets;
