import * as commonFunctions from './common';
import * as legacyEndpoints from './legacy';
import * as newEnpoints from './newInfra';
import * as tokenFunctions from './tokens';

class API {
  constructor() {
    this.access_token = null;
    this.refresh_token = null;
    this.userId = '';
    this.headers = {
      'Content-Type': 'application/json'
    };
    this.isRefreshingToken = false;
    this.isImpersonating = false;
    this.originalToken = null;

    Object.assign(this, commonFunctions.default, legacyEndpoints.default, newEnpoints.default, tokenFunctions.default);
  }
}

export default new API();
