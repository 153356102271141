import React, { useState, useEffect } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useDispatch } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import types from '../../redux/types';

import CustomConfirm from '../customConfirm';
import CustomInput from '../customInput';

import parse from '../../utils/device/parse';
import API from '../../services/api';
import utils from '../../utils';

const DeviceCommands = ({ device }) => {
  const dispatch = useDispatch();

  // status can be: Online, Updating, Offline, Stopped
  const [showArchiveDeviceDialog, setShowArchiveDeviceDialog] = useState(false);
  const [showToggleHeatmapDialog, setShowToggleHeatmapDialog] = useState(false);
  const [versions, setVersions] = useState({});
  const [modalOpened, setModalOpened] = useState(false);
  const [removePinVersionModalOpened, setRemovePinVersionModalOpened] = useState(false); // TODO: rename it
  const [hasPinnedVersion, setHasPinnedVersion] = useState(false);

  useEffect(() => {
    if (device && device.config && device.config.settings) {
      getPinnedVersion(device.config.settings);
    }
  }, [device]);

  const getPinnedVersion = ({ desktopui_config }) => {
    if (desktopui_config) {
      const {
        admprovider_target_version,
        malos_vision_target_version,
        desktop_ui_target_version,
        vision_service_target_version
      } = desktopui_config;

      setHasPinnedVersion(
        admprovider_target_version ||
          malos_vision_target_version ||
          desktop_ui_target_version ||
          vision_service_target_version
      );

      setVersions({
        admprovider_target_version,
        malos_vision_target_version,
        desktop_ui_target_version,
        vision_service_target_version
      });
    } else {
      // Clear pinned version after changing devices
      setHasPinnedVersion(false);
      setVersions({
        admprovider_target_version: null,
        malos_vision_target_version: null,
        desktop_ui_target_version: null,
        vision_service_target_version: null
      });
    }
  };

  const archiveDevice = () => {
    API.archiveDevice(device.details.companyId, device.details.id)
      .then(() => {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(`Device Archived`, 'success')
        });
      })
      .catch(error => {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(error.message, 'error')
        });
      })
      .finally(() => {
        setShowArchiveDeviceDialog(false);
      });
  };
  const dearchiveDevice = () => {
    API.dearchiveDevice(device.details.companyId, device.details.id)
      .then(() => {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(`Device Archived`, 'success')
        });
      })
      .catch(error => {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(error.message, 'error')
        });
      })
      .finally(() => {
        setShowArchiveDeviceDialog(false);
      });
  };

  const enableHeatmap = () => {
    API.toggleHeatmap(device.details.companyId, device.details.id, 'enable')
      .then(
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(`Heatmap was enabled`, 'success')
        })
      )
      .catch(error => {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(error.message, 'error')
        });
      })
      .finally(() => {
        setShowToggleHeatmapDialog(false);
      });
  };

  const disableHeatmap = () => {
    API.toggleHeatmap(device.details.companyId, device.details.id, 'disable')
      .then(
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(`Heatmap was disabled`, 'success')
        })
      )
      .catch(error => {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(error.message, 'error')
        });
      })
      .finally(() => {
        setShowToggleHeatmapDialog(false);
      });
  };

  const setVersion = async selectedVersion => {
    const config = {
      ...device.config,
      settings: {
        ...device.config.settings,
        desktopui_config: { ...device.config.settings.desktopui_config, ...selectedVersion }
      }
    };

    const newConfig = parse.parseDeviceConfigToServer(config);

    try {
      await API.updateDeviceConfig(device.details.companyId, device.details.id, newConfig, ['customConfig']);
      getPinnedVersion(config.settings);
      dispatch(setAlert(utils.generateAlert('Device updated successfully!', 'success')));
    } catch (error) {
      console.error(error);
    } finally {
      setModalOpened(false);
      setRemovePinVersionModalOpened(false);
    }
  };

  return (
    <>
      <div className="device-command">
        {device && device.details && (
          <>
            <div className="form-group">
              <button
                className="btn-secondary btn"
                onClick={e => {
                  e.stopPropagation();
                  setShowArchiveDeviceDialog(true);
                }}
              >
                <i className="uil uil-archive-alt"></i>
                {device.details.archived ? 'Unarchive Device' : 'Archive Device'}
              </button>
              <CustomConfirm
                messageType="archiveDevice"
                messageContext={device.details.displayName}
                confirmCb={device.details.archived ? dearchiveDevice : archiveDevice}
                cancelCb={() => setShowArchiveDeviceDialog(false)}
                isOpen={showArchiveDeviceDialog}
              />
            </div>
            <div className="form-group">
              <button
                className="btn-secondary btn"
                onClick={e => {
                  e.stopPropagation();
                  setShowToggleHeatmapDialog(true);
                }}
              >
                <i className="uil uil-archive-alt"></i>
                {device.details.heatmapEnabled ? 'Disable heatmap' : 'Enable heatmap'}
              </button>
              <CustomConfirm
                messageType={device.details.heatmapEnabled ? 'disableHeatmap' : 'enableHeatmap'}
                messageContext={device.details.displayName}
                confirmCb={device.details.heatmapEnabled ? disableHeatmap : enableHeatmap}
                cancelCb={() => setShowToggleHeatmapDialog(false)}
                isOpen={showToggleHeatmapDialog}
              />
            </div>
            <div className="form-group">
              <button
                className="btn-secondary btn"
                onClick={e => {
                  e.stopPropagation();
                  setModalOpened(true);
                }}
              >
                <i className="uil uil-map-marker-shield"></i>
                <span>{hasPinnedVersion ? 'Change Pinned Version' : 'Pin Version'}</span>
              </button>
            </div>
            {hasPinnedVersion && (
              <div className="form-group">
                <button
                  className="btn-secondary btn"
                  onClick={e => {
                    e.stopPropagation();
                    setRemovePinVersionModalOpened(true);
                  }}
                >
                  <i className="uil uil-map-marker-slash"></i>
                  <span>Remove Pinned Version</span>
                </button>
                <CustomConfirm
                  messageType="removePinVersion"
                  confirmCb={async () =>
                    await setVersion({
                      admprovider_target_version: '',
                      malos_vision_target_version: '',
                      desktop_ui_target_version: '',
                      vision_service_target_version: ''
                    })
                  }
                  cancelCb={() => setRemovePinVersionModalOpened(false)}
                  isOpen={removePinVersionModalOpened}
                />
              </div>
            )}
          </>
        )}
      </div>

      <Modal size="lg" className="custom-confirm" isOpen={modalOpened} fade={false} centered>
        <ModalHeader className="header-gray">Pin Version</ModalHeader>
        <ModalBody>
          <div className="custom-confirm-body">
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label>Admprovider Version</label>
                  <CustomInput
                    id="admprovider-target-version"
                    type="text"
                    value={versions.admprovider_target_version || ''}
                    isEditable={true}
                    onChange={e => setVersions({ ...versions, admprovider_target_version: e.target.value })}
                    autoFocus={true}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Malos Version</label>
                  <CustomInput
                    id="malos-vision-target-version"
                    type="text"
                    value={versions.malos_vision_target_version || ''}
                    isEditable={true}
                    onChange={e => setVersions({ ...versions, malos_vision_target_version: e.target.value })}
                    autoFocus={true}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <label>Desktop Ui Version</label>
                  <CustomInput
                    id="desktop-ui-target-version"
                    type="text"
                    value={versions.desktop_ui_target_version || ''}
                    isEditable={true}
                    onChange={e => setVersions({ ...versions, desktop_ui_target_version: e.target.value })}
                    autoFocus={true}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Admobilize Vision Service Version</label>
                  <CustomInput
                    id="adm-vision-service-target-version"
                    type="text"
                    value={versions.vision_service_target_version || ''}
                    isEditable={true}
                    onChange={e => setVersions({ ...versions, vision_service_target_version: e.target.value })}
                    autoFocus={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="row flex-fill justify-content-end">
            <div className="modal-buttons d-flex">
              <button type="button" className="btn btn-secondary" onClick={() => setModalOpened(false)}>
                Cancel
              </button>
              <button type="button" className="btn btn-primary ml-3" onClick={() => setVersion(versions)}>
                Apply
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DeviceCommands;
