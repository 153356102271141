import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import CustomButton from '../../../components/customButton';
import deviceSteps from './deviceSteps';
import osSteps from './osSteps';
import './styles.scss';

const CreateDeviceModal = ({ isOpen, toggleModal, newDevices, setNewDevices }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [steps, setSteps] = useState(deviceSteps(setNewDevices));

  useEffect(() => {
    if (steps[currentStep].isNextDisabled) {
      if (newDevices[0]) {
        addSteps(newDevices[0].os.id);
      }
      setIsNextDisabled(steps[currentStep].isNextDisabled(newDevices[0]));
    }
  }, [newDevices]);

  const previous = () => {
    if (currentStep  !== 0) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(currentStep);
      toggleModal();
    }
  };

  const next = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(0);
      toggleModal();
    }
  };

  const addSteps = osId => {
    const defaultSteps = deviceSteps(setNewDevices);
    const stepsToAdd = osSteps(osId, newDevices, setNewDevices);
    setSteps(defaultSteps.concat(stepsToAdd));
  };

  return (
    <Modal size="lg" isOpen={isOpen} fade={false} toggle={toggleModal} centered id="create-devices-modal">
      <ModalHeader toggle={toggleModal}>
        {steps[currentStep].title}
        <p className="create-modal-label">{steps[currentStep].label}</p>
      </ModalHeader>
      <ModalBody>{steps[currentStep].body}</ModalBody>
      <ModalFooter>
        <CustomButton
          classes="btn btn-secondary"
          title={steps[currentStep].previousButtonLabel}
          handleClick={previous}
        />
        <CustomButton
          classes="btn btn-primary"
          title={steps[currentStep].nextButtonLabel}
          handleClick={next}
          disabled={isNextDisabled}
        />
      </ModalFooter>
    </Modal>
  );
};

export default CreateDeviceModal;
