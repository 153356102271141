import { call, put } from 'redux-saga/effects';
import API from '../services/api';
import types from '../redux/types';

export function* fetchFeatureFlags() {
  try {
    const featureFlags = yield call(API.getFeatureFlags);
    if (featureFlags) {
      yield put({ type: types.SET_FEATURE_FLAGS, payload: featureFlags });
    }
  } catch (error) {
    console.error("Couldn't fetch feature flags");
  }
}
