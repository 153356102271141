import { call, delay, put, select } from 'redux-saga/effects';
import types from '../redux/types';
import API from '../services/api';
import utils from '../utils';

/**
 * Method used to change the device status on reducer
 * and after check the device status using the backend
 * this method is a palliative measure to get the device status when send a command.
 * @param { deviceId, newStatus } param0
 */
export function* updateDeviceStatus({ payload }) {
  const currentDevices = yield select(state => state.deviceReducer.devices);
  try {
    const newDevices = currentDevices.map(device => {
      if (device.id === payload.deviceId) {
        device.status = payload.newStatus;
      }
      return { ...device };
    });
    yield put({ type: types.UPDATE_DEVICES, payload: newDevices });
    yield delay(10000);
  } catch (e) {
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateErrorAlert("Couldn't update status. Try to refresh this page.")
    });
  }
}

export function* postDeviceUpdate(action) {
  try {
    const updatedDevice = action.payload.device;
    yield call(API.updateDevice, updatedDevice.companyId, updatedDevice, action.payload.fieldsPath);

    // TODO: Test response before updating store
    // console.log(response);

    const devices = yield select(state => state.deviceReducer.devices);
    const newDevices = devices.map(device => {
      if (device.id === updatedDevice.id) {
        const keys = Object.keys(updatedDevice);
        keys.forEach(key => {
          if (key !== 'status') {
            device[key] = updatedDevice[key];
          }
        });
        return device;
      }
      return device;
    });

    yield put({ type: types.UPDATE_DEVICES, payload: newDevices });
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateAlert('Device updated successfully!', 'success')
    });
  } catch (e) {
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateErrorAlert("Couldn't update device. Try again or refresh this page.")
    });
  }
}
