import { call, put, select } from 'redux-saga/effects';
import types from '../redux/types';
import API from '../services/api';
import utils from '../utils';

export function* fetchLicenses() {
  const user = yield select(state => state.userReducer.user);

  if (user && user.permissions && user.permissions.support) {
    // Fetch License only if user have privillege
    const licensesResponse = yield call(API.listLicenses);
    yield put({ type: types.LOAD_LICENSES, payload: licensesResponse.licenses });
  }
}

export function* fetchTables() {
  const selectedProject = yield select(state => state.projectReducer.selectedProject);
  const selectedCompany = yield select(state => state.companyReducer.selectedCompany);

  if (!selectedProject) return;

  const projectId = selectedProject.id;
  const companyId = selectedCompany.id;

  try {
    const tables = yield call(API.getTables, companyId, projectId);
    selectedProject.tables = tables.tables;
    yield put({ type: types.SET_PROJECT_TABLES, payload: selectedProject });
  } catch (e) {
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateErrorAlert("Couldn't load tables. Try again or refresh this page.")
    });
  }
}

// Update user's default solution on Redux and DB
export function* updateUserDefaultSolution({ payload: solution }) {
  // Get user and selectetProject from Redux
  const user = yield select(state => state.userReducer.user);
  const selectedProject = yield select(state => state.projectReducer.selectedProject);

  // Don't allow impersonator user to change default solution of other user
  if (solution && !user.impersonator && user.preferences && solution.id !== user.preferences.defaultSolutionId) {
    // Update User preferences on Redux and AuthAPI
    const updatedUserPreferences = {
      ...user.preferences,
      defaultProjectId: selectedProject.id,
      defaultSolutionId: solution.id
    };
    yield put({ type: types.UPDATE_USER, payload: { ...user, preferences: updatedUserPreferences } });
    yield call(API.updateUser, user.id, { preferences: updatedUserPreferences });
  }
}

// Fetch solution Schema and normalize
export function* fetchSolutionSchema({ payload: solution }) {
  const selectedProject = yield select(state => state.projectReducer.selectedProject);
  const selectedCompany = yield select(state => state.companyReducer.selectedCompany);

  if (selectedProject && solution) {
    const projectId = selectedProject.id;
    const companyId = selectedCompany.id;
    // VDetect doesn't have its own table, use VRecog instead
    const solutionId = solution.table;

    // Load selected solution database schema
    const selectedSolutionSpec = yield call(API.getTableSchema, companyId, projectId, solutionId);

    // Columns to be removed from custom report creation requests
    const columnsToRemove = [
      'deviceRegistry',
      'cameraId',
      'insertId',
      'confidenceAge',
      'confidenceEmotion',
      'confidenceGender'
    ];

    // Remove vehicleType column for VDetection solution
    if (solution.id === 'vehicledetectionv1') {
      columnsToRemove.push('vehicleType');
    }

    // Prevent errors when API doesn't find solution table for current project
    if (selectedSolutionSpec.schema) {
      // Remove columns that souldn't be listed form the database schema
      solution.schema = selectedSolutionSpec.schema
        .map(column => column.name)
        .filter(column => columnsToRemove.every(columnToRemove => column !== columnToRemove));

      // Add attentionTime column for face based solutions
      if (solution.id === 'facev2' || solution.cms) {
        solution.schema.push('attentionTime');
      }
    } else {
      solution.schema = [];
    }

    // Update solution on Redux adding schema to it
    yield put({ type: types.UPDATE_SELECT_SOLUTION, payload: { ...solution } });
  }
}
