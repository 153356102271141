import config from '../../config';
import API from '../api';

const { baseUrl } = config.iamApi;

const testPermission = (entity, id, permissions) => {
  const url = `${baseUrl}/resources:testPermissions`;
  return fetch(url, {
    headers: API.headers,
    method: 'POST',
    body: JSON.stringify({
      resource: `${entity}/${id}`,
      permissions
    })
  }).then(res => API.generateResponse(res));
};

export { testPermission };
