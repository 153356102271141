import React, { useEffect, useState } from 'react';
import CustomInput from '../../../components/customInput';
import utils from '../../../utils';
import ImportStep from './importStep';

const IntegrationSection = ({ step, integration, newDevices, setNewDevices }) => {
  const [deviceName, setDeviceName] = useState('');
  const [balenaId, setBalenaId] = useState('');
  const [mac, setMac] = useState('');
  const [serial, setSerial] = useState('');
  const [fileName, setFileName] = useState('');
  const [columns, setColumns] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    switch (integration) {
      case 'balena':
        setFileName('Balena Multiple Devices');
        setColumns(['Device Name', 'Balena UUID']);
        break;
      case 'brightsign':
        setFileName('BrightSign Multiple Devices (HD223 not supported)');
        setColumns(['Device Name', 'MAC', 'Serial']);
        break;
      default:
        break;
    }
    setNewDevices(populateDevicesInfo());
    setDeviceName(deviceName); // REMOVE: this is here only to disable warning of non user var on console.
  }, []);

  useEffect(() => {
    setErrors(validateForm());
    setNewDevices(populateDevicesInfo());
  }, [deviceName, balenaId, mac, serial]);

  const validateForm = () => {
    const validation = {
      'device-name': [{ key: 'required', value: true }],
      app: [{ key: 'required', value: true }],
      'balena-id': [{ key: 'required', value: true }],
      mac: [{ key: 'required', value: true }],
      serial: [{ key: 'required', value: true }]
    };

    let errorList = {};
    switch (integration) {
      case 'balena':
        errorList = {
          'balena-id': utils.fieldValidation(balenaId, validation['balena-id']).join(', ')
        };
        break;
      case 'brightsign':
        errorList = {
          mac: utils.fieldValidation(mac, validation['mac']).join(', '),
          serial: utils.fieldValidation(serial, validation['serial']).join(', ')
        };
        break;
      default:
        break;
    }

    return errorList;
  };

  const checkErrors = localErrors => {
    let hasError = false;

    Object.keys(localErrors).forEach(key => {
      if (localErrors[key].length > 0) {
        hasError = true;
      }
    });

    return hasError;
  };

  const populateDevicesInfo = () => {
    const localDevices = utils.deepClone(newDevices);
    let selectedIntegration = {};
    switch (integration) {
      case 'balena':
        selectedIntegration = {
          deviceName,
          balenaUUID: balenaId,
          errors,
          hasErrors: checkErrors(validateForm())
        };
        break;
      case 'brightsign':
        selectedIntegration = {
          mac,
          serial,
          errors,
          hasErrors: checkErrors(validateForm())
        };
        break;
      default:
        break;
    }

    localDevices.forEach(device => (device.integration = selectedIntegration));

    return localDevices;
  };

  if (step === 'install') {
    if (integration === 'balena') {
      return (
        <form>
          <div className="row">
            <div className="form-group col">
              <label>Balena UUID*</label>
              <CustomInput
                id="balena-uuid"
                type="text"
                value={balenaId}
                isEditable={true}
                onChange={e => setBalenaId(e.target.value)}
                autoFocus={true}
                errorMessage={errors['balena-id']}
              />
            </div>
          </div>
        </form>
      );
    }
    if (integration === 'brightsign') {
      return (
        <form>
          <div className="row">
            <div className="form-group col-6">
              <label>MAC*</label>
              <CustomInput
                id="balena-app"
                type="text"
                value={mac}
                isEditable={true}
                onChange={e => setMac(e.target.value)}
                autoFocus={true}
                placeholder="MAC"
                errorMessage={errors['mac']}
              />
            </div>
            <div className="form-group col-6">
              <label>Serial*</label>
              <CustomInput
                id="balena-uuid"
                type="text"
                value={serial}
                isEditable={true}
                onChange={e => setSerial(e.target.value)}
                autoFocus={true}
                placeholder="Serial"
                errorMessage={errors['serial']}
              />
            </div>
          </div>
        </form>
      );
    }
  }

  if (step === 'provision') {
    return (
      <>
        <ImportStep
          fileName={fileName}
          customColumns={columns}
          onUpdate={console.log}
          newDevices={newDevices}
          setNewDevices={setNewDevices}
        />
        <div className="instruction mt-3">
          <p>
            If you had any trouble with previous steps, check out our{' '}
            <a href="https://documentation.admobilize.com">documentation</a>
          </p>
        </div>
      </>
    );
  }
};

export default IntegrationSection;
