import React from 'react';
import icons from '../../../assets/icons';

const ProvisionDeviceSection = () => {
  return (
    <div className="provision-device">
      <img src={icons.device_success} alt="Device created" />
      <h2>Success! Devices Set Up</h2>
      <p>Now you can provision your devices</p>
    </div>
  );
};

export default ProvisionDeviceSection;
