const deviceTypes = [
  'CREATE_DEVICE',
  'LOAD_DEVICES',
  'POST_DEVICE_UPDATE',
  'UPDATE_DEVICES',
  'UPDATE_DEVICE_STATUS',
  'LOAD_LICENSES',
  'LOAD_ALL_DEVICES'
];

export default deviceTypes;
