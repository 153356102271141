import API from './api';
import credentialsStore from './credentialsStore';

const refreshTokenWhenNeeded = async () => {
  if (!API.access_token || !API.refresh_token || API.isRefreshingToken) {
    return;
  }

  API.isRefreshingToken = true;

  const parsedPayload = credentialsStore.parseJwtPayload(API.access_token);
  const now = Math.floor(new Date().getTime() / 1000);
  const exp = parseInt(parsedPayload.exp);

  if (exp > now) {
    // Token still valid
    API.isRefreshingToken = false;
    return;
  } else {
    // Token has expired, so refresh session
    await API.refreshSession(API.refresh_token).then(async res => {
      // Need to see if user was impersonating, because we need to impersonate again
      if (API.isImpersonating) {
        await API.impersonateUser(API.userId, res.accessToken).then(user => {
          // Update AccessToken on header Authorization
          API.setAccessToken(user.accessToken);
        });
      }
      API.isRefreshingToken = false;
    });
  }
};

// Update only accessToken (used when impersonating)
const setAccessToken = newToken => {
  API.access_token = newToken;
  API.headers.Authorization = `Bearer ${newToken}`;
};

// Update the accessToken and refreshToken (used by login and refresh session)
const setTokens = ({ accessToken, refreshToken }) => {
  API.refresh_token = refreshToken;
  API.setAccessToken(accessToken);
};

export default { refreshTokenWhenNeeded, setAccessToken, setTokens };
