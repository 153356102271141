import { generateTypes } from '../utils/redux.js';
import companyTypes from './company/types.js';
import dashboardTypes from './dashboard/types.js';
import deviceTypes from './devices/types.js';
import integrationTypes from './integration/types.js';
import notificationTypes from './notification/types.js';
import { solutionTypes, projectTypes } from './projects/types.js';
import templateTypes from './templates/types.js';
import usertypes from './user/types.js';

const typeList = [
  ...companyTypes,
  ...dashboardTypes,
  ...deviceTypes,
  ...notificationTypes,
  ...solutionTypes,
  ...projectTypes,
  ...templateTypes,
  ...usertypes,
  ...integrationTypes
];

const types = generateTypes(typeList);

export default types;
