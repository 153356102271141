import { call } from 'redux-saga/effects';
import credentialsStore from '../services/credentialsStore';

export function* updateLocalStore({ payload }) {
  try {
    yield call(credentialsStore.put, payload);
  } catch (err) {
    console.error(err);
  }
}
