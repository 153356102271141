import React, { useState, useEffect, useRef } from 'react';
// import { useDispatch } from 'react-redux';
// import { setAlert } from '../../../redux/dashboard/actions';
import utils from '../../../utils';
import CSVTemplate from '../../../components/csvTemplate';
import CustomButton from '../../../components/customButton';

const ImportStep = ({ fileName, customColumns /*, onUpdate*/, newDevices, setNewDevices }) => {
  const [file, setFile] = useState(null);
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);

  // const dispatch = useDispatch();
  // TODO: Save mappings

  const inputRef = useRef(null);

  useEffect(() => {
    setRows([]);
    setFile(null);
    setError(null);
  }, []);

  useEffect(() => {
    setError(null);

    if (file) {
      if (file.type === 'text/csv') {
        loadFile(file, e => {
          const data = e.target.result;
          const fileRows = data.split('\n');
          setError(null);

          if (fileRows < 2) {
            setError('File is empty or has only header');
          } else if (fileRows.length !== newDevices.length) {
            // setError(`${fileRows.length - 1} provisions found. You wanted to provision ${newDevices.length} devices.
            // ${fileRows.length - 1} will be provisioned`)
            setError(`Desired number of provisions don't match. ${fileRows.length - 1} will be provisioned.`);
          }
          setRows(fileRows);
        });
        setNewDevices(populateDevices()); //TODO: not saving file name inside object
      } else {
        setError('File choosen is not a CSV!');
        setRows([]);
      }
    } else {
      setRows([]);
    }
  }, [file]);

  const populateDevices = () => {
    const localDevices = utils.deepClone(newDevices);
    localDevices.forEach(device => (device.fileName = file.name));
    return localDevices;
  };

  const loadFile = (intendedFile, callback) => {
    if (!intendedFile) return;
    const reader = new FileReader();
    reader.onload = evt => callback(evt);
    reader.readAsText(intendedFile);
  };

  const renderFileName = () => {
    if (file) {
      if (file.name.length > 25) {
        return `${file.name.substr(0, 25)}...csv`;
      }
      return file.name;
    }

    return '';
  };

  const renderInfo = () => {
    if (error || !file) return null;

    return (
      <div className="file-info">
        {renderFileName()} -{' '}
        <b>
          {rows.length - 1} {rows.length - 1 > 1 ? 'rows' : 'row'} found
        </b>
      </div>
    );
  };

  return (
    <>
      <CSVTemplate fileName={fileName} columns={customColumns} />
      <div className="file-input-container">
        <input id="input-file-btn" type="file" ref={inputRef} onChange={e => setFile(e.target.files[0])} />
        <CustomButton
          title="Choose File"
          classes="btn-secondary"
          icon="folder"
          handleClick={() => {
            inputRef.current.click();
          }}
        />

        {renderInfo()}
        {error && <div className="error-message">{error}</div>}
      </div>
    </>
  );
};

export default ImportStep;
