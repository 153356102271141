import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/actions';
import icons from '../../assets/icons';
import API from '../../services/api';

import './styles.scss';
import { getSelectedCompany } from '../../redux/company/actions';

const StatusBar = ({ lastUpdated }) => {
  const [status, setStatus] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const project = useSelector(getSelectedProject);
  const company = useSelector(getSelectedCompany);

  useEffect(() => {
    calculateStatus();
  }, [lastUpdated, project]);

  const calculateStatus = () => {
    if (project) {
      setStatus({});
      setIsLoading(true);
      API.getSiteGroupStats(company.id, project.id)
        .then(response => {
          setStatus(response.deviceCountByStatus);
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="status-counters">
      <div className="status-counter">
        <span className="status-counter-icon">
          <img src={icons.online} alt="" />
        </span>
        <span className="status-counter-label">Online</span>
        <b>{isLoading ? '--' : status.detecting}</b>
      </div>
      <div className="status-counter">
        <span className="status-counter-icon">
          <img src={icons.offline} alt="" />
        </span>
        <span className="status-counter-label">Offline</span>
        <b>{isLoading ? '--' : status.offline}</b>
      </div>
      <div className="status-counter">
        <span className="status-counter-icon">
          <img src={icons.stopped} alt="" />
        </span>
        <span className="status-counter-label">Stopped</span>
        <b>{isLoading ? '--' : status.inactive + status.downloadingModels}</b>
      </div>
    </div>
  );
};

export default StatusBar;
