import React from 'react';
import Widget from '../../components/widget';

const WidgetGrid = ({ appliedFilters, filteredWidgets, handleError, eventHandlers, lastUpdated }) => {
  if (!filteredWidgets) {
    return null;
  }

  const schemaHasColumn = (column) => {
    return appliedFilters.selectedSolution.schema && appliedFilters.selectedSolution.schema.includes(column);
  };

  return filteredWidgets.map((row, index) => {

    if (!appliedFilters.selectedSolution) {
      return null;
    }

    // TODO: GET THIS INFO ON DEVICE LEVEL
    let filteredRow = row;

    // Remove wifi widgets if there is no wifi device selected
    if (appliedFilters.wifiDevices && appliedFilters.wifiDevices.length === 0) {
      filteredRow = row.filter(widget => !widget.wifiTracker)
    }

    // Remove heatmap widgets if there is no heatmap device selected
    if (appliedFilters.heatmapDevices && appliedFilters.heatmapDevices.length === 0) {
      filteredRow = filteredRow.filter(widget => widget.display !== 'heatmap')
    }

    // Prevent errors when current project/table doesn't have required columns
    filteredRow = filteredRow.filter(widget => !widget.requiredColumns || widget.requiredColumns.every(schemaHasColumn));

    // Filter widget that doesn't match renderCondition
    filteredRow = filteredRow.filter(widget => {
      if (!widget.renderCondition) return true;

      const renderCondition = new Function('filters', widget.renderCondition);
      return renderCondition(appliedFilters);
    });


    // Shouldn't render empty rows
    if (filteredRow.length === 0) {
      return null;
    }

    return (
      <div key={`row-${index}}`} className="chart-row">
        {
          filteredRow.map(widget => {
            return (
              <Widget
                key={`analytics-${widget.id}`}
                widget={widget}
                lastUpdated={lastUpdated}
                filters={appliedFilters}
                handleError={handleError}
                eventHandlers={eventHandlers}
                collection={'ANALYTICS'}
              />
            )
          })
        }
      </div>
    )
  });
};

export default WidgetGrid;