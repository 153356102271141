import type from '../types';
import { setState } from '../../utils/redux';

const initialState = {
  loading: true,
  featureFlags: {},
  alert: {
    visible: false,
    message: "We couldn't load your devices. Please refresh the page.",
    type: 'error',
    color: 'danger',
    duration: 0,
    callToAction: null,
    hideType: false
  }
};

const dashboardReducer = (state = initialState, action) => {
  const { SET_ALERT, SET_LOADING, SET_FEATURE_FLAGS, DISMISS_ALERT } = type;

  switch (action.type) {
    case SET_ALERT:
      return setState(state, 'alert', action.payload);
    case DISMISS_ALERT:
      return setState(state, 'alert', { ...state.alert, visible: false });
    case SET_LOADING:
      return setState(state, 'loading', action.payload);
    case SET_FEATURE_FLAGS:
      return setState(state, 'featureFlags', action.payload);
    default:
      return state;
  }
};

export default dashboardReducer;
