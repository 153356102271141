import { call, put, select } from 'redux-saga/effects';
import types from '../redux/types';
import API from '../services/api';

export function* fetchTemplates() {
  const project = yield select(state => state.projectReducer.selectedProject);
  const company = yield select(state => state.companyReducer.selectedCompany);
  const user = yield select(state => state.userReducer.user);

  // Get datasources from current SiteGroup
  const cid = project && project.companyId ? project.companyId : company.id;
  const pid = project && project.id ? project.id : user.preferences.defaultProjectId;

  // Check if there are any projects in current company
  const { projects } = yield call(API.getSiteGroupsByCompany, cid, 200);
  if (projects && projects.length > 0) {
    const datasourcesResponse = yield call(API.getDatasources, cid, pid);
    // Get filter from datasources
    const filterList = datasourcesResponse.datasources.map(datasource => {
      return `'${datasource}' = ANY(compatibleDatasources)`;
    });

    let summaryTemplatesResponse = {};
    summaryTemplatesResponse = yield call(
      API.getTemplates,
      '',
      datasourcesResponse.datasources.length > 0
        ? `(${filterList.join(' OR ')}) AND (collection = 'SUMMARY' AND enabled = true)`
        : ''
    );

    let analyticsTemplatesResponse = {};
    analyticsTemplatesResponse = yield call(
      API.getTemplates,
      '',
      datasourcesResponse.datasources.length > 0
        ? `(${filterList.join(' OR ')}) AND (collection = 'ANALYTICS' AND enabled = true)`
        : ''
    );

    yield put({ type: types.SELECT_SUMMARY_DASHBOARD, payload: summaryTemplatesResponse.templates[0] });
    yield put({ type: types.SELECT_ANALYTICS_DASHBOARD, payload: analyticsTemplatesResponse.templates[0] });
    yield put({ type: types.LOAD_SUMMARY_TEMPLATES, payload: summaryTemplatesResponse.templates });
    yield put({ type: types.LOAD_ANALYTICS_TEMPLATES, payload: analyticsTemplatesResponse.templates });
  } else {
    yield put({ type: types.SELECT_SUMMARY_DASHBOARD, payload: {} });
    yield put({ type: types.SELECT_ANALYTICS_DASHBOARD, payload: {} });
  }
}
