import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProjects, getSelectedProject, updateProjects, selectProject } from '../../redux/projects/actions';
import types from '../../redux/types';
import { Grid, AutoSizer } from 'react-virtualized';
import { setAlert } from '../../redux/dashboard/actions';
import { getUser } from '../../redux/user/actions';
import utils from '../../utils';
import components from '../../components';
import API from '../../services/api';
import icons from '../../assets/icons';

import { discoverUserPermission } from '../../utils/user/permissions';

import projectSections from './projectSections';
import newProjectSteps from './newProjectSteps';
import './styles.scss';

const { Toolbar, Card, DetailsDrawer, CopyButton, Wizard, Loading, CustomCheckBox } = components;

const NEW_PROJECT_SKELETON = {
  users: [],
  devices: [],
  preferences: null
};

const ProjectsContainer = () => {
  const [searchedProjects, setSearchedProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchingBy, setSearchingBy] = useState('');
  const [projectCount, setProjectCount] = useState('0');
  const [counters, setCounters] = useState({ deviceCounter: 0, userCounter: 0 });
  const [displayProjectDetails, setDisplayProjectDetails] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [newProject, setNewProject] = useState(null);
  const [owners, setOwners] = useState({});
  const [ownerFilter, setOwnerFilter] = useState('none');

  const { projectId } = useParams();
  const projects = useSelector(getProjects);
  const reduxSelectedProject = useSelector(getSelectedProject);
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  useEffect(() => {
    // Apply owned filter and search projects
    applyFilters();

    // Get owner name and email from AuthAPI
    getOwnerInfo();

    // Use route to open project detail panel
    if (projectId && projects) {
      // Make sure projectId exists on project list
      const projFromRoute = projects.find(proj => proj.id === projectId);
      if (projFromRoute) {
        setSelectedProject(projFromRoute);
      }
    }
  }, [projects, projectId, ownerFilter]);

  useEffect(() => {
    if (selectedProject && user) {
      discoverUserPermission(user, selectedProject).then(permissions => {
        const hasPrivilege = user.impersonator || !permissions.advertiser;
        // Test permission before opening the panel
        if (hasPrivilege) {
          setDisplayProjectDetails(true);
        } else {
          setDisplayProjectDetails(false);
        }
      });
    }
  }, [selectedProject]);

  // Update project list on type search
  const onSearch = searchBy => {
    let searchedProjects = filteredProjects;

    if (searchBy !== '') {
      searchedProjects = getSearchedProjects(searchedProjects, searchBy);
      setProjectCount(`${searchedProjects.length}/${projects.length}`);
    } else {
      setProjectCount(filteredProjects.length);
    }

    setSearchedProjects(searchedProjects);
    setSearchingBy(searchBy);
  };

  /**
   * Return project array filtered by a search string
   * @param {*} projectList Project array to be filtered
   * @param {*} searchBy String to search on id and displayName field
   */
  const getSearchedProjects = (projectList, searchBy) => {
    return projectList.filter(project => {
      const projectHas = option => project[option].toUpperCase().includes(searchBy.toUpperCase());
      return projectHas('displayName') || projectHas('id');
    });
  };

  // Apply owned filter and search to update project list
  const applyFilters = () => {
    let filtered = projects;

    // Filter result if Owned Projects is checked
    if (ownerFilter !== 'none') {
      filtered = projects.filter(proj => proj.ownerId === user.id);
      setProjectCount(`${filtered.length}/${projects.length}`);
    } else {
      // Counter will be projects original size when there is no filter
      setProjectCount(filtered.length);
    }

    // Update filteredProjects on state
    setFilteredProjects(filtered);

    // Search results after updating filtered list
    if (searchingBy) {
      const searchedResult = getSearchedProjects(filtered, searchingBy);
      // Searched result will be rendered
      setSearchedProjects(searchedResult);

      // Update counter after updating search results
      setProjectCount(`${searchedResult.length}/${projects.length}`);
    } else {
      // Nothing to search, filtered list will be rendered
      setSearchedProjects(filtered);
    }
  };

  // Get owners email from authAPI
  const getOwnerInfo = () => {
    const ownerIds = projects.map(proj => proj.ownerId).filter(proj => proj);
    if (ownerIds.length > 0) {
      API.getAccountDetails(ownerIds)
        .then(ownerList => {
          setOwners(ownerList);
        })
        .catch(console.error);
    }
  };

  const createProject = () => {
    const { displayName, preferences, devices, users, isDataViewer } = newProject;
    const payload = preferences ? { displayName: displayName, preferences: preferences } : { displayName: displayName };

    API.createProject(payload)
      .then(async res => {
        // Move devices to new project
        if (devices && devices.length > 0) {
          for (const device of devices) {
            const parentProject = Object.keys(device)[0];
            await moveDevices(parentProject, res.id, Object.values(device)[0]);
          }
        }

        // Bind users to new project
        if (users && users.length > 0) {
          await bindUsers(res.id, users, isDataViewer);
        }

        loadProjects();
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(`Your project has been created`, 'success')
        });
        setCreateMode(false);
      })
      .catch(err => {
        console.error(err);
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(`Something went wrong on your new project`, 'error')
        });
        setCreateMode(false);
      });
  };

  const moveDevices = async (parentProject, destinationProject, selectedDevices) => {
    const data = {
      destination_project_id: destinationProject,
      device_ids: selectedDevices
    };

    return API.moveDevices(parentProject, data)
      .then(() => {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(`Devices moved to selected Project!`, 'success')
        });
      })
      .catch(err => {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert(`Devices couldn't be moved to selected Project!`, 'error')
        });
        console.error(err);
      });
  };

  const bindUsers = async (projectId, users, isDataViewer) => {
    const resource = `projects/${projectId}`;
    return API.getPolicy(resource)
      .then(policy => {
        const role = isDataViewer ? 'admobilize.dashboardDataViewer' : 'admobilize.dashboardUser';
        const members = users.map(user => `user:${user.id}`);

        const policyIndex = policy.bindings.findIndex(binding => binding.role === role);

        // Update policy to add new users, only if it has dashboardUser role
        if (policyIndex > -1) {
          policy.bindings[policyIndex].members = [...policy.bindings[policyIndex].members, ...members];
        } else {
          policy.bindings.push({
            role,
            members
          });
        }

        const body = {
          parent_name: 'organizations/admobilize',
          policy: policy,
          resource
        };

        API.setPolicy(resource, body)
          .then(res => {
            console.log('Users added successfuly', res);
          })
          .catch(console.error);
      })
      .catch(console.error);
  };

  const updateProject = async project => {
    try {
      const updatedProjects = projects.map(proj => {
        if (proj.id === project.id) {
          return project;
        }
        return proj;
      });

      setSelectedProject({ ...project });
      await API.updateSiteGroup(project, ['preferences', 'display_name', 'enabledIntegrations']);
      dispatch(updateProjects(updatedProjects));
      dispatch(selectProject(project));

      const message = `Preferences for Project "${project.displayName}" has changed.`;
      dispatch(setAlert(utils.generateAlert(message, 'success')));
    } catch (error) {
      dispatch(setAlert(utils.generateAlert(error.message, 'error')));
    }
  };

  const deleteProject = project => {
    API.deleteProject(project.id, project.name)
      .then(() => {
        // Reset drawer
        setDisplayProjectDetails(false);
        setSelectedProject(null);

        // Notify user
        const message = `Project "${project.displayName}" was deleted.`;
        dispatch(setAlert(utils.generateAlert(message, 'success')));

        // Load project list
        loadProjects();
      })
      .catch(console.error);
  };

  const loadProjects = () => {
    setLoading(true);
    return API.getSiteGroups()
      .then(res => {
        const sortedProjects = res.projects.sort((a, b) => utils.compareStrings(a['displayName'], b['displayName']));
        // Find the select project from redux on the returned list given by the API.
        const updatedProjectRedux = sortedProjects.find(proj => proj.id === reduxSelectedProject.id);
        //Update selected project on redux with recent changes.
        dispatch(selectProject(updatedProjectRedux));

        if (selectedProject) {
          const updatedSelectedProject = sortedProjects.find(proj => proj.id === selectedProject.id);
          // Update selected project on State if found on API return.
          setSelectedProject(updatedSelectedProject ? updatedSelectedProject : null);
        }

        // Update project list on the interface by applying current filters
        applyFilters();

        // Update list of projects on Redux
        dispatch(updateProjects(sortedProjects));
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  };

  const updateCounters = (counterName, counterValue) => {
    counters[counterName] = counterValue;
    setCounters({ ...counters });
  };

  const confirmChange = callback => {
    const question = 'Are you sure you want to do that? Changes will not be saved.';
    const shouldContinue = window.confirm(question) ? true : false;
    if (shouldContinue) {
      setEditMode(false);
      if (callback) callback();
    }
  };

  const onProjectSelect = async project => {
    const permissions = await discoverUserPermission(user, project);
    if (user.impersonator || !permissions.advertiser) {
      if (editMode) {
        confirmChange(() => {
          setDisplayProjectDetails(true);
          setSelectedProject(project);
        });
      } else {
        setDisplayProjectDetails(true);
        setSelectedProject(project);
      }
    } else {
      dispatch({
        type: types.SET_ALERT,
        payload: utils.generateAlert(`You cannot see details about this project`, 'error')
      });
    }
  };

  const oncloseDrawer = () => {
    if (editMode) {
      confirmChange(() => {
        setDisplayProjectDetails(false);
        setSelectedProject(null);
      });
    } else {
      setDisplayProjectDetails(false);
      setSelectedProject(null);
    }
  };

  const onFocusChange = cb => {
    confirmChange(() => {
      setSelectedProject({ ...selectedProject });
      if (cb) cb();
    });
  };

  const computeColumnCount = width => {
    let columnCount = 4;

    if (width < 1440) {
      columnCount = 3;
    }

    if (width < 1200) {
      columnCount = 2;
    }

    if (width < 800) {
      columnCount = 1;
    }

    return columnCount;
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }, columnCount) => {
    const index = rowIndex * columnCount + columnIndex;
    const currentProject = searchedProjects[index];

    if (!currentProject) return;
    const currentOwner = owners[currentProject.ownerId];

    return (
      <div key={key} style={style}>
        <Card
          classes={selectedProject && selectedProject.id === currentProject.id ? 'selected' : ''}
          header={
            <div>
              <h3>{currentProject.displayName}</h3>
              <div className="value-block">
                <h4 id={`card-project-owner-id-${currentProject.ownerId}`}>
                  {currentOwner ? currentOwner.email : '--'}
                </h4>
                {currentOwner && <CopyButton id={`card-project-owner-id-${currentProject.ownerId}`} />}
              </div>
            </div>
          }
          body={
            <div className="d-flex justify-content-between">
              <div className="project-id">
                <h3>Project ID</h3>
                <div className="value-block">
                  <h4 id={`card-project-id-${currentProject.id}`}>{currentProject.id}</h4>
                  <CopyButton id={`card-project-id-${currentProject.id}`} />
                </div>
              </div>
              <div className="project-token">
                <h3>Token</h3>
                <div className="value-block">
                  <h4 id={`card-project-token-${currentProject.provisioningToken}`}>
                    {currentProject.provisioningToken}
                  </h4>
                  <CopyButton id={`card-project-token-${currentProject.provisioningToken}`} />
                </div>
              </div>
            </div>
          }
          footer={
            <div className="d-flex">
              <img src={icons.projectOutline} alt="projects" />
              <h5>{currentProject.devicesCount} devices</h5>
            </div>
          }
          onPress={() => onProjectSelect(currentProject)}
        />
      </div>
    );
  };

  if (createMode) {
    return (
      <Wizard
        title={'Create new Project'}
        icon={'projectDarkOutline'}
        steps={newProjectSteps(newProject, setNewProject)}
        onSubmit={createProject}
        onCancel={() => setCreateMode(false)}
      />
    );
  }

  // Layout constraints definitions
  const cardOuterPadding = 24;
  const containerOuterElementsHeight = 183;

  return (
    <div id="projects">
      <Toolbar
        title={'Projects'}
        icon={'projectDarkOutline'}
        count={projectCount}
        searchPlaceholder={'Search Project'}
        onSearch={onSearch}
        renderFilters={
          <CustomCheckBox
            label={'Owned Projects'}
            selected={ownerFilter}
            onClick={() => setOwnerFilter(ownerFilter !== 'none' ? 'none' : 'all')}
          />
        }
        hasButton={user && (user.permissions['support'] || user.impersonator)}
        buttonTitle={'Create new project'}
        buttonPress={() => {
          setNewProject(NEW_PROJECT_SKELETON);
          setCreateMode(true);
        }}
      />
      <div className="projects-container">
        {loading ? (
          <div className="loading-container">
            <Loading size="15" />
          </div>
        ) : (
          <div style={{ width: '100%', height: window.innerHeight - containerOuterElementsHeight }}>
            {searchedProjects && searchedProjects.length > 0 && (
              <AutoSizer>
                {({ width, height }) => {
                  const columnCount = computeColumnCount(width);
                  const columnWidth = (width - cardOuterPadding) / columnCount;
                  const rowCount = Math.ceil(searchedProjects.length / columnCount);
                  return (
                    <Grid
                      className={`projects-grid ${selectedProject ? 'drawer-open' : ''}`}
                      containerStyle={{ overflowY: 'auto' }}
                      cellRenderer={({ columnIndex, key, rowIndex, style }) =>
                        cellRenderer({ columnIndex, key, rowIndex, style }, columnCount)
                      }
                      height={height}
                      width={width}
                      rowCount={rowCount}
                      columnCount={columnCount}
                      rowHeight={244}
                      columnWidth={columnWidth}
                    />
                  );
                }}
              </AutoSizer>
            )}
          </div>
        )}

        <DetailsDrawer
          title="Project Details"
          isEditing={editMode}
          onFocusChange={onFocusChange}
          onClose={oncloseDrawer}
          sections={projectSections({
            project: selectedProject,
            loadProjects,
            projects,
            editMode,
            setEditMode,
            updateProject,
            deleteProject,
            isAdmin: !!(user && (user.permissions['support'] || user.impersonator)),
            counters,
            updateCounters
          })}
          hidden={!displayProjectDetails}
        />
      </div>
    </div>
  );
};

export default ProjectsContainer;
