import React from 'react';
import { useSelector } from 'react-redux';
import {
  getWidgetConfig,
  replaceCustomVariables,
  getWidgetOptions,
  getWidgetQuery,
  getStringfiedField
} from '../../utils/widgets';
import { getSelectedProject } from '../../redux/projects/actions';
import { getSelectedAnalyticsDashboard, getSelectedSummaryDashboard } from '../../redux/templates/actions';
import { getSpeedUnitOption, getMultiplyValues } from '../../utils/project';
import Heatmap from './heatmap';
import { availableCMS } from '../../utils/solutions';
import { getSelectedCompany } from '../../redux/company/actions';

const Widget = ({ widget, filters, lastUpdated, handleError, eventHandlers, collection = 'SUMMARY' }) => {
  const project = useSelector(getSelectedProject);
  const company = useSelector(getSelectedCompany);
  const selectedDashboard =
    collection === 'SUMMARY' ? useSelector(getSelectedSummaryDashboard) : useSelector(getSelectedAnalyticsDashboard);

  if (!project || !selectedDashboard || !widget) {
    return null;
  }

  const speedUnitOptions = getSpeedUnitOption(project.preferences.speedUnit);
  const multiplyValues = getMultiplyValues(project.preferences.vehicleMultiplier);
  const currentCMS = availableCMS[selectedDashboard.legacyTable];

  // Inject custom variables
  const variableToReplace = project
    ? {
        ...project.preferences,
        projectId: project.id,
        solutionId: selectedDashboard.legacyTable,
        conversionFactor: speedUnitOptions.conversionFactor,
        speedUnit: speedUnitOptions.shortName,
        interval: filters?.interval?.name ? filters.interval.name : '',
        multiplyValues,
        siteColumn: currentCMS ? currentCMS.columns.sites : '',
        mediaColumn: currentCMS ? currentCMS.columns.medias : '',
        advertiserColumn: currentCMS ? currentCMS.columns.advertisers : '',
        cmsDescriptor: currentCMS ? JSON.stringify(currentCMS) : '',
        // Add filters to cover Mobile Data params
        ...filters
      }
    : {};

  const newWidget = replaceCustomVariables(widget, variableToReplace);

  if (newWidget.requestResult) {
    // Listen to requestResult
    eventHandlers.push(newWidget.requestResult);
    window.addEventListener('requestResult', newWidget.requestResult);
  }

  // Listen to requestError
  eventHandlers.push(handleError);
  window.addEventListener('requestError', handleError);

  if (widget.display === 'heatmap') {
    return <Heatmap query={filters} widget={newWidget} />;
  }

  return (
    <widget-container
      key={`analytics-${newWidget.id}`}
      id={newWidget.id}
      info={getStringfiedField(newWidget, 'info')}
      format={newWidget.format}
      unit={newWidget.unit}
      plot-options={getStringfiedField(newWidget, 'plotOptions')}
      widget-title={newWidget.title}
      data={newWidget.data}
      display={newWidget.display}
      config={getWidgetConfig(newWidget.config, company.id)}
      options={getWidgetOptions(newWidget)}
      query={getWidgetQuery(newWidget, filters, selectedDashboard, lastUpdated)}
      is-loading={filters?.loading}
      y-title={newWidget.yTitle}
    />
  );
};

export default Widget;
