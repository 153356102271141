import credentialsStore from './credentialsStore';
import utils from './../utils';
import API from './api';

const generateResponse = resp => {
  let json = resp.json().catch(() => ({}));
  if (resp.ok) {
    return json;
  }
  return json.then(err => {
    throw err;
  });
};

const getImpersonatorHeader = () => {
  const token = API.isImpersonating ? API.originalToken : API.access_token;
  return { ...API.headers, Authorization: `Bearer ${token}` };
};

const logout = () => {
  API.access_token = API.refresh_token = null;
  API.isImpersonating = false;
  API.originalToken = null;
  API.userId = '';
  credentialsStore.purge();
  utils.purgeShowMask();
};

export default { generateResponse, getImpersonatorHeader, logout };
