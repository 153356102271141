import config from '../../config';
import API from '../api';

const { baseUrl } = config.cmsApi;

const checkCredentials = (cmsClient, credentials, credentialFieldName) => {
  const url = `${baseUrl}/${cmsClient}/credentials/-/check`;
  const body = {
    cms_client: cmsClient,
    credential: {
      [credentialFieldName]: credentials
    }
  };

  return fetch(url, {
    headers: API.headers,
    body: JSON.stringify(body),
    method: 'POST'
  }).then(res => API.generateResponse(res));
};

export { checkCredentials };
