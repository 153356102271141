import config from '../../config';
import API from '../api';

const { baseUrl } = config.coreApi;

//#region Types
/**
 * @typedef QueryBody
 * @type {Object}
 * @property {[{deviceId: string, deviceName: string}]} devices - List of devices associated with the report.
 * @property {string} endTime - End time of the report.
 * @property {string} epoch - Epoch time for the report.
 * @property {{column: string, value: string}} filterBy - Filter criteria for the report.
 * @property {{end: string, start: string}} filterByTime - Time filter for the report.
 * @property {number[]} filterByWeekday - Array representing the weekdays to filter (0 = Sunday, 6 = Saturday).
 * @property {string[]} groupBy - Array of columns to group the report by.
 * @property {string} layout - Layout of the report.
 * @property {number} maxResults - Maximum number of results to return.
 * @property {string} pageToken - Token for paginated results.
 * @property {string} project - Project associated with the report.
 * @property {string} startTime - Start time of the report.
 * @property {string} table - Table associated with the report.
 * @property {string} timezone - Timezone of the report.
 */

/**
 * @typedef QueryRequest
 * @type {Object}
 * @property {{as: string, metric: string, operation: string}} aggregateResult - Aggregate result for the query.
 * @property {[{conditions: [{end: number, start: number, then: string, when: string}], field: string, name: string, operation: string}]} classifiers - Classifiers used in the query.
 * @property {{columns: {advertisers: string, medias: string, sites: string, timestamp: string}, name: string, playlogTable: string, table: string}} cmsDescriptor - CMS descriptor for the query.
 * @property {{as: string, name: string}} cmsDimension - CMS dimension for the query.
 * @property {[{cast: string, conversionFactor: number, field: string, filter: string[], name: string, operation: string, order: string}]} cmsMetrics - CMS metrics used in the query.
 * @property {string[]} dimensions - Dimensions used in the query.
 * @property {boolean} dimensionsEncoder - Whether dimensions encoding is enabled.
 * @property {{dimensions: string[], metrics: string[]}} encoderParams - Encoder parameters for the query.
 * @property {string} endTime - End time of the query.
 * @property {string} epoch - Epoch time for the query.
 * @property {number[]} filterByHour - Hours to filter the query by.
 * @property {{end: string, start: string}} filterByTime - Time filter for the query.
 * @property {number[]} filterByWeekday - Weekdays to filter the query by (0 = Sunday, 6 = Saturday).
 * @property {boolean} hasComparison - Whether a comparison is included in the query.
 * @property {number} limit - Limit for the query results.
 * @property {[{cast: string, conversionFactor: number, field: string, filter: string[], name: string, operation: string, order: string}]} metrics - Metrics used in the query.
 * @property {[{column: string, order: string}]} orderBy - Columns to order the results by.
 * @property {boolean} previous - Whether to include previous results.
 * @property {string} project - Project associated with the query.
 * @property {{avg: string[], groupBy: string[], orderBy: [{column: string, order: string}], select: string[], sum: string[]}} queryFromResult - Sub-query derived from the result.
 */
//#endregion

//#region CREATE
/**
 * Get the data of a single project table.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {string} did - Device ID.
 * @param {QueryBody} data - Query Object.
 * @returns {{"pageToken": "string","rows": [{}]}} Data from project table
 */
const getTableData = async (cid, pid, tid, data) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/tables/${tid}/data`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: JSON.stringify(data)
  }).then(res => res.json());
};
/**
 * Execute query synchronously and return the results.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {string} did - Device ID.
 * @param {QueryRequest} data - Query Object.
 * @returns {{"datasets": [{"label": "string","value": {}}],"tid": "string"}} Query result
 */
const getCMSData = async (cid, pid, tid, data) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/tables/${tid}/query`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: data
  }).then(res => res.json());
};
//#endregion

//#region READ
/**
 * List project tables in BigQuery.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @returns {{"tables" : [string]}} tables
 */
const getTables = async (cid, pid) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/tables`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Get the schema of a single project table.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {string} tid - Tablet ID.
 * @returns {{ "lastModifiedTime": 0, "numBytes": 0, "numRows": 0, "schema": [{"name": "string","type": "string"}]}} Project table schema
 */
const getTableSchema = async (cid, pid, tid) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/tables/${tid}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Gets a single device heatmap.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {string} tid - Tablet ID.
 * @param {string} did - Device ID.
 * @returns {{"data": [{"value": 0,"x": 0,"y": 0}]}} Device heatmap data
 */
const getHeatmapData = async (cid, pid, tid, did, startTime, endTime, filters) => {
  const filterStr = filters
    .map(filter => {
      if (filter.operator && filter.operator !== '') {
        return `(${filter.path}${filter.operator}${filter.value})`;
      }

      return `(${filter.path}="${filter.value}")`;
    })
    .join(' AND ');

  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/tables/${tid}/devices/${did}/heatmap?startTime=${startTime}&endTime=${endTime}&filter="${filterStr}"`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};
//#endregion

export { getTables, getTableSchema, getHeatmapData, getTableData, getCMSData };
