import types from '../types.js';
import { setState } from '../../utils/redux.js';

const initialState = {
  summaryTemplates: null,
  analyticsTemplates: null,
  selectedSummaryDashboard: null,
  selectedAnalyticsDashboard: null
};

const templateReducer = (state = initialState, action) => {
  const { LOAD_SUMMARY_TEMPLATES, LOAD_ANALYTICS_TEMPLATES, SELECT_SUMMARY_DASHBOARD, SELECT_ANALYTICS_DASHBOARD } =
    types;

  switch (action.type) {
    case LOAD_SUMMARY_TEMPLATES:
      return setState(state, 'summaryTemplates', action.payload);
    case LOAD_ANALYTICS_TEMPLATES:
      return setState(state, 'analyticsTemplates', action.payload);
    case SELECT_SUMMARY_DASHBOARD:
      return setState(state, 'selectedSummaryDashboard', action.payload);
    case SELECT_ANALYTICS_DASHBOARD:
      return setState(state, 'selectedAnalyticsDashboard', action.payload);
    default:
      return state;
  }
};

export default templateReducer;
