const reportTypes = [
  { id: 'quick', name: 'Quick CSV' },
  { id: 'custom', name: 'Custom CSV' },
  { id: 'pdf', name: 'Quick PDF' }
];

// Set initial values on reports object
const parse = (reports, projects) => {
  return reports.map(report => {
    const project = projects.find(proj => proj.id === report.projectId);

    return {
      ...report,
      projectName: project ? project.displayName : report.projectId
    };
  });
};

const loadSelectedDay = (frequency, daysToSend) => {
  // Format selectedDay based on frequency
  if (frequency === 'monthly') {
    const day = parseInt(daysToSend[0]);

    if (day >= 31) {
      return '31';
    } else if (day === 1) {
      return '1';
    }

    return 'custom';
  }
  return daysToSend ? daysToSend[0] : 0;
};

const getCustomReportTemplate = filters => {
  const commonsFields = {
    devices: Array.from(filters.selectedDevices),
    email: filters.user.email,
    endDate: filters.endDate,
    filterByTime: filters.filterByTime,
    filterByWeekday: filters.filterByWeekday,
    format: 'default',
    project: filters.projectId,
    solution: filters.selectedSolution,
    startDate: filters.startDate,
    table: filters?.selectedSolution?.table,
    timezone: filters.project.preferences.timezone,
    type: filters?.selectedSolution?.type
  };

  const cmsFields = {
    advertisers: filters.selectedAdvertisers.map(advertiser => advertiser.name),
    medias: filters.selectedMedias.map(media => media.name)
  };

  const singleReportFields = {
    epoch: filters.interval,
    fields: []
  };

  const recurringReportFields = {
    daysToSend: [],
    frequency: 'single',
    interval: filters?.interval
  };

  return {
    ...commonsFields,
    ...cmsFields,
    ...recurringReportFields,
    ...singleReportFields
  };
};

const generateUpdateReportObject = report => {
  return {
    daysToSend: report.daysToSend,
    email: report.email,
    endTime: report.endDate,
    filterByTime: report.filterByTime,
    filterByWeekday: report.filterByWeekday,
    format: report.format,
    frequency: report.frequency,
    interval: report.interval,
    name: report.name,
    project: report.projectId,
    report: report.id,
    speedUnit: report?.speedUnit,
    timezone: report.timezone,
    type: report.type
  };
};

const generateCMSQueryObject = (field, startDate, endDate) => {
  return JSON.stringify({
    metrics: [
      {
        field: field,
        operation: 'distinct',
        name: field
      }
    ],
    aggregateResult: {},
    dimensions: [],
    dimensionsEncoder: false,
    epoch: '',
    hasComparison: false,
    startTime: startDate,
    endTime: endDate
  });
};

export default {
  reportTypes,
  loadSelectedDay,
  parse,
  getCustomReportTemplate,
  generateCMSQueryObject,
  generateUpdateReportObject
};
