import { appendState, setState } from '../../utils/redux.js';
import types from '../types.js';

const initialState = {
  selectedProject: null,
  selectedSolution: null,
  solutions: [],
  projects: [],
  solutionsConfig: {}
};

const projectReducer = (state = initialState, action) => {
  const {
    LOAD_PROJECTS,
    CREATE_PROJECT,
    SELECT_PROJECT,
    UPDATE_SELECTED_PROJECT,
    UPDATE_PROJECTS,
    SET_PROJECT_TABLES,
    CLEAR_PROJECT,
    LOAD_SOLUTIONS,
    SELECT_SOLUTION,
    UPDATE_SELECT_SOLUTION,
    LOAD_SOLUTIONS_CONFIG,
    UPDATE_SOLUTION_CONFIG,
    CREATE_SOLUTION_CONFIG
  } = types;

  switch (action.type) {
    case LOAD_SOLUTIONS_CONFIG:
      return setState(state, 'solutionsConfig', action.payload);
    case UPDATE_SOLUTION_CONFIG: {
      const configId = action.payload.id;
      const solutionId = action.payload.solutionId;
      const solutionsConfigByType = [...state.solutionsConfig[solutionId]];
      const configFound = solutionsConfigByType.findIndex(config => config.id === configId);
      solutionsConfigByType[configFound] = { ...action.payload };

      return setState(state, 'solutionsConfig', {
        ...state.solutionsConfig,
        [solutionId]: solutionsConfigByType
      });
    }
    case CREATE_SOLUTION_CONFIG: {
      const solutionId = action.payload.solutionId;
      return {
        ...state,
        solutionsConfig: {
          ...state.solutionsConfig,
          [solutionId]: [...state.solutionsConfig[solutionId], action.payload]
        }
      };
    }
    case CREATE_PROJECT:
      return appendState(state, 'projects', action.payload);
    case SELECT_PROJECT:
    case CLEAR_PROJECT:
    case UPDATE_SELECTED_PROJECT:
    case SET_PROJECT_TABLES:
      return setState(state, 'selectedProject', action.payload);
    case UPDATE_PROJECTS:
    case LOAD_PROJECTS:
      return setState(state, 'projects', action.payload);
    case LOAD_SOLUTIONS:
      return setState(state, 'solutions', action.payload);
    case SELECT_SOLUTION:
    case UPDATE_SELECT_SOLUTION:
      return setState(state, 'selectedSolution', action.payload);
    default:
      return state;
  }
};

export default projectReducer;
