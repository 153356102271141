import React, { useEffect, useState, useRef, useCallback } from 'react';
import { isAfter, parseISO } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';

import { setAlert } from '../../redux/dashboard/actions';
import { getUser } from '../../redux/user/actions';
import { getProjects } from '../../redux/projects/actions';
import { loadLicenses } from '../../redux/devices/actions';
import API from '../../services/api';
import utils from '../../utils';
import components from '../../components';

import icons from '../../assets/icons';
import WidgetRow from './widgetRow';
import tableHeaders from './tableHeaders';
import ActionButtons from './actionButtons';
import CreateLicenseModal from './createLicense';
import ReassignLicenseModal from './reassignModal';
import ExpirationModal from './expirationModal';
import MultipleFilters from '../../components/multipleFilters';

import filterUtils from '../../utils/filters';
import api from '../../services/api';
import './styles.scss';

const { Table, CustomButton, SearchBox } = components;

const LicensesContainer = ({ lastUpdated }) => {
  const [licenses, setLicenses] = useState([]); // Data returned by API
  const [data, setData] = useState([]); // Data displayed on the table (search)
  const [items, setItems] = useState([]); // Pagination
  const [filteredLicenses, setFilteredLicenses] = useState([]);
  const [devices, setDevices] = useState([]);
  const [deviceStatus, setDeviceStatus] = useState([]);
  const [columns, setColumns] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingDeviceStatus, setLoadingDeviceStatus] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [showExpirationModal, setShowExpirationModal] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState({});
  const [searchBy, setSearchBy] = useState('');

  const fetchIdRef = useRef(0);

  const user = useSelector(getUser);
  const projects = useSelector(getProjects);
  const dispatch = useDispatch();

  // CDM & update license after navigation or redux changes
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getLicensesFromAPI();
      getDeviceStatus();
    }
    return () => {
      isMounted = false;
    };
  }, [user, lastUpdated]);

  useEffect(() => {
    setFilteredLicenses(licenses);
    setSearchBy('');
  }, [licenses]);

  // Update list on typed search
  useEffect(() => {
    setData(onSearch());
  }, [filteredLicenses, searchBy]);

  // Bind status to license devices after getting device info
  useEffect(() => {
    bindDeviceInfo(devices, licenses);
    getStatusTotals();
  }, [devices]);

  const licenseHas = (option, license) => {
    return license[option] && license[option].toUpperCase().includes(searchBy.toUpperCase());
  };

  const onSearch = () => {
    if (searchBy === '') {
      return filteredLicenses;
    }

    // Update licenses based on search box content
    const searchedLicense = filteredLicenses.filter(license => {
      return licenseHas('id', license) || licenseHas('deviceId', license) || licenseHas('deviceName', license);
    });
    return searchedLicense;
  };

  const getLicensesFromAPI = () => {
    if (user) {
      setLoading(true);

      API.listLicenses()
        .then(res => {
          const fetchedLicenses = res.licenses;

          setColumns(tableHeaders(projects));
          if (fetchedLicenses && fetchedLicenses.length > 0) {
            setLicenses(fetchedLicenses);
            bindDeviceInfo(devices, fetchedLicenses);
            dispatch(loadLicenses(fetchedLicenses));
          } else {
            setData([]);
          }
        })
        .catch(err => {
          console.error(err);
          dispatch(setAlert(utils.generateAlert('Could not fetch licenses. Try again later.', 'error')));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getDeviceStatus = () => {
    if (user) {
      setLoadingDeviceStatus(true);
      api
        .getDevices('-', ['state', 'displayName'])
        .then(res => {
          if (res.devices) {
            setDevices(res.devices);
          }
        })
        .catch(console.error)
        .finally(() => setLoadingDeviceStatus(false));
    }
  };

  // Bind device status info, from DM API, with licenses
  const bindDeviceInfo = (devicesCopy, licensesCopy) => {
    devicesCopy.forEach(device => {
      const deviceIndex = licensesCopy.findIndex(item => item.deviceId === device.id);
      if (deviceIndex > -1) {
        licensesCopy[deviceIndex].deviceStatus = device.state ? device.state.status : '--';
        licensesCopy[deviceIndex].deviceName = device.displayName ? device.displayName : device.id;
      }
    });

    // Update licenses to re-render table
    setLicenses(licensesCopy);
  };

  const getStatusTotals = () => {
    const totals = {};

    devices.forEach(device => {
      if (device.state && device.state.status) {
        if (totals[device.state.status]) {
          totals[device.state.status].push(device.id);
        } else {
          totals[device.state.status] = [device.id];
        }
      }
    });

    // Get existing status for current devices
    const totalKeys = Object.keys(totals);
    const status = totalKeys.map(key => {
      return { label: key, value: totals[key].length };
    });

    if (status.length > 0) {
      setDeviceStatus(status);
    }
  };

  // This is called when the table needs new data
  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      // Load table empty state
      if (data.length === 0) {
        setItems([]);
        setItemsCount(0);
        setPageCount(0);
        return;
      }

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Generate sort config object
      const sortByQuery = sortBy[0];
      const sortByConfig = utils.generateSortByConfig(sortByQuery, 'projectName', columns);

      // Perform data sort
      const localItems = utils.sort(data, sortByConfig);

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;

        // Retrieve fetched data
        setItems(localItems.slice(startRow, endRow));

        // Compute total number of items
        setItemsCount(localItems.length);

        // Compute total page count
        setPageCount(Math.ceil(localItems.length / pageSize));
      }
    },
    [data, columns]
  );

  const getAssignedLicenses = () => data.filter(item => item.deviceId);

  const getExpiredLicenses = () =>
    data.filter(item => {
      return isAfter(new Date(), new Date(parseISO(item.validUntil)));
    });

  const reassignLicense = license => {
    setShowReassignModal(true);
    setSelectedLicense(license);
  };

  const setExpirationDate = license => {
    setShowExpirationModal(true);
    setSelectedLicense(license);
  };

  const onReassign = (deviceId, projectId) => {
    const newLicense = { ...selectedLicense, deviceId, projectId };
    api
      .updateLicense(newLicense, ['deviceId', 'projectId'])
      .then(() => {
        getLicensesFromAPI();
      })
      .catch(error => {
        console.error(error);
        dispatch(setAlert(utils.generateAlert('Could not bind this device. Try again later.', 'error')));
      })
      .finally(() => setShowReassignModal(false));
  };

  const onExpirationChange = (validUntil, demo) => {
    api
      .updateLicense(
        {
          ...selectedLicense,
          demo: demo,
          validUntil: new Date(validUntil).toISOString()
        },
        ['validUntil', 'demo']
      )
      .then(() => {
        getLicensesFromAPI();
      })
      .catch(error => {
        console.error(error);
        dispatch(setAlert(utils.generateAlert('Could set new expiration date. Try again later.', 'error')));
      })
      .finally(() => setShowExpirationModal(false));
  };

  const generateFilters = () => {
    const filters = filterUtils.replaceFilterOptions(
      filterUtils.getFilters(['Solution', 'Status', 'Project', 'Demo'], true),
      'Project',
      projects.map(project => ({ ...project, label: project.displayName, value: project.id, isAdminOnly: true }))
    );

    // replace type on status filter to fit the screen.
    filters[1].type = 'dropdown';
    // replace path license status because it's related to the device into the license.
    filters[1].path = 'deviceStatus';
    return filters;
  };

  return (
    <div className="licenses-container">
      <div className="toolbar">
        <div className="title">
          <img src={icons.key} alt="Licenses" />
          <h2>Licenses</h2>
          <SearchBox placeholder={'Search by License or Device'} value={searchBy} onChange={setSearchBy} />
          <div className="d-flex align-items-center">
            <MultipleFilters
              items={licenses}
              filters={generateFilters()}
              onFilter={filteredLicenses => {
                setFilteredLicenses(filteredLicenses);
              }}
              renderAsButton
            />
          </div>
        </div>
        <div>
          <CustomButton title="Create Licenses" classes="btn-primary" handleClick={() => setShowCreateModal(true)} />
        </div>
      </div>

      <WidgetRow
        isLoading={loadingDeviceStatus}
        data={{
          totalLicenses: itemsCount,
          totalDevices: devices.length,
          assignedLicenses: getAssignedLicenses().length,
          expiredLicenses: getExpiredLicenses().length,
          deviceStatus
        }}
      />

      <Table
        columns={columns}
        data={items}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        itemsCount={itemsCount}
        onItemSelected={() => {}}
        dataLabel={'licenses'}
        //TODO: bulkActionButtons={() => renderBulkActionButtons()}
        actionButtons={license => (
          <ActionButtons
            buttons={[
              { title: 'Reassign License', key: 'reassign-license', onPress: reassignLicense },
              { title: 'Set Expiration Date', key: 'set-expiration-date', onPress: setExpirationDate }
            ]}
            license={license}
          />
        )}
      />
      <CreateLicenseModal
        isOpen={showCreateModal}
        onLicensesCreation={getLicensesFromAPI}
        toggleModal={() => setShowCreateModal(!showCreateModal)}
      />
      <ReassignLicenseModal
        license={selectedLicense}
        isOpen={showReassignModal}
        onReassign={onReassign}
        toggleModal={() => setShowReassignModal(!showReassignModal)}
      />
      <ExpirationModal
        license={selectedLicense}
        isOpen={showExpirationModal}
        onExpirationChange={onExpirationChange}
        toggleModal={() => setShowExpirationModal(!showExpirationModal)}
      />
    </div>
  );
};

export default LicensesContainer;
