import React from 'react';

const messages = {
  gdpr: {
    title: 'Important Notice',
    message: `In accordance with the EU GDPR rights to privacy, you affirm that you, a data controller,
    have obtained the necessary consent required for AdMobilize, a data processor,
    to transmit this image for you to view from anyone whose personally identifiable information
    may be contained in the image if said image is from a camera within the EU.`,
    link: 'https://www.admobilize.com/#!/policies/gdpr',
    acceptButton: 'Accept'
  },
  archiveDevice: {
    title: 'Device Archive Warning!',
    actionDetails: deviceName => (
      <>
        <b>This action will affect this device: </b>
        {deviceName}
      </>
    ),
    message: (
      <>
        Are you sure that you want to archive this device? Contact{' '}
        <a href="mailto:support@admobilize.com">support@admobilize.com</a> to reactivate this device and restore the
        data.
        <br />
        <br />
        Archived device data will be <b>deleted after 180 days</b>.
      </>
    ),
    acceptButton: 'Proceed'
  },
  enableHeatmap: {
    title: 'Device Archive Warning!',
    actionDetails: deviceName => (
      <>
        <b>This action will affect this device: </b>
        {deviceName}
      </>
    ),
    message: <>Are you sure that you want to enable heatmap for this device?</>,
    acceptButton: 'Proceed'
  },
  disableHeatmap: {
    title: 'Device Archive Warning!',
    actionDetails: deviceName => (
      <>
        <b>This action will affect this device: </b>
        {deviceName}
      </>
    ),
    message: <>Are you sure that you want to disable heatmap for this device?</>,
    acceptButton: 'Proceed'
  },
  deleteReport: {
    title: 'Please, take caution!',
    actionDetails: reportName => `This action will affect this report: ${reportName}`,
    message: `Are you sure that you want to delete this report?`,
    acceptButton: 'Proceed'
  },
  deleteProject: {
    title: 'Please, take caution!',
    actionDetails: project => {
      let msg = `This action will affect the project (${project.displayName}`;
      msg += project.devicesCount ? `) and ${project.devicesCount} devices will be deleted.` : ')';
      return msg;
    },
    message: 'Are you sure that you want to delete this project?',
    acceptButton: 'Proceed'
  },
  deleteSite: {
    title: 'Please, take caution!',
    actionDetails: site => {
      let msg = `This action will affect the site (${site.displayName}`;
      msg += site.attachedDevices.lenght > 0 ? `) and ${site.attachedDevices.lenght} devices will be deleted.` : ')';
      return msg;
    },
    message: 'Are you sure that you want to delete this site?',
    acceptButton: 'Proceed'
  },
  removePinVersion: {
    title: 'Remove Pin Version',
    message: `Are you sure that you want remove the version pinned?`,
    acceptButton: 'Proceed'
  }
};

export default messages;
