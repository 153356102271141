import React, { useEffect, useState } from 'react';
import CustomInput from '../../../components/customInput';
import CustomTextArea from '../../../components/customTextArea';
import SiteTypeSelector from '../../../components/filterSelector/SiteTypeSelector.js';
// import SiteGroupSelector from '../../../components/filterSelector/SiteGroupSelector.js';
import utils from '../../../utils';
// import API from '../../../services/api.js';
// import { getSelectedProject } from '../../../redux/projects/actions.js';
// import { useSelector } from 'react-redux';
// import { getSelectedCompany } from '../../../redux/company/actions.js';

const Settings = ({ site, updateSite }) => {
  const [displayName, setDisplayName] = useState(site.displayName ? site.displayName : '');
  const [description, setDescription] = useState(site.description ? site.description : '');
  const [errors, setErrors] = useState(site.errors ? site.errors : {});
  // const [selectedGroup, setSelectedGroup] = useState({ displayName: 'Select Site Group' });
  const [selectedType, setSelectedType] = useState(
    site.type ? { label: site.type.displayName, value: site.type.id } : { label: 'Select Site Type' }
  );
  // const project = useSelector(getSelectedProject);
  // const company = useSelector(getSelectedCompany);

  // Validate fields
  useEffect(() => {
    const validation = {
      'site-display-name': [
        { key: 'required', value: true },
        { key: 'min', value: 4 }
      ]
    };

    const errorList = {
      'site-display-name': utils.fieldValidation(displayName, validation['site-display-name']).join(', ')
    };
    setErrors(errorList);
    updateSite({ ...site, errors: { ...site.errors, ...errorList }, displayName: displayName });
  }, [displayName]);

  // useEffect(() => {
  //   getSiteGroupOption();
  // }, [site.id]);

  // const getSiteGroupOption = () => {
  //   API.getSiteGroup(company.id, project.id).then(res => {
  //     setSelectedGroup({ label: res.displayName, value: res.id });
  //   });
  // };

  return (
    <div>
      <h4 className="section-label">Configure name, site type, site group and put a description</h4>
      <div className="form-group">
        <label className={`${displayName.length < 4 ? 'input-error' : ''}`}>Site Name*</label>
        <CustomInput
          id="site-name"
          placeholder="Write the site name"
          type="text"
          value={displayName}
          isEditable={true}
          onChange={e => {
            setDisplayName(e.target.value);
          }}
          autoFocus={true}
          errorMessage={errors['site-display-name']}
        />
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col">
            <label className={`${selectedType.value ? '' : 'input-error'}`}>Site Type*</label>
            <SiteTypeSelector
              selected={selectedType}
              filterObject={{}}
              onSelect={(selectedValue, filterObject) => {
                const newType = filterObject.options.find(option => option.value === selectedValue);
                setSelectedType(newType);
                updateSite({ ...site, type: newType.data });
              }}
            />
          </div>
          {/* <div className="col-6">
            <label>Site Group</label>
            <SiteGroupSelector
              disabled // TODO: Remove this when there is an implementation for transfer site to another siteGroup
              selected={selectedGroup}
              filterObject={{}}
              onSelect={(selectedValue, filterObject) => {
                const newSiteGroup = filterObject.options.find(option => option.value === selectedValue)
                setSelectedGroup(newSiteGroup);
                updateSite({ ...site, projectId: newSiteGroup });
              }} />
          </div> */}
        </div>
      </div>
      <div className="form-group description-field">
        <label>Description</label>
        <CustomTextArea
          isEditable
          value={description}
          onChange={e => {
            updateSite({ ...site, description: description });
            setDescription(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default Settings;
