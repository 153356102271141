import * as authEnpoints from './auth';
import * as cmsEnpoints from './cms';
import * as licensesEnpoints from './licenses';
import * as notificationsEnpoints from './notification';
import * as permEnpoints from './permissions';
import * as presetsEnpoints from './presets';

export default {
  ...authEnpoints,
  ...cmsEnpoints,
  ...licensesEnpoints,
  ...notificationsEnpoints,
  ...permEnpoints,
  ...presetsEnpoints
};
