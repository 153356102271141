import React from 'react';
import CreateDeviceSection from './createDeviceSection';
import ProvisionDeviceSection from './provisionDeviceSection';

export default function deviceSteps(setNewDevices) {
  return [
    {
      title: 'Create Devices',
      label: 'Devices will be available to assign later',
      body: <CreateDeviceSection setNewDevices={setNewDevices} />,
      previousButtonLabel: 'Cancel',
      nextButtonLabel: 'Create',
      isNextDisabled: firstDevice => {
        return !firstDevice || firstDevice.hasErrors;
      }
    },
    {
      title: 'Provision Devices',
      label: 'The devices are already available to assign',
      body: <ProvisionDeviceSection />,
      previousButtonLabel: 'Cancel',
      nextButtonLabel: 'Provision Device'
    }
  ];
}
