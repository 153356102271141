import React from 'react';
import calendarUtils from '../../utils/calendar';
import { useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/actions';
import { getSelectedAnalyticsDashboard } from '../../redux/templates/actions';

const HeaderPDF = ({
  filters,
  devices
}) => {
  const project = useSelector(getSelectedProject);
  const selectedDashboard = useSelector(getSelectedAnalyticsDashboard);

  const displayDevicesName = () => {
    const devicesToDisplay = [];

    if (filters.selectedDevices.length === devices.length) {
      return <b>All</b>;
    }

    if (filters.selectedDevices.length >= 4) {
      const otherDevicesCount = filters.selectedDevices.length - 3;

      for (let i = 0; i < 3; i++) {
        devicesToDisplay.push(filters.selectedDevices[i]);
      }

      otherDevicesCount === 1
        ? devicesToDisplay.push(`and ${otherDevicesCount} other`)
        : devicesToDisplay.push(`and ${otherDevicesCount} others`);

      return <b>{devicesToDisplay.join(', ')}</b>;
    } else {
      filters?.selectedDevices?.forEach(currentDevice => {
        devicesToDisplay.push(currentDevice);
      });
    }

    return <b>{devicesToDisplay.join(', ')}</b>;
  };

  if (!filters) {
    return null;
  }

  if (!filters.interval) {
    return null;
  }

  return (
    <div className="show-only-print">
      <div className="d-flex flex-column">
        <div className="company-logo d-flex flex-column">
          <div className="company-logo-img d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column company-logo-title">
              <h2 className="report-title"> Report</h2>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between w-100">
            <div className="d-flex mt-2 flex-column">
              <h5>
                Start Date: <b>{filters.startDate && calendarUtils.getDateTime(filters.startDate, 'date')}</b>
              </h5>
              <h5>
                End Date: <b>{filters.endDate && calendarUtils.getDateTime(filters.endDate, 'date')}</b>
              </h5>
              <h5>
                Interval: <b>{filters.interval.name}</b>
              </h5>
            </div>
            <div className="d-flex mt-2 flex-column">
              <h5>
                Timezone: <b>{filters.timezone}</b>
              </h5>
              <h5>
                Project: <b>{project && project.displayName}</b>
              </h5>
              <h5>
                Dashboard: <b>{selectedDashboard && selectedDashboard.displayName ?  selectedDashboard?.displayName : selectedDashboard?.id}</b>
              </h5>
            </div>
          </div>
          {filters.selectedDevices.length === 1 ? (
            <h5>Device: {displayDevicesName()}</h5>
          ) : (
            <h5>Devices: {displayDevicesName()}</h5>
          )}
          { selectedDashboard?.legacyTable === 'facev2' && 
            <>
              <h5>Glossary of Terms:</h5>
              <p><b>Impression</b> - Every person detected within the field of view of the camera.</p>
              <p><b>View</b> - Every person that the software is able to verify that it has looked towards the camera for over 0.5 seconds.</p>
              <p><b>Gaze Thru Rate (%)</b> - Views divided by Impressions. Also known as Engagement Rate.</p>
              <p><b>Session Time</b> - The length of time an Impression (person) has been in the field of view of the camera.</p>
              <p><b>Attention Time</b> - The length of time a View was captured looking at the screen.</p>
              <p><b>Age Groups</b> - {"Young = 0-19 years old, Young Adult = 20-29, Adult = 30-59, Senior >= 60."}</p>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default HeaderPDF;
