import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import IconButton from '../iconButton';
import OffcanvasDrawer from '../offcanvasDrawer';
import NotificationSettings from '../notificationSettings';
import NotificationList from '../notificationList';
import Tabs from '../tabs';
import { getUser } from '../../redux/user/actions';
import { getNotifications } from '../../redux/notification/actions';

import './styles.scss';

const NotificationTools = () => {
  const [isOpenNotificationPreferences, setIsOpenNotificationPreferences] = useState(false);

  const user = useSelector(getUser);
  const notifications = useSelector(getNotifications);

  if (!user) return null;

  return (
    <div id="notification-tools" className="d-flex flex-row">
      <IconButton
        id="notification-tools-icon"
        icon={
          notifications.filter(notification => !notification.archived).length > 0
            ? 'unreadNotificationDarkOutline'
            : 'notificationDarkOutline'
        }
        onPress={() => setIsOpenNotificationPreferences(true)}
        tooltip="Notifications"
      />

      <OffcanvasDrawer
        isOpen={isOpenNotificationPreferences}
        handleClose={() => setIsOpenNotificationPreferences(false)}
        title="Notifications"
        body={
          <Tabs
            pages={{
              tabs: [
                {
                  name: 'ALL',
                  content: <NotificationList onUpdate={() => setIsOpenNotificationPreferences(false)} />
                },
                {
                  name: 'SETTINGS',
                  content: (
                    <div className="settings-tab d-flex flex-column flex-fill">
                      <h3>Configure your notifications</h3>
                      <p>I want to receive notification of...</p>
                      <NotificationSettings onUpdate={() => setIsOpenNotificationPreferences(false)} />
                    </div>
                  )
                }
              ]
            }}
          />
        }
      />
    </div>
  );
};

export default NotificationTools;
