import React from 'react';
import SiteGroupInfo from './siteGroupInfo';
import Sites from './sites';
import Preferences from '../../projects/sections/preferences';
// import Users from '../../projects/sections/users';
import utils from '../../../utils';

//DetailsDrawer -> Header/Body

const editSections = (
  siteGroup, //newSiteGroup if Create Mode
  updateSiteGroup, //setNewSiteGroup if Create Mode
  editMode,
  setEditMode
  // counters,
  // updateCounters,
) => {
  return [
    {
      header: 'Settings',
      body: (
        <SiteGroupInfo
          siteGroup={siteGroup}
          editMode={editMode}
          setEditMode={setEditMode}
          createMode={false}
          updateSiteGroup={updateSiteGroup}
        />
      ),
      required: true,
      validation: () => {
        return siteGroup ? !utils.hasErrorsCheck(siteGroup.errors) && siteGroup.displayName : false;
      }
    },
    {
      header: `Sites`,
      body: <Sites siteGroup={siteGroup} createMode={false} />,
      contentClasses: 'add-sites'
    },
    // TODO: Enable this section when the permission endpoint in new API is ready
    // {
    //   /* Use AIM API to get info for this section. getPolicy()? */
    //   header: `Permissions (${counters.userCounter})`,
    //   body: <Users project={siteGroup} updateCounters={updateCounters} />,
    //   contentClasses: 'add-users'
    // },
    {
      header: 'Preferences',
      body: (
        <Preferences
          selectedProject={siteGroup}
          editMode={editMode}
          setEditMode={setEditMode}
          updateProject={updateSiteGroup}
        />
      )
    }
  ];
};

export default editSections;
