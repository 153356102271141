import config from '../../config';
import API from '../api';

const { baseUrl } = config.coreApi;

//#region Types
/**
 * @typedef SiteType
 * @type {Object}
 * @property {string} displayName - Display name of the site type.
 * @property {string} id - ID of the site type.
 * @property {number} radius - Radius of the site type.
 */

/**
 * @typedef Site
 * @type {Object}
 * @property {string} address - Address of the site.
 * @property {string[]} attachedDevices - Array of attached device identifiers.
 * @property {string} countryCode - Country code of the site.
 * @property {string} createTime - Creation time of the site.
 * @property {("DATASOURCE_UNSPECIFIED" | "TRAFFIC_M" | "AUDIENCE_M" | "WIFI_M" | "MOBILE_M")[]} datasources - Available data sources for the site.
 * @property {string} deleteTime - Deletion time of the site.
 * @property {string} description - Description of the site.
 * @property {string} displayName - Display name of the site.
 * @property {string} id - ID of the site.
 * @property {number} latitude - Latitude coordinate of the site.
 * @property {number} longitude - Longitude coordinate of the site.
 * @property {string} name - Name of the site.
 * @property {string} projectId - Project ID associated with the site.
 * @property {("SITE_SOLUTION_UNSPECIFIED" | "TRAFFIC" | "AUDIENCE" | "CUSTOM")} solution - Solution type of the site.
 * @property {("SITE_STATUS_UNSPECIFIED" | "ENABLED" | "DISABLED" | "NOT_CONFIGURED")} status - Status of the site.
 * @property {SiteType} type - Type details of the site.
 * @property {string} updateTime - Update time of the site.
 */
//#endregion

//#region CREATE
/**
 * Creates a new site.
 * @param {string} cid - Company ID.
 * @param {Site} site - Site to be created.
 * @returns {Site} Created site.
 */
const createSite = async (cid, site) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${site.projectId}/sites`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: JSON.stringify(site)
  }).then(res => res.json());
};
//#endregion

//#region READ
/**
 * Lists existing sites.
 * @param {string} cid - Company ID.
 * @param {string} pid - Site group ID.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @param {string} orderBy - Order expression.
 * @returns {{sites: Site[], nextPageToken: string, totalSize: number}} List of sites.
 */
const getSites = async (cid, pid, page = '', filter = '', orderBy = 'displayName, id', pageSize = 40) => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/companies/${cid}/projects/${pid}/sites?pageSize=${pageSize}&pageToken=${page}&filter=${filter}&orderBy=${orderBy}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Gets a single site.
 * @param {string} cid - Company ID.
 * @param {string} pid - Site group ID.
 * @param {string} sid - Site ID.
 * @returns {Site} Site.
 */
const getSite = async (cid, pid, sid) => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/companies/${cid}/projects/${pid}/sites/${sid}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Lists existing site types.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @param {string} orderBy - Order expression.
 * @returns {{siteTypes: SiteType[], nextPageToken: string}} List of site types.
 */
const getSiteTypes = async (page = '', filter = '', orderBy = 'displayName, id') => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/sitetypes?pageSize=40&pageToken=${page}&filter=${filter}&orderBy=${orderBy}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

//#endregion

//#region UPDATE
/**
 * Updates a site.
 * @param {string} cid - Company ID.
 * @param {Site} site - Site to be updated.
 * @param {string[]} fields - Site fields being updated. Comma separated.
 * @returns {Site} Site updated.
 */
const updateSite = async (cid, site, fields) => {
  await API.refreshTokenWhenNeeded();
  const paths = `updateMask=${fields.join(',')}`;
  const body = {};
  fields.forEach(field => {
    body[field] = site[field];
  });
  const url = `${baseUrl}/companies/${cid}/projects/${site.projectId}/sites/${site.id}?${paths},type.id`;

  return fetch(url, {
    method: 'PATCH',
    headers: API.headers,
    body: JSON.stringify(body)
  }).then(res => res.json());
};

/**
 * Sets the list of attached devices for a site.
 * @param {string} cid - Company ID.
 * @param {Site} site - Site to be updated.
 * @returns {{attachedDevicesObject: {deviceNames: string[], name: string}}} Attached devices object.
 */
const updateAttachedDevices = async (cid, site) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${site.projectId}/sites/${site.id}:setAttachedDevices`;
  return fetch(url, {
    method: 'PATCH',
    headers: API.headers,
    body: JSON.stringify({ deviceNames: site.attachedDevices.map(id => `devices/${id}`) })
  }).then(res => res.json());
};
//#endregion

//#region DELETE
/**
 * Deletes a single site.
 * @param {string} cid - Company ID.
 * @param {string} pid - Site group ID.
 * @param {string} sid - Site ID.
 * @returns {{}}
 */
const deleteSite = async (cid, pid, sid) => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/companies/${cid}/projects/${pid}/sites/${sid}`;
  return fetch(url, {
    method: 'DELETE',
    headers: API.headers
  }).then(res => res.json());
};
//#endregion

export { createSite, getSites, getSite, getSiteTypes, updateSite, updateAttachedDevices, deleteSite };
