import { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { clearProject } from '../../redux/projects/actions';
import { clearUser } from '../../redux/user/actions';
import API from '../../services/api';

class Logout extends Component {
  componentDidMount() {
    this.props.clearProject({});
    this.props.clearUser();
    API.logout();
    this.props.history.replace('/');
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearProject: project => dispatch(clearProject(project)),
    clearUser: () => dispatch(clearUser())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Logout));
