import { put, select } from 'redux-saga/effects';
import API from '../services/api';
import types from '../redux/types';
import utils from '../utils';

export function* fetchNotificationTypes() {
  try {
    const user = yield select(state => state.userReducer.user);
    if (user) {
      const notificationTypeResponse = yield API.getNotificationTypes();
      yield put({
        type: types.LOAD_NOTIFICATION_TYPES,
        payload: notificationTypeResponse.types
      });
    }
  } catch (err) {
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateErrorAlert("Couldn't fetch Notification Types. Try to refresh this page.")
    });
  }
}

export function* fetchNotificationPreferences() {
  try {
    const user = yield select(state => state.userReducer.user);
    if (user) {
      const notificationPreferencesResponse = yield API.getNotificationPreferences();
      yield put({
        type: types.LOAD_NOTIFICATION_PREFERENCES,
        payload: notificationPreferencesResponse.preferences
      });
    }
  } catch (err) {
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateErrorAlert("Couldn't fetch Notification Types. Try to refresh this page.")
    });
  }
}

export function* fetchNotifications() {
  const user = yield select(state => state.userReducer.user);
  if (user) {
    const notificationResponse = yield API.getNotifications();
    yield put({
      type: types.LOAD_NOTIFICATIONS,
      payload: notificationResponse.notifications.map(notification => {
        if (notification.type && notification.type.id) {
          const { id } = notification.type;
          const [service, status] = id.split('_');
          notification.status = status;
          notification.service = service.toLowerCase();
        }
        return notification;
      })
    });
  }
}
