import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux
import { getSelectedProject, getSelectedSolution } from '../../redux/projects/actions';
import { setAlert } from '../../redux/dashboard/actions';
import { getUser } from '../../redux/user/actions';
import { getSelectedCompany } from '../../redux/company/actions';
import { getSelectedAnalyticsDashboard } from '../../redux/templates/actions';

// Components
import components from '../../components';
import HeaderPDF from '../../components/header/HeaderPDF';
import GenerateReportButton from './generateReportButton';
import CustomWidgets from './customWidgets/customWidgets';
import WidgetGrid from './widgetGrid';
import SidePanel from '../../components/sidepanel';
import Loading from '../../components/loading';

// Utilities
import utils from '../../utils';
import reportUtils from '../../utils/report';
import { getFilteredWidgets } from '../../utils/widgets';
import calendar from '../../utils/calendar';
import API from '../../services/api';

import './styles.scss';

const { DateTimeFilterBar, AnalyticsSelector } = components;

const AnalyticsContainer = ({ lastUpdated }) => {
  // Sites
  const [sites, setSites] = useState([]); //All sites

  //Devices
  const [devices, setDevices] = useState([]);
  const [heatmapDevices, setHeatmapDevices] = useState([]);
  const [wifiDevices, setWifiDevices] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(null);

  // Stop using local widget definition
  const [currentWidgets, setCurrentWidgets] = useState({}); // Current list of widgets, get from dashboard template

  const [filteredWidgets, setFilteredWidgets] = useState([]); // Merged array from Selected widgets and default list

  // Flags
  const [hasTable, setHasTable] = useState(true);
  const [loadingDashboard, setLoadingDashboard] = useState(true);
  const [collapseSidebar, setCollapseSidebar] = useState(true);
  const [hasIntegration, setHasIntegration] = useState(false);

  // Redux variables
  const user = useSelector(getUser);
  const project = useSelector(getSelectedProject);
  const company = useSelector(getSelectedCompany);
  // TODO: Find a way to stop using solution here
  const selectedSolution = useSelector(getSelectedSolution);
  const selectedDashboard = useSelector(getSelectedAnalyticsDashboard);

  const dispatch = useDispatch();

  const eventHandlers = useRef([]);
  const oldProjectRef = useRef();
  const oldDashboardRef = useRef();

  // Update applied filters when project changes
  useEffect(() => {
    const fetchSites = async () => {
      setDevices([]);
      setSites([]);
      await getQueryFilter();
    };

    setLoadingDashboard(true);
    if (project && user) {
      // TODO: test device filter with dashboard instead of solution
      const startDate = !oldProjectRef.current ? calendar.getDefaultDate('start') : appliedFilters.startDate;
      const endDate = !oldProjectRef.current ? calendar.getDefaultDate('end') : appliedFilters.endDate;

      getHeatmapDevices();
      getWifiDevices();

      // Load Filters
      setAppliedFilters({
        startDate,
        endDate,
        timezone: project.preferences.timezone,
        interval: calendar.getIntervalById('day'),
        filterByTime: {},
        filterByWeekday: [],
        selectedDevices: devices,
        wifiDevices,
        heatmapDevices,
        selectedSites: sites,
        selectedMedias: [],
        selectedAdvertisers: [],
        user, // TODO: Remove this dependency
        project, // TODO: Remove this dependency
        projectId: project.id, // TODO: Remove this dependency
        selectedSolution: selectedSolution, // TODO: Remove this dependency
        // mobile data extra params
        sites: '6822072',
        startTime: startDate, // Naming convention is different on bigQuery
        endTime: endDate // Naming convention is different on bigQuery
      });

      if (project.id !== oldProjectRef.current) {
        fetchSites();
      }

      setLoadingDashboard(false);

      oldProjectRef.current = project.id;
    }
  }, [project]);

  // Update widgets after fetching them all
  useEffect(() => {
    const widgetCount = getWidgetCount();
    if (widgetCount > 0 && Object.keys(currentWidgets).length === widgetCount) {
      loadWidgets();
    }
  }, [currentWidgets]);

  // Update widgets when applied filters changes
  useEffect(() => {
    if (appliedFilters) {
      loadWidgets();
    }
    API.refreshTokenWhenNeeded();
  }, [appliedFilters]);

  // Update widgets when applied filters changes
  useEffect(() => {
    const fetchSites = async () => {
      if (selectedDashboard && selectedDashboard.id !== oldDashboardRef.current) {
        setHasIntegration(selectedDashboard?.id?.toLowerCase().includes('cms'));
        setDevices([]);
        setSites([]);
        await getQueryFilter();
        oldDashboardRef.current = selectedDashboard.id;
      }
    };

    const fetchWidgets = async () => {
      if (selectedDashboard && selectedDashboard.id !== oldDashboardRef.current) {
        setLoadingDashboard(true);
        // Load Widgets
        let newDashboard = {};

        const widgetsToFetch = [];
        selectedDashboard.rows.forEach(dashboardRow => {
          dashboardRow.widgets.forEach(widgetData => {
            widgetsToFetch.push(widgetData.url);
          });
        });

        const fetchedWidgets = await Promise.all(
          widgetsToFetch.map(widgetUrl => {
            return API.getWidget(widgetUrl);
          })
        );

        fetchedWidgets.forEach(fetchedWidget => {
          newDashboard = { ...newDashboard, [fetchedWidget.id]: fetchedWidget };
        });

        setCurrentWidgets(newDashboard);
        fetchSites();
        setLoadingDashboard(false);

        oldDashboardRef.current = selectedDashboard.id;
      }
    };

    fetchWidgets();

    API.refreshTokenWhenNeeded();
  }, [selectedDashboard]);

  useEffect(() => {
    return () => {
      eventHandlers.current.forEach(handler => {
        window.removeEventListener('requestResult', handler);
        window.removeEventListener('requestError', handler);
      });
    };
  }, []);

  useEffect(() => {
    setLoadingDashboard(true);
    if (!sites || sites.length === 0) return;

    loadDevices();
    setLoadingDashboard(false);
  }, [sites]);

  const getQueryFilter = async () => {
    if (selectedDashboard) {
      const filter = encodeURIComponent(`'{"${selectedDashboard.compatibleDatasources.join('","')}"}' && datasources`);
      await loadAllSites(filter);
    }
  };

  const getWidgetsInPlace = () => {
    if (!selectedDashboard || !selectedDashboard.rows || Object.keys(currentWidgets).length === 0) {
      return [];
    }

    return selectedDashboard.rows.map(widgetRow => {
      return widgetRow.widgets.map(widget => currentWidgets[widget.id]);
    });
  };

  const getSortedWidgets = () => {
    if (!selectedDashboard || !selectedDashboard.rows || Object.keys(currentWidgets).length === 0) {
      return {};
    }

    const sortedWidgets = [];

    selectedDashboard.rows.forEach(widgetRow => {
      widgetRow.widgets.forEach(widget => {
        sortedWidgets.push(currentWidgets[widget.id] || { id: widget.id });
      });
    });

    return sortedWidgets;
  };

  const getWidgetCount = () => {
    if (!selectedDashboard || !selectedDashboard.rows) return 0;

    return selectedDashboard.rows.map(row => row.widgets.length).reduce((sum, prev) => sum + prev);
  };

  const loadWidgets = () => {
    // Load Widgets
    const customWidgets =
      user.preferences.customWidgets && selectedDashboard ? user.preferences.customWidgets[selectedDashboard.id] : null;
    const newFilteredWidgets = getFilteredWidgets(getWidgetsInPlace(), customWidgets);

    setFilteredWidgets(newFilteredWidgets);
  };

  const loadSites = async (filter = '', pageToken = '') => {
    try {
      const data = await API.getSites(company.id, project.id, pageToken, filter, 'displayName, id', 200);

      const sites = data.sites;
      const nextPageToken = data.nextPageToken;

      if (nextPageToken !== '') {
        const nextPageSites = await loadSites(filter, nextPageToken);
        return [...sites, ...nextPageSites];
      }

      return sites;
    } catch (error) {
      console.error('Error loding sites:', error);
    }
  };

  const loadAllSites = async filter => {
    setLoadingDashboard(true);

    if (!company || !project || !filter) return;

    const sites = await loadSites(filter);
    setSites(sites);
    setLoadingDashboard(false);
  };

  const loadDevices = () => {
    const localDevices = [];
    sites.forEach(site => site.attachedDevices.forEach(device => localDevices.push(device)));
    const devicesSet = new Set([...devices, ...localDevices]);
    const deviceList = Array.from(devicesSet);

    setDevices(deviceList);

    setAppliedFilters({
      ...appliedFilters,
      selectedDevices: deviceList,
      selectedSites: sites,
      selectedSolution
    });
  };

  const getHeatmapDevices = () => {
    const filter = encodeURIComponent(`project_id = '${project.id}' AND heatmap_enabled`);
    API.getDevices(company.id, '', filter, 200)
      .then(res => setHeatmapDevices(res.devices))
      .catch(error => console.error(error));
  };

  const getWifiDevices = () => {
    const filter = encodeURIComponent(`project_id = '${project.id}' AND hasWifi`);
    API.getDevices(company.id, '', filter, 200)
      .then(res => setWifiDevices(res.devices))
      .catch(error => console.error(error));
  };

  const onFilterSelect = payload => {
    const newAppliedFilters = utils.deepClone(appliedFilters);
    Object.keys(payload).forEach(key => {
      newAppliedFilters[key] = payload[key];
      // Update mobile data params
      if (key === 'startDate') {
        newAppliedFilters['startTime'] = payload[key];
      }
      if (key === 'endDate') {
        newAppliedFilters['endTime'] = payload[key];
      }
    });

    setAppliedFilters(newAppliedFilters);
  };

  const onCustomWidgetsChange = customWidgets => {
    const newFilteredWidgets = getFilteredWidgets(getWidgetsInPlace(), customWidgets);
    setFilteredWidgets(newFilteredWidgets);
  };

  const onError = e => {
    console.error(e);
    const error = e.detail ? e.detail.message : 'No details';
    let errorMessage = error;
    if (error && error.includes('Not found: Table')) {
      setHasTable(false);
      errorMessage = 'Table not found';
    }

    dispatch(setAlert(utils.generateAlert(errorMessage ? errorMessage : 'Something went wrong.', 'error')));
  };

  const renderEmptyMessage = () => {
    return (
      <div className="no-data-message flex-column">
        <h1>No data found!</h1>
        <p>Nothing to see here. Try choosing another site group or creating a new one.</p>
      </div>
    );
  };

  const renderTopFilterBar = () => {
    if (!appliedFilters) {
      return null;
    }

    return (
      <div className="filter-bar">
        <DateTimeFilterBar appliedFilters={appliedFilters} onPeriodSelected={onFilterSelect} />
        <div className="d-flex">
          <GenerateReportButton project={project} report={reportUtils.getCustomReportTemplate(appliedFilters)} />
          <CustomWidgets
            defaultWidgets={getSortedWidgets()}
            selectedDashboard={selectedDashboard}
            onChange={onCustomWidgetsChange}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (!selectedDashboard) {
      return null;
    }

    if (devices.length === 0 || !hasTable) {
      return renderEmptyMessage();
    }

    return (
      <div className="d-flex">
        <SidePanel
          isOpen={false}
          content={
            <AnalyticsSelector
              sites={sites}
              selectedDashboard={selectedDashboard}
              devices={devices}
              appliedFilters={appliedFilters}
              onSelection={onFilterSelect}
              cmsIntegration={hasIntegration && project.currentCMS ? project.currentCMS : null}
            />
          }
          onToggle={() => setCollapseSidebar(!collapseSidebar)}
        />
        <div className="scrollable-container">
          <div id="analytics-chart-container" className={`print ${!collapseSidebar ? 'expanded-sidebar' : ''}`}>
            <WidgetGrid
              appliedFilters={appliedFilters}
              filteredWidgets={filteredWidgets}
              handleError={onError}
              eventHandlers={eventHandlers.current}
              lastUpdated={lastUpdated}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="parent-container analytics-container">
      {/* We are downloading the image so when the user generates the PDF the image already exists for the browser */}
      <div className="company-logo-img visibility-hidden" />
      <HeaderPDF filters={appliedFilters} devices={devices} />
      <div id="analytics-widget-container" className="d-flex flex-column">
        {renderTopFilterBar()}
        {!loadingDashboard || !project ? (
          renderContent()
        ) : (
          <div className="h-100">
            <Loading size={8} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AnalyticsContainer;
