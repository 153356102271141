import React from 'react';
import SiteGroupInfo from './siteGroupInfo';
import Preferences from './preferences';
import Integrations from './integrations/index';
import utils from '../../../utils';
// import NewUsers from './addUsers/newUsers';

//Wizard -> Title/Content
const createSections = (
  siteGroup, //newSiteGroup if Create Mode
  updateSiteGroup, //setNewSiteGroup if Create Mode
  addPreferences
) => {
  return [
    {
      title: 'Settings',
      content: (
        <SiteGroupInfo siteGroup={siteGroup} editMode={true} createMode={true} updateSiteGroup={updateSiteGroup} />
      ),
      required: true,
      validation: () => {
        return siteGroup ? !utils.hasErrorsCheck(siteGroup.errors) && siteGroup.displayName : false;
      }
    },
    // {
    // TODO: Enable this section when the permission endpoint in new API is ready
    //   title: `Invite Users`,
    //   content: <NewUsers siteGroup={siteGroup} updateSiteGroup={updateSiteGroup} />,
    //   contentClasses: 'add-users',
    //   modified: () => {
    //     return siteGroup && siteGroup.users && siteGroup.users.length > 0;
    //   }
    // },
    {
      title: 'Integration',
      content: <Integrations siteGroup={siteGroup} updateSiteGroup={updateSiteGroup} />,
      contentClasses: 'add-integrations',
      modified: () => {
        return siteGroup && siteGroup.credentials && siteGroup.credentials.length > 0;
      }
    },
    {
      title: 'Preferences',
      content: (
        <Preferences
          selectedProject={siteGroup}
          editMode={true}
          updateProject={updateSiteGroup}
          hasButtons={false}
          isCreateMode={true}
          onChangePreferences={addPreferences}
        />
      )
    }
  ];
};

export default createSections;
