import { call, put, select } from 'redux-saga/effects';
import types from '../redux/types';
import API from '../services/api';
import { clearProjectsData, normalizeProject } from '../utils/project';
import { discoverUserPermission } from '../utils/user/permissions';
import utils from '../utils';
import deviceUtils from '../utils/device';

// Fetch Presets aka Solutions Config from API
export function* fetchSolutionsConfig(projects) {
  const allSolutions = [];
  let solutionsConfig = {};

  for (const project of projects) {
    for (const solution of project.enabledSolutions) {
      if (!allSolutions.includes(solution)) {
        allSolutions.push(solution);
      }
    }
  }

  const solutionsConfigsResponse = yield Promise.all(
    allSolutions.map(solutionType => API.getSolutionsConfig(solutionType))
  );

  solutionsConfigsResponse.forEach((solutionConfigResponse, index) => {
    solutionsConfig[allSolutions[index]] = solutionConfigResponse.solutionConfigs;
  });

  return solutionsConfig;
}

// Return default Project from user preferences or first project found
export function* getDefaultProject(projects) {
  if (!projects || projects.length === 0) {
    return null;
  }
  // Get user preference to load default project
  const user = yield select(state => state.userReducer.user);
  const company = yield select(state => state.companyReducer.selectedCompany);
  let selectedProject = projects[0];

  // Updated selected device based on user preferences
  if (user.preferences && user.preferences.defaultProjectId) {
    // Get default project from API
    let defaultProject = null;
    try {
      defaultProject = yield call(API.getSiteGroup, company.id, user.preferences.defaultProjectId);
      selectedProject = defaultProject ? defaultProject : selectedProject;
    } catch (e) {
      console.error('Could not fetch default project', e);
    }
  }

  return normalizeProject(selectedProject);
}

export function* fetchProjects() {
  // Fetch project info from API
  const company = yield select(state => state.companyReducer.selectedCompany);
  let { projects } = company
    ? yield call(API.getSiteGroupsByCompany, company.id, 200)
    : yield call(API.getSiteGroups, 200);
  // Search through projects the one with more devices to select
  const normalizedProjects = projects.map(project => {
    return normalizeProject(project);
  });

  return normalizedProjects;
}
export function* loadProjects() {
  try {
    const projects = yield call(fetchProjects);
    const selectedProject = yield call(getDefaultProject, projects);
    // Get solution Presets from API
    const solutionsConfig = yield call(fetchSolutionsConfig, projects);

    // Set Projects on Redux
    yield put({ type: types.LOAD_PROJECTS, payload: projects });
    // Set Selected Project on Redux
    yield put({ type: types.SELECT_PROJECT, payload: selectedProject });
    // Set Presets on Redux
    yield put({ type: types.LOAD_SOLUTIONS_CONFIG, payload: solutionsConfig });
  } catch (e) {
    console.error(e);
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateErrorAlert("Couldn't fetch Projects. Try to refresh this page.")
    });
  }
}

export function* updateUserDefaultProject({ payload: project }) {
  if (project) {
    try {
      const user = yield select(state => state.userReducer.user);
      const userPermissions = yield call(discoverUserPermission, user, project);
      let updatedUser = { ...user, permissions: userPermissions };
      // Don't allow impersonator user to change default project of other user
      if (!user.impersonator) {
        const userPreferences = { ...user.preferences, defaultProjectId: project.id };
        updatedUser = { ...updatedUser, preferences: userPreferences };
        yield call(API.updateUser, user.id, { preferences: userPreferences });
      }
      yield put({ type: types.UPDATE_USER, payload: updatedUser });
    } catch (e) {
      console.error(e);
      // Clear project data from localStorage to prevent errors when changing env (dev/prod)
      clearProjectsData();
    }
  }
}

export function* fetchDevices() {
  const project = yield select(state => state.projectReducer.selectedProject);

  // Stop FX chain if the user doesn't have projects
  if (!project) {
    // yield call(finishLoading);
    yield put({ type: types.LOAD_SOLUTIONS, payload: [] });
    yield put({ type: types.SELECT_SOLUTION, payload: null });
    yield put({ type: types.LOAD_DEVICES, payload: [] });
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateErrorAlert(
        "You don't have any sites or site groups. Please contact support@admobilize.com",
        -1
      )
    });
    return;
  }

  try {
    const devices = yield call(API.getDevices, project.companyId);

    // Get all possible Solutions/Products by reading all devices
    const { enabledIntegrations, enabledSolutions } = project;

    const sortedSolutions = utils.sortSolutions([
      ...enabledIntegrations.map(integration => integration.toLowerCase()),
      ...enabledSolutions.map(solution => solution.toLowerCase())
    ]);

    let selectedSolution = sortedSolutions.length ? sortedSolutions[0] : null;
    const user = yield select(state => state.userReducer.user);

    if (user.preferences && user.preferences.defaultSolutionId) {
      const solutionFound = sortedSolutions.find(solution => solution.id === user.preferences.defaultSolutionId);
      if (solutionFound) {
        selectedSolution = solutionFound;
      }
    }

    // Remove last param to enable not-malos devices
    let deviceArray = yield call(deviceUtils.addInformation, devices);
    deviceArray = utils.sort(deviceArray, { field: 'displayName', type: 'string', isDescending: false });

    // Wait until devices value is ready and update value on App state
    yield put({ type: types.LOAD_DEVICES, payload: deviceArray });

    yield put({ type: types.LOAD_SOLUTIONS, payload: sortedSolutions });
    yield put({ type: types.SELECT_SOLUTION, payload: selectedSolution });
  } catch (e) {
    console.log(e);
    yield put({
      type: types.SET_ALERT,
      payload: utils.generateErrorAlert("Couldn't fetch devices. Try to refresh this page.")
    });
  }
}
