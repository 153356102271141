import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import CustomInput from '../customInput';
import utils from '../../utils';
import API from '../../services/api';
import { setLoading } from '../../redux/dashboard/actions';
import { setUser } from '../../redux/user/actions';

const ChangePassword = ({ email, onChange }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [showingCurrentPassword, setShowingCurrentPassword] = useState(false);
  const [showingNewPassword, setShowingNewPassword] = useState(false);
  const [showingNewPassword2, setShowingNewPassword2] = useState(false);
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const [errors, setErrors] = useState({ currentPassword: [], newPassword: [], newPassword2: [] });

  const dispatch = useDispatch();

  const validateForm = useCallback(() => {
    const validations = {
      currentPassword: [
        { key: 'required', value: true },
        { key: 'min', value: 8 }
      ],
      newPassword: [
        { key: 'required', value: true },
        { key: 'min', value: 8 },
        {
          key: 'different',
          value: currentPassword,
          customMessage: 'New Password should be different from Current Password'
        }
      ],
      newPassword2: [
        { key: 'required', value: true },
        { key: 'min', value: 8 },
        { key: 'equals', value: newPassword, customMessage: 'Value is different from New Password.' }
      ]
    };

    let hasError = false;
    let newErrors = {
      currentPassword: utils.fieldValidation(currentPassword, validations['currentPassword']),
      newPassword: utils.fieldValidation(newPassword, validations['newPassword']),
      newPassword2: utils.fieldValidation(newPassword2, validations['newPassword2'])
    };

    setErrors(newErrors);

    Object.keys(newErrors).forEach(key => {
      if (newErrors[key].length > 0) {
        hasError = true;
      }
    });

    return hasError;
  }, [currentPassword, newPassword2, newPassword]);

  useEffect(() => {
    if (!wasSubmitted) {
      return;
    }
    validateForm();
  }, [wasSubmitted, validateForm]);

  const resetForm = () => {
    setCurrentPassword('');
    setNewPassword('');
    setNewPassword2('');
    setWasSubmitted(false);
    setIsLoading(false);
    setErrors({ currentPassword: [], newPassword: [], newPassword2: [] });
  };

  // when the user reset password the firebase as a security measure revoke all old tokens and the user
  // need get a new token
  // https://firebase.google.com/docs/auth/admin/manage-sessions#revoke_refresh_tokens
  const getNewToken = () => {
    API.signin(email, newPassword)
      .then(userInfo => {
        //When user is returned, userInfo.permission is undefined
        userInfo.permissions = {};
        dispatch(setUser(userInfo));
        dispatch(setLoading(true));
        resetForm();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const resetPassword = () => {
    setWasSubmitted(true);
    if (!validateForm()) {
      setIsLoading(true);
      onChange(
        {
          currentPassword: currentPassword,
          password: newPassword
        },
        err => {
          if (err) {
            setIsLoading(false);
          } else {
            getNewToken();
          }
        }
      );
    }
  };

  return (
    <div className="section change-password">
      <div className="section-title">Change Password</div>
      <div className="section-content">
        <div className="row">
          <div className="col-7">
            <form className="form-container">
              <div className={`form-group ${errors['currentPassword'].length > 0 && 'is-invalid'}`}>
                <label>Current Password</label>
                <CustomInput
                  id="password-current"
                  isEditable={true}
                  errorMessage={errors['currentPassword'].join(', ')}
                  onChange={e => setCurrentPassword(e.target.value)}
                  autoComplete="current-password"
                  isPasswordToolEnabled
                  showingPassword={showingCurrentPassword}
                  showPasswordClicked={() => setShowingCurrentPassword(!showingCurrentPassword)}
                  type={showingCurrentPassword ? 'text' : 'password'}
                  value={currentPassword}
                />
              </div>
              <div className={`form-group ${errors['newPassword'].length > 0 && 'is-invalid'}`}>
                <label>New password</label>
                <CustomInput
                  id="new-password"
                  isEditable={true}
                  onChange={e => setNewPassword(e.target.value)}
                  autoComplete="new-password"
                  type={showingNewPassword ? 'text' : 'password'}
                  isPasswordToolEnabled
                  showingPassword={showingNewPassword}
                  showPasswordClicked={() => setShowingNewPassword(!showingNewPassword)}
                  errorMessage={errors['newPassword'].join(', ')}
                  value={newPassword}
                />
              </div>
              <div className={`form-group ${errors['newPassword2'].length > 0 && 'is-invalid'}`}>
                <label>Repeat the new password</label>
                <CustomInput
                  id="repeat-new-password"
                  isEditable={true}
                  onChange={e => setNewPassword2(e.target.value)}
                  autoComplete="repeat-password"
                  errorMessage={errors['newPassword2'].join(', ')}
                  type={showingNewPassword2 ? 'text' : 'password'}
                  isPasswordToolEnabled
                  showingPassword={showingNewPassword2}
                  showPasswordClicked={() => setShowingNewPassword2(!showingNewPassword2)}
                  value={newPassword2}
                />
              </div>
            </form>
          </div>
          <div className="col-5">
            <div className="buttons-container">
              <button className="btn btn-primary btn-sm" disabled={isLoading} onClick={resetPassword}>
                Change
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
