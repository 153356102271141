import React, { useState, useEffect, useCallback } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectedProject } from '../../redux/projects/actions';
import { getUser } from '../../redux/user/actions';
import API from '../../services/api';
import utils from '../../utils';
import types from '../../redux/types';
import Loading from '../loading';
import CustomConfirm from '../customConfirm';

import './styles.scss';
import CustomButton from '../customButton';

const PreviewModal = ({ className, device, setIsCommandRunning, showPreviewModal, onClose }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState('');
  const [openGdprDialog, setOpenGdprDialog] = useState(false);
  const [loadingSaveHeatmap, setLoadingSaveHeatmap] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      onClose();
    }
    setModal(!modal);
  }, [modal]);

  const project = useSelector(getSelectedProject);
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (device && modal && project) {
      setLoading(true);
      if (setIsCommandRunning) setIsCommandRunning(true);

      API.sendCommand(device.companyId, device.id, 'preview')
        .then(res => {
          setImagePreview(utils.get(res, 'url'));
        })
        .catch(error => {
          dispatch({
            type: types.SET_ALERT,
            payload: utils.generateAlert(error.message, 'error')
          });
          setModal(false);
          onClose();
        })
        .finally(() => {
          if (setIsCommandRunning) setIsCommandRunning(false);
          setLoading(false);
        });
    }
  }, [device, project, modal, dispatch, toggle, setIsCommandRunning]);

  useEffect(() => {
    setOpenGdprDialog(showPreviewModal);
  }, [showPreviewModal]);

  const cancelPreview = () => {
    setOpenGdprDialog(false);
    onClose();
  };

  const confirmPreview = () => {
    setOpenGdprDialog(false);
    toggle();
  };

  const canRenderHeatmapButton = () => {
    return imagePreview && user && (user.permissions['support'] || user.impersonator);
  };

  const setHeatmapImage = () => {
    setLoadingSaveHeatmap(true);
    API.setHeatmapImage(device.companyId, device.id, imagePreview)
      .then(() => {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert('Your preview has been saved as heatmap BG.', 'success')
        });
      })
      .catch(err => {
        dispatch({
          type: types.SET_ALERT,
          payload: utils.generateAlert('Something went wrong. Preview could not be saved!', 'error')
        });
        console.error(err);
      })
      .finally(() => setLoadingSaveHeatmap(false));
  };

  if (!device) return null;

  return (
    <>
      <Modal className={className} isOpen={modal} fade={false} toggle={toggle} centered>
        <ModalHeader className="header-gray" toggle={toggle}>
          Device Image Preview:
          <div className="preview-subtitle">
            {device.displayName} ({device.id})
          </div>
        </ModalHeader>
        <ModalBody>
          {loading ? <Loading size={10} /> : <img src={imagePreview} alt="Device Preview" width="100%" />}
          {canRenderHeatmapButton() && (
            <CustomButton
              loading={loadingSaveHeatmap}
              classes="btn btn-primary heatmap-button"
              title={device.heatmapImageUrl ? 'Update Heatmap Image' : 'Set Heatmap Image'}
              handleClick={setHeatmapImage}
            />
          )}
        </ModalBody>
      </Modal>
      <CustomConfirm messageType="gdpr" cancelCb={cancelPreview} confirmCb={confirmPreview} isOpen={openGdprDialog} />
    </>
  );
};

export default PreviewModal;
