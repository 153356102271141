import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { setAlert } from '../../redux/dashboard/actions';
import siteSteps from './siteSteps';
import Tabs from '../../components/tabs';
import CustomButton from '../../components/customButton';
import siteUtils from '../../utils/site';
import utils from '../../utils';
import API from '../../services/api';
import CustomConfirm from '../../components/customConfirm';

const ManageSitesModal = ({ site, isOpen, toggle, onCancel, companyId }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [modifiedSite, setModifiedSite] = useState(utils.deepClone(site));
  const [errors, setErrors] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setErrors(siteUtils.getSiteErrorsObject(modifiedSite, ['settings', 'solution', 'location']));
  }, [modifiedSite]);

  const onConfirm = () => {
    const requests = [
      API.updateSite(companyId, { ...modifiedSite, status: 'ENABLED' }, [
        'displayName',
        'type',
        'description',
        'solution',
        'datasources',
        'address',
        'latitude',
        'longitude',
        'status',
        'countryCode'
      ])
    ];

    if (!utils.compareArrays(site.attachedDevices, modifiedSite.attachedDevices)) {
      requests.push(API.updateAttachedDevices(companyId, modifiedSite));
    }
    // 'projectId' should be updated with transferSite endpoint

    setLoadingSubmit(true);
    Promise.all(requests)
      .then(() => {
        dispatch(setAlert(utils.generateAlert('Your site has been updated!', 'success')));
      })
      .catch(err => {
        console.error(err);
        dispatch(setAlert(utils.generateAlert('Your site could not be updated! Something went wrong', 'error')));
      })
      .finally(() => {
        toggle(false);
        setLoadingSubmit(false);
      });
  };

  const getPagesFromSteps = steps => {
    return steps.map(step => {
      const newStep = { ...step, name: step.title };
      newStep.content = (
        <div>
          <h2 className="section-header">
            Editing {newStep.title.toLowerCase()} on {site.displayName}
          </h2>{' '}
          {newStep.content}
        </div>
      );
      delete newStep.title;
      return newStep;
    });
  };

  const getPageWithErrorNotification = () => {
    const steps = siteSteps(modifiedSite, setModifiedSite, companyId);

    return getPagesFromSteps(steps).map(page => {
      page.notify = false;
      if (errors[page.name.toLowerCase()]) {
        page.notify = true;
      }
      return page;
    });
  };

  const canConfirm = () => {
    for (const key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key)) {
        if (errors[key]) {
          return true;
        }
      }
    }
    return false;
  };

  const handleDelete = () => {
    API.deleteSite(site.name.split('/')[1], site.projectId, site.id)
      .then(() => {
        dispatch(setAlert(utils.generateAlert('Site deleted successfully!', 'success')));
      })
      .catch(error => {
        console.error(error);
        dispatch(setAlert(utils.generateAlert('Something went wrong. Try again later!', 'error')));
      })
      .finally(() => {
        setShowDeleteDialog(false);
        toggle(false);
      });
  };

  useEffect(() => {
    //Always start with the first tab opened
    setSelectedTab(0);
  }, [isOpen]);

  return (
    <Modal size="lg" isOpen={isOpen} fade={false} centered id="manage-sites-modal">
      <ModalBody>
        <Tabs
          classes="manage-sites-tabs"
          onTabsChange={setSelectedTab}
          selectedTab={selectedTab}
          pages={{ tabs: getPageWithErrorNotification() }}
        />
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary btn-danger" onClick={() => setShowDeleteDialog(true)}>
          <i className="uil uil-trash" />
          Delete
        </button>
        <CustomButton
          handleClick={onCancel ? onCancel : toggle}
          classes="btn-secondary"
          title="Cancel"
          disabled={false}
        />
        <CustomButton
          handleClick={() => {
            onConfirm();
          }}
          classes="btn-primary"
          title="Confirm changes"
          loading={loadingSubmit}
          disabled={canConfirm()}
        />
        <CustomConfirm
          messageType="deleteSite"
          messageContext={site}
          confirmCb={() => handleDelete()}
          cancelCb={() => setShowDeleteDialog(false)}
          isOpen={showDeleteDialog}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ManageSitesModal;
