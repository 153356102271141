const projectTypes = [
  'LOAD_PROJECTS',
  'CREATE_PROJECT',
  'SELECT_PROJECT',
  'UPDATE_SELECTED_PROJECT',
  'UPDATE_PROJECTS',
  'SET_PROJECT_TABLES',
  'CLEAR_PROJECT'
];

const solutionTypes = [
  'LOAD_SOLUTIONS',
  'SELECT_SOLUTION',
  'UPDATE_SELECT_SOLUTION',
  'LOAD_SOLUTIONS_CONFIG', //presets
  'UPDATE_SOLUTION_CONFIG', //presets
  'CREATE_SOLUTION_CONFIG' //presets
];

export { projectTypes, solutionTypes };
