import config from '../../config';
import API from '../api';
import credentialsStore from '../credentialsStore';

const baseUrl = config.authApi.baseUrlV2;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const signin = async (email, password) => {
  const url = `${baseUrl}/users:login`;
  return fetch(url, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      email,
      password
    })
  }).then(res => validateSigninResponse(res));
};

const refreshSession = async refreshToken => {
  const url = `${baseUrl}/users:refreshToken`;
  return fetch(url, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      refreshToken
    })
  }).then(res => validateSigninResponse(res));
};

const resetPassword = async email => {
  const url = `${baseUrl}/users:sendOobCode`;
  return fetch(url, {
    headers,
    body: JSON.stringify(email),
    method: 'POST'
  }).then(res => res.json());
};

const tokenSignin = async refreshToken => {
  const url = `${baseUrl}/users:refreshToken`;
  return fetch(url, {
    headers,
    method: 'POST',
    body: JSON.stringify({
      refreshToken
    })
  }).then(res => validateSigninResponse(res));
};

const validateSigninResponse = async loginRes => {
  if (!loginRes.ok) {
    let response;

    try {
      response = await loginRes.json();
    } catch (e) {
      throw new Error('Failed to parse login response.');
    }

    if (response.message) {
      throw new Error(response.message);
    }

    throw new Error('Failed to complete login request.');
  }

  const tokens = await loginRes.json();
  const user = await API.getCurrentUser(tokens.accessToken);

  const output = {
    ...user,
    name: user.displayName,
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken
  };

  // Add custom widgets after login
  output.preferences = await API.getUserCustomWidgets(tokens.accessToken);

  API.setTokens(tokens);
  credentialsStore.put(output);

  return output;
};

export { signin, refreshSession, tokenSignin, resetPassword };
