import config from '../../config';
import API from '../api';
// REVIEW: Checking presets endpoints with heitor

const { baseUrl } = config.deviceManagementApi;

const getSolutionsConfig = async solutionType => {
  const url = `${baseUrl}/solutions/${solutionType}/configurations?page_size=-1`;
  return fetch(url, {
    headers: API.headers
  }).then(res => API.generateResponse(res));
};

const getDevicesFromPreset = async () => {
  const url = `${baseUrl}/deviceConfigs?page_size=-1`;
  return fetch(url, {
    headers: API.headers
  }).then(res => API.generateResponse(res));
};

const updateSolutionConfig = async (solutionConfig, fields) => {
  await API.refreshTokenWhenNeeded();
  const fieldsToUpdate = fields.map(field => `update_mask=${field}`).join('&');
  const url = `${baseUrl}/solutions/${solutionConfig.solutionId}/configurations/${solutionConfig.id}/?${fieldsToUpdate}`;
  return fetch(url, {
    method: 'PUT',
    headers: API.headers,
    body: JSON.stringify(solutionConfig)
  }).then(res => res.json());
};

const createSolutionConfig = async solutionConfig => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/solutions/${solutionConfig.solutionId}/configurations`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: JSON.stringify(solutionConfig)
  }).then(res => res.json());
};

export { getSolutionsConfig, getDevicesFromPreset, updateSolutionConfig, createSolutionConfig };
