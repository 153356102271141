import React, { useEffect, useState } from 'react';
import CustomInput from '../../../components/customInput';
import CustomDropdown from '../../../components/customDropdown';
import utils from '../../../utils';

const CreateDeviceSection = ({ setNewDevices }) => {
  const [displayName, setDisplayName] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [solution, setSolution] = useState({ name: 'Select a Solution' });
  const [site, setSite] = useState({ name: 'Select a Site' });
  const [os, setOs] = useState({ name: 'Select an OS' });
  const [errors, setErrors] = useState({});

  const osList = [
    { id: 'balena', name: 'Balena' },
    { id: 'brightsign', name: 'Brightsign' },
    { id: 'linux', name: 'Linux' },
    { id: 'windows', name: 'Windows' }
  ];

  const solutionList = [
    { id: 'audience', name: 'Audience' },
    { id: 'custom', name: 'Custom' },
    { id: 'traffic', name: 'Traffic' }
  ];

  const mockedSites = [
    { id: 'siteA', name: 'Site A' },
    { id: 'siteB', name: 'Site B' },
    { id: 'siteC', name: 'Site C' }
  ];

  useEffect(() => {
    setNewDevices(populateDevicesInfo());
  }, [displayName, quantity, os, solution, site, errors]);

  // Validate fields
  useEffect(() => {
    const validation = {
      'device-display-name': [
        { key: 'required', value: true },
        { key: 'min', value: 4 }
      ]
    };

    const errorList = {
      'device-display-name': utils.fieldValidation(displayName, validation['device-display-name']).join(', ')
    };
    setErrors(errorList);
  }, [displayName]);

  const hasErrors = () => {
    return errors['device-display-name'] === '' && solution.id && os.id && site.id ? false : true;
  };

  const populateDevicesInfo = () => {
    const localDevices = [];
    for (let i = 0; i < quantity; i++) {
      localDevices.push({
        name: i === 0 ? displayName : `${displayName}-${i}`,
        os: os,
        solution: solution,
        site: site,
        hasErrors: hasErrors()
      });
    }
    return localDevices;
  };

  return (
    <div className="create-device">
      <form>
        <div className="row">
          <div className="form-group col">
            <label>Choose Site*</label>
            <CustomDropdown
              items={mockedSites}
              handleSelection={e => {
                setSite(mockedSites.find(site => site.id === e.target.value));
              }}
              selectedItem={site}
              valueField={'id'}
              displayField={'name'}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label>Device Name*</label>
            <CustomInput
              id="create-devices-name"
              type="text"
              value={displayName}
              isEditable={true}
              onChange={e => setDisplayName(e.target.value)}
              autoFocus={true}
              errorMessage={errors['device-display-name']}
            />
          </div>
          <div className="form-group col-6">
            <label>Quantity of Devices*</label>
            <CustomInput
              id="create-devices-quantity"
              type="number"
              value={quantity}
              isEditable={true}
              onChange={e => setQuantity(e.target.value)}
              min={1}
              autoFocus={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label>OS type*</label>
            <CustomDropdown
              items={osList}
              handleSelection={e => {
                setOs(osList.find(os => os.id === e.target.value));
              }}
              selectedItem={os}
              valueField={'id'}
              displayField={'name'}
            />
          </div>
          <div className="form-group col-6">
            <label>Solution*</label>
            <CustomDropdown
              items={solutionList}
              handleSelection={e => {
                setSolution(solutionList.find(solution => solution.id === e.target.value));
              }}
              selectedItem={solution}
              valueField={'id'}
              displayField={'name'}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateDeviceSection;
