import fieldValidation from './fieldValidation';
import { solutionsMeta } from './solutions';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

// Store timezone offset
const LOCAL_STORAGE_MASK = 'showMask';

// Check if date is a valid one
const isValidDate = date => date instanceof Date && !isNaN(date);

const isValidDateString = date => {
  let format = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  return format.test(date);
};

const isNumeric = value => {
  return /^-{0,1}\d+$/.test(value);
};

/**
 * Compare 2 versions
 * return 0 if second version is the same than first version
 * return 1 if second version is greater than first version
 * return -1 if second version is less than first version
 *
 * @param {first version} a
 * @param {second version} b
 */
const compareVersion = (a = '0.0.0', b = '0.0.0') => {
  const firstVersion = a.split('.');
  const secondVersion = b.split('.');

  for (const index in firstVersion) {
    const first =
      isNumeric(firstVersion[index]) && isNumeric(secondVersion[index])
        ? parseInt(firstVersion[index])
        : firstVersion[index];
    const second =
      isNumeric(firstVersion[index]) && isNumeric(secondVersion[index])
        ? parseInt(secondVersion[index])
        : secondVersion[index];
    if (second > first) {
      return -1;
    } else if (second < first) {
      return 1;
    }
  }
  return 0;
};

const compare = (a, b) => {
  if (a > b) return 1;
  else if (a < b) return -1;
  else return 0;
};

const compareNumbers = (a, b) => a - b;

const compareStrings = (a, b) => {
  const itemA = typeof a == 'string' ? a.toUpperCase() : a;
  const itemB = typeof b == 'string' ? b.toUpperCase() : b;
  return compare(itemA, itemB);
};

const compareDates = (a, b) => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  const isValidA = isValidDate(dateA);
  const isValidB = isValidDate(dateB);

  if (isValidA && isValidB) {
    return compare(dateA, dateB) * -1;
  } else {
    if (isValidA && !isValidB) return 1;
    else if (!isValidA && isValidB) return -1;
    else return 0;
  }
};

const compareArrays = (arr1 = [], arr2) => {
  // Arrays with different size are not equal
  if (arr1.length !== arr2.length) return false;

  for (let item1 of arr2) {
    if (typeof item1 === 'object') {
      const found = arr1.some(i => compareObjects(i, item1));
      if (!found) {
        return false;
      }
    } else {
      if (!arr1.includes(item1)) {
        return false;
      }
    }
  }
  return true;
};

const compareObjects = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (!(obj1 instanceof Object) || !(obj2 instanceof Object)) return false;

  for (let key in obj1) {
    if (!Object.prototype.hasOwnProperty.call(obj2, key)) return false;

    if (obj1[key] === obj2[key]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof obj1[key] !== 'object') return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!compareObjects(obj1[key], obj2[key])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (let key in obj2) if (!Object.prototype.hasOwnProperty.call(obj1, key)) return false;
  return true;
};

const compareTo = (a, b, type) => {
  switch (type) {
    case 'string':
      return compareStrings(a, b);
    case 'date':
      return compareDates(a, b);
    case 'number':
      return compareNumbers(a, b);
    case 'version':
      return compareVersion(a, b);
    default:
      return compareStrings(a, b);
  }
};

const sort = (data, sortBy) => {
  const { field, type, isDescending } = sortBy;
  // Prevent error when field is empty
  if (!field) return data;

  const path = field.split('.');
  return data.sort((a, b) => {
    const result =
      path.length === 2
        ? compareTo(get(a, `${path[0]}.${path[1]}`), get(b, `${path[0]}.${path[1]}`), type)
        : compareTo(a[field], b[field], type);
    return isDescending ? result * -1 : result;
  });
};

const getSortByQueryType = (sortByQuery, columns) => {
  for (let column of columns) {
    if (column.id === sortByQuery.id) {
      return column.type;
    }
  }
  return 'string';
};

const generateSortByConfig = (sortBy, initialSortColumn, columns) => {
  if (sortBy) {
    return {
      field: sortBy.id,
      type: getSortByQueryType(sortBy, columns),
      isDescending: sortBy.desc ? sortBy.desc : false
    };
  } else {
    return {
      field: initialSortColumn.id,
      type: initialSortColumn.type,
      isDescending: false
    };
  }
};

/**
 * Get a property from a given object
 * @param {*} object Target object
 * @param {*} path Property path with dots
 */
const get = (object, path) => {
  let obj = { ...object };
  path = path.split('.');
  while (obj && path.length) {
    const nextPath = path.shift();
    // Look up for array index on path
    if (isNaN(parseInt(nextPath))) {
      obj = obj[nextPath];
    } else {
      // Support path with array index
      obj = obj[parseInt(nextPath)];
    }
  }
  return obj;
};

/**
 * Set value to given path. Path will be created if doesn't exist.
 * @param {*} obj target object
 * @param {*} path Property path with dots
 * @param {*} val value to put
 */
const set = (obj, path, val) => {
  path = path.split('.');
  let current = obj;
  let key = null;
  while (path.length) {
    key = path.shift();
    if (!current[key]) {
      current[key] = {};
    }
    if (path.length) {
      current = current[key];
    }
  }
  if (!isNullish(key)) {
    current[key] = val;
  }
};

/**
 * Set object value to given path and return the object, not changing the original. Path will be created if doesn't exist.
 * @param {*} obj target object
 * @param {*} path Property path with dots
 * @param {*} val value to put
 */
const setCopy = (obj, path, val) => {
  path = path.split('.');
  const modifiedObj = deepClone(obj);
  let current = modifiedObj;
  let key = null;
  while (path.length) {
    key = path.shift();
    if (!current[key]) {
      current[key] = {};
    }
    if (path.length) {
      current = current[key];
    }
  }
  if (!isNullish(key)) {
    current[key] = val;
  }

  return modifiedObj;
};

/**
 * Remove an object property using a given path
 * @param {*} obj
 * @param {*} path
 */
const remove = (obj, path) => {
  path = path.split('.');
  let current = obj;
  let key = null;
  while (path.length) {
    key = path.shift();
    if (!current[key]) {
      current[key] = {};
    }
    if (path.length) {
      current = current[key];
    }
  }
  if (!isNullish(key)) {
    delete current[key];
  }
};

/**
 * Receive a list of objects and return a merge of them
 * PS.: this function will override any child array
 * @param  {...any} arg objects to merge
 */
const mergeObjs = (...arg) => {
  let firstObject = arg.length > 0 ? { ...arg[0] } : {};
  for (let objectIndex = 1; objectIndex < arg.length; ++objectIndex) {
    let currentObject = arg[objectIndex];
    if ('object' === typeof currentObject) {
      for (let keyCurrentObject in currentObject) {
        if (Object.prototype.hasOwnProperty.call(currentObject, keyCurrentObject)) {
          if ('object' === typeof currentObject[keyCurrentObject] && !Array.isArray(currentObject[keyCurrentObject])) {
            firstObject[keyCurrentObject] = mergeObjs(firstObject[keyCurrentObject], currentObject[keyCurrentObject]);
          } else {
            firstObject[keyCurrentObject] = currentObject[keyCurrentObject];
          }
        }
      }
    }
  }
  return firstObject;
};

/**
 * Return a copy of the target object that can be modified without changing original
 * @param {*} object target object
 */
const deepClone = object => {
  // Recursive call to copy inner objects.
  if (typeof object === 'object') {
    // Deep clone array items
    if (Array.isArray(object)) {
      const newArray = [];
      object.forEach(element => {
        newArray.push(deepClone(element));
      });
      return newArray;
    }

    // Deep clone objects
    const newObject = {};
    for (let key in object) {
      newObject[key] = deepClone(object[key]);
    }

    // Return primitive values
    return newObject;
  }

  // Return primitive values (int, string, bool etc)
  return object;
};

/**
 * Verifies if a given object has a property
 * @param {*} object Target object
 * @param {*} path Property path with dots
 */
const has = (object, path) => {
  return typeof get(object, path) !== 'undefined';
};

const generateAlert = (msg, type, duration, callToAction, hideType = false) => {
  const colors = {
    error: 'danger',
    info: 'info',
    success: 'success',
    warning: 'warning'
  };

  return {
    message: msg,
    visible: true,
    type: type,
    color: colors[type],
    duration,
    callToAction,
    hideType
  };
};

// TODO: Move to storageUtils
const getShowMask = () => {
  // LocalStorage only returns a string. The comparison with "true" will ensure we'll return a bool
  const showMask = window.localStorage.getItem(LOCAL_STORAGE_MASK) === 'true';
  return showMask;
};

// TODO: Move to storageUtils
const setShowMask = showMask => {
  window.localStorage.setItem(LOCAL_STORAGE_MASK, showMask);
};

// TODO: Move to storageUtils
const purgeShowMask = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_MASK);
};
// TODO: Move to solutionUtils
const sortSolutions = solutions => {
  // Table value is duplicated due to retrocompability
  return solutionsMeta
    .filter(solution => solutions.find(item => solution.id === item))
    .map(item => ({
      id: item.id,
      name: item.name,
      table: item.table,
      type: item.type,
      cms: item.cms
    }));
};

const capitalize = string => {
  return string.replace(/\b\w/g, c => c.toUpperCase());
};

const splitCamelCase = string => {
  return string.replace(/([a-z])([A-Z])/g, '$1 $2');
};

const toCamelCase = str => {
  return str.replace(/[-_](.)/g, (_, c) => c.toUpperCase());
};

/**
 * Check if given object has null values of any kind
 * @param {*} x
 */
const isNullish = x =>
  [
    v => v === '',
    v => v === null,
    v => v === undefined,
    v => v && typeof v === 'object' && !Object.keys(v).length
  ].some(f => f(x));

/**
 * Get array removing null values
 * @param {*} array
 */
const getArrayWithoutNull = array => {
  const newArray = [];
  array.forEach(v => {
    v = removeNullValue(v);
    if (v !== undefined) newArray.push(v);
  });
  return newArray.length ? newArray : undefined;
};

/**
 * Get object removing null values
 * @param {*} object
 */
const getObjectWithoutNull = object => {
  let hasValues = false;
  let newObj = {};
  Object.entries(object).forEach(([k, v]) => {
    v = removeNullValue(v);
    if (v !== undefined) {
      newObj[k] = v;
      hasValues = true;
    }
  });
  return hasValues ? newObj : undefined;
};

/**
 * Remove all attribute with null values
 * @param {*} obj
 */
const removeNullValue = value => {
  if (Array.isArray(value)) return getArrayWithoutNull(value);
  if (value && typeof value === 'object') return getObjectWithoutNull(value);
  return isNullish(value) ? undefined : value;
};

// TODO: Move to presetUtils
const replaceModels = (oldModels, presetModels) => {
  return presetModels.map(currentModel => {
    const oldModelRef = oldModels.find(oldModel => oldModel.type === currentModel.type);
    if (oldModelRef) {
      oldModelRef.version = currentModel.version;
      return { ...oldModelRef };
    } else {
      return {
        ...currentModel,
        config: {
          async_queue_size: 3,
          backend: 0,
          target: 0
        }
      };
    }
  });
};

const hasErrorsCheck = errors => {
  let hasErrors = false;

  if (!errors) {
    return false;
  }

  Object.keys(errors).forEach(errorKey => {
    if (errors[errorKey].length > 0) {
      hasErrors = true;
    }
  });

  return hasErrors;
};

const generateSuffix = day => {
  switch (parseInt(day)) {
    case 1:
    case 21:
    case 31:
      return 'st';
    case 2:
    case 22:
      return 'nd';
    case 3:
    case 23:
      return 'rd';
    default:
      return 'th';
  }
};

const generateMontlyReport = report => {
  const customDay = report.customDay ? report.customDay[0] : 1;
  const chosenDay = report.selectedDay === 'custom' ? customDay : report.selectedDay;
  const setence = 'day of each month';

  if (chosenDay) {
    const when = parseInt(chosenDay) === 31 ? 'Last' : `${chosenDay}${generateSuffix(chosenDay)}`;
    return `${when} ${setence}`;
  } else {
    return `1st ${setence}`;
  }
};

const generateRecurringReportSummary = report => {
  if (report.frequency === 'daily') {
    return 'everyday';
  } else if (report.frequency === 'weekly') {
    return `every ${report.selectedDay ? report.selectedDay : 'sunday'}`;
  } else if (report.frequency === 'monthly') {
    return generateMontlyReport(report);
  } else {
    return 'not defined';
  }
};

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

/**
 * Returns the list of selected items through diff analize between
 * the current list and the new list
 * @param {*} currentList current list
 * @param {*} newList updated list
 * @param {*} entity name of the entitly collection
 */
const getListIntersection = (currentList, newList, entity, setAlert) => {
  let removedItems = 0;
  let selectedItems = [...currentList];

  currentList.forEach((currentItem, index) => {
    const itemExists = newList.some(newItem => currentItem.id === newItem.id);

    if (!itemExists) {
      selectedItems.splice(index - removedItems, 1);
      removedItems = removedItems + 1;
    }
  });

  //  (show as warning)
  if (setAlert && removedItems > 0) {
    const messagePrefix = `${removedItems} item${removedItems > 1 ? 's' : ''} (${entity})`;
    const message = `${messagePrefix} from previous selection ${
      removedItems > 1 ? 'are' : 'is'
    } not available for this date period`;
    setAlert(generateAlert(message, 'warning'));
  }

  return selectedItems;
};

const renderTrimmedString = (value, key) => {
  if (!value) return '--';

  if (typeof value !== 'string') {
    return value;
  }

  const trimmedValue = value.length > 20 ? `${value.slice(0, 10)}...${value.slice(-5)}` : value;

  const elementId = typeof key !== 'undefined' ? `displayName-${key}` : `displayName-${value}`;

  return (
    <div id={elementId}>
      {trimmedValue}
      <UncontrolledTooltip placement="top" target={`#${elementId}`}>
        {value}
      </UncontrolledTooltip>
    </div>
  );
};

const mergeArraysByField = (array1, array2, field) => {
  const newArray = deepClone(array2);

  array1.forEach(item1 => {
    if (!newArray.find(item2 => item2[field] === item1[field])) {
      newArray.push(item1);
    }
  });

  return newArray;
};

const escapeQuotes = str => str.replace(/["']/g, match => '\\' + match);

const generateErrorAlert = (msg, duration) => {
  return generateAlert(msg, 'error', duration);
};

const utils = {
  fieldValidation,
  generateAlert,
  generateErrorAlert,
  get,
  set,
  setCopy,
  remove,
  mergeObjs,
  mergeArraysByField,
  has,
  sort,
  deepClone,
  generateSortByConfig,
  compareObjects,
  compareVersion,
  compareArrays,
  removeNullValue,
  isNullish,
  hasErrorsCheck,
  getListIntersection,
  sortSolutions, // TODO: Move to storageUtils
  getShowMask, // TODO: Move to storageUtils
  setShowMask, // TODO: Move to storageUtils
  purgeShowMask, // TODO: Move to storageUtils
  generateRecurringReportSummary, // TODO: Move to reportUtils
  replaceModels, // TODO: Move to presetUtils
  // Strings
  compareStrings,
  capitalize,
  splitCamelCase,
  toCamelCase,
  camelToSnakeCase,
  isValidDateString,
  renderTrimmedString,
  escapeQuotes
};

export default utils;
