import React, { useState, useEffect } from 'react';

import ReportSettings from '../../reportDetails/reportSettings';
import Timezone from '../../timezone';
import Calendar from '../../calendar/calendar';
import calendarUtils from '../../../utils/calendar';

const defaultSolution = { id: 'facev2', name: 'Audience' };
const frequencies = [
  { value: 'single', label: 'Single' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' }
];

const TimeParameters = ({ report, updateReport }) => {
  const [selectedTimezone, setSelectedTimezone] = useState(report.timezone);
  const [reportSettings, setReportSettings] = useState({ ...report });

  useEffect(() => {
    const updatedReport = {
      ...report,
      ...reportSettings,
      timezone: selectedTimezone
    };
    updateReport({ ...updatedReport });
  }, [reportSettings, selectedTimezone]);

  const updatePeriod = data => {
    // Get only the week day numbers to send to Query API
    if (data['filterByWeekday']) {
      data['filterByWeekday'] = data['filterByWeekday'].map(day => day.value);
    }
    setReportSettings({ ...reportSettings, ...data });
  };

  const getWeekDayObjects = weekDays => {
    return calendarUtils.weekDaysOptions.filter(day => {
      return weekDays.some(e => {
        return day.value === e;
      });
    });
  };

  return (
    <div className="time-parameters">
      <div className="form-group">
        <label>Range</label>
        <Calendar
          startDate={report.startDate}
          endDate={report.endDate}
          startTime={report.filterByTime.start}
          endTime={report.filterByTime.end}
          weekDays={getWeekDayObjects(report.filterByWeekday)}
          onSelect={updatePeriod}
        />
      </div>
      <div className="form-group">
        <label>Timezone</label>
        <Timezone
          selected={{ label: selectedTimezone, value: selectedTimezone }}
          onSelect={tz => setSelectedTimezone(tz)}
        />
      </div>
      <ReportSettings
        report={reportSettings}
        solution={report.solution || defaultSolution}
        frequencies={frequencies}
        onChange={data => setReportSettings(data)}
        customIntervals={[{ id: 'minute', name: 'Minute' }, ...calendarUtils.defaultIntervals]}
        isEditable
      />
    </div>
  );
};

export default TimeParameters;
