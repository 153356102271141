import React, { useEffect, useState } from 'react';
import InfiniteLoader from '../../../components/multiSelector/infinityLoader';
// import IconButton from '../../../components/iconButton';
// import ActionButtons from '../../../components/actionButtons';
import { Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';
import CustomButton from '../../../components/customButton';
import SiteGroupSelector from '../../../components/filterSelector/SiteGroupSelector';
import { renderSolution } from '../../../utils/tables';
import API from '../../../services/api';
import utils from '../../../utils';
import { setAlert } from '../../../redux/dashboard/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedCompany } from '../../../redux/company/actions';

const Sites = ({ siteGroup, createMode }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedSiteGroup, setSelectedSiteGroup] = useState(null);
  const [currentFilter, setCurrentFilter] = useState('');
  const [totalSites, setTotalSites] = useState(0);

  const dispatch = useDispatch();
  const company = useSelector(getSelectedCompany);

  useEffect(() => {
    setTotalSites(0);
    onLoading();
  }, [company, siteGroup]);

  const toggle = () => {
    setModalOpen(!modalOpen);
  };

  const transferSites = () => {
    const sitesId = selectedSites.map(site => site.id);
    API.transferSites(sitesId, selectedSiteGroup.value.replaceAll('"', ''))
      .then(() => {
        onLoading();
        dispatch(setAlert(utils.generateAlert('Sites were transfered.', 'success')));
      })
      .catch(err => {
        console.error(err);
        dispatch(setAlert(utils.generateAlert('Sites could not be transfered. Something went wrong!', 'error')));
      })
      .finally(() => {
        setSelectedSiteGroup(null);
      });
  };

  const getSize = () => {
    return createMode ? 56 : 40;
  };

  const onLoading = (nextPageToken = '', searchingBy = '') => {
    const query = encodeURI(`displayName ILIKE "%${searchingBy}%" OR id ILIKE "%${searchingBy}%"`);
    const filter = searchingBy !== '' ? query : '';

    if (filter !== currentFilter) {
      setSites([]);
    }

    return API.getSites(company.id, siteGroup.id, nextPageToken, filter).then(res => {
      if (filter === '') {
        setTotalSites(res.totalSize);
      }
      const fetchedSites = res.sites.map(site => ({ ...site, id: site.id.trim() }));

      // Reset list when filter changes
      if (filter !== currentFilter) {
        setCurrentFilter(filter);
        setSites(fetchedSites);
      } else {
        setSites(fetchedSites);
      }

      return res;
    });
  };

  const renderSite = site => {
    const fields = [
      {
        id: 'displayName',
        name: 'Site'
      },
      {
        id: 'siteGroup',
        name: 'Site Group'
      }
    ];

    const newSite = fields.map(field => {
      const key = `${field.id}-${site.id}`;
      return (
        <div key={key} className="cell">
          <div id={key}>{site[field.id]}</div>
          <UncontrolledTooltip placement="left" target={`#${key}`}>
            <div>
              <span className="d-flex">
                <span>{field.name}</span>
              </span>
            </div>
          </UncontrolledTooltip>
        </div>
      );
    });
    newSite.push(renderSolution(site.solution, `solution-icon-${site.id}`));
    return newSite;
  };

  // const renderBulkActionButtons = selectedSites => {
  //   return createMode ? null : <IconButton icon={'transfer'} disabled={selectedSites.length === 0} onPress={toggle} />;
  // };

  // const renderActionButtons = site =>
  //   createMode ? null : (
  //     <div className={'action-buttons'}>
  //       <ActionButtons
  //         buttons={[
  //           {
  //             id: `navigate-to-site-${site.id}`,
  //             icon: 'navigate',
  //             key: 'Navigate',
  //             tooltip: 'Navigate',
  //             onPress: () => window.location.assign('#/sites')
  //           }
  //         ]}
  //       />
  //     </div>
  //   );

  if (!sites) {
    return null;
  }
  return (
    <>
      <Modal className="modal-transfer-sites" isOpen={modalOpen} fade={false} toggle={toggle} centered>
        <ModalBody>
          <div className="transfer-sites">
            <h3>{`Transfer ${selectedSites.length > 1 ? 'sites' : 'site'} to another Site Group`}</h3>
            <h4>
              {`Select a site group to transfer ${selectedSites.length} ${
                selectedSites.length > 1 ? ' sites' : ' site'
              }`}
            </h4>
            <SiteGroupSelector
              onSelect={(selectedValue, sitegroups) => {
                setSelectedSiteGroup(sitegroups.options.find(sitegroup => sitegroup.value === selectedValue));
              }}
              selected={selectedSiteGroup}
              filterObject={{}}
            />
          </div>
          <div className="button-group">
            <CustomButton
              title={'Cancel'}
              classes={'btn-secondary'}
              handleClick={() => {
                toggle();
                setSelectedSiteGroup(null);
              }}
            />
            <CustomButton
              title={'Confirm'}
              disabled={!selectedSiteGroup}
              loading={false}
              classes={'btn-primary'}
              handleClick={() => {
                toggle();
                transferSites();
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      {/* TODO: Uncomment the actions when transfer site endpoint is ready to use */}

      <InfiniteLoader
        items={sites}
        allSelected={false}
        displayField="displayName"
        customRender={createMode ? renderSite : null}
        emptyMessage="No Sites found for this site group"
        customSearchPlaceHolder="Search Sites"
        sortField="displayName"
        onSelect={setSelectedSites}
        listSize={sites.length * getSize()}
        itemHeight={getSize()}
        renderCheckbox={false}
        // renderBulkActionButtons={renderBulkActionButtons}
        // renderActionButtons={renderActionButtons}
        onLoading={onLoading}
        totalItems={totalSites}
        shouldUpdate={siteGroup.id ? true : false}
      />
    </>
  );
};

export default Sites;
