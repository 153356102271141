/*
 * action types
 */

import types from '../types.js';

/*
 * action creators
 */

export const setLoading = payload => {
  return { type: types.SET_LOADING, payload };
};

export const setAlert = payload => {
  return { type: types.SET_ALERT, payload };
};

export const dismissAlert = () => {
  return { type: types.DISMISS_ALERT, payload: false };
};

export const getAlert = state => {
  return state.dashboardReducer.alert;
};

export const getLoading = state => {
  return state.dashboardReducer.loading;
};

export const getFeatureFlags = state => {
  return state.dashboardReducer.featureFlags;
};

export const setFeatureFlags = payload => {
  return { type: types.SET_FEATURE_FLAGS, payload };
};
