import type from '../types';
import { setState } from '../../utils/redux';

const initialState = {
  user: null
};

const userReducer = (state = initialState, action) => {
  const { SET_USER, UPDATE_USER, UPDATE_USER_PREFERENCES, CLEAR_USER } = type;

  switch (action.type) {
    case SET_USER:
    case UPDATE_USER:
      return setState(state, 'user', action.payload);
    case UPDATE_USER_PREFERENCES:
      return setState(state, 'user', { ...state.user, preferences: action.payload });
    case CLEAR_USER:
      return setState(state, 'user', null);
    default:
      return state;
  }
};

export default userReducer;
