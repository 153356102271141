import { call, put } from 'redux-saga/effects';
import API from '../services/api';
import types from '../redux/types';

export function* fetchIntegrations() {
  try {
    // Variables defined to load standard integrations
    const page = '';
    const filter = '';
    const pageSize = 200;
    const integrations = yield call(API.getIntegrations, page, filter, pageSize);
    if (integrations) {
      yield put({ type: types.LOAD_INTEGRATIONS, payload: integrations.integrations });
    }
  } catch (error) {
    console.error("Couldn't fetch integrations");
  }
}
