import types from '../types';

export const setUser = payload => {
  return { type: types.SET_USER, payload };
};

export const updateUser = payload => {
  return { type: types.UPDATE_USER, payload };
};

export const updateUserPreferences = payload => {
  return { type: types.UPDATE_USER_PREFERENCES, payload };
};

export const clearUser = () => {
  return { type: types.CLEAR_USER };
};

export const getUser = state => {
  return state.userReducer.user;
};
