const setState = (state, node, payload) => {
  return {
    ...state,
    ...{
      [node]: payload
    }
  };
};

const appendState = (state, node, payload) => {
  return {
    ...state,
    ...{
      [node]: [...state[node], payload]
    }
  };
};

const generateTypes = typeList => {
  const result = {};
  typeList.forEach(type => {
    result[type] = type;
  });
  return result;
};

export { setState, appendState, generateTypes };
