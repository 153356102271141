import { setState } from '../../utils/redux.js';
import types from '../types.js';

const initialState = {
  notifications: [],
  notificationTypes: [],
  notificationPreferences: []
};

const projectReducer = (state = initialState, action) => {
  const { LOAD_NOTIFICATIONS, LOAD_NOTIFICATION_TYPES, LOAD_NOTIFICATION_PREFERENCES } = types;

  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      return setState(state, 'notifications', action.payload);
    case LOAD_NOTIFICATION_TYPES:
      return setState(state, 'notificationTypes', action.payload);
    case LOAD_NOTIFICATION_PREFERENCES:
      return setState(state, 'notificationPreferences', action.payload);
    default:
      return state;
  }
};

export default projectReducer;
