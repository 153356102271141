import config from '../../config';
import API from '../api';

const { baseUrl } = config.coreApi;

//#region TYPES
/**
 * @typedef Report
 * @type {Object}
 * @property {string[]} advertisers - List of advertisers.
 * @property {string} createdOn - Date the report was created.
 * @property {string[]} daysToSend - Days to send the report.
 * @property {string[]} deviceIds - List of device IDs.
 * @property {string[]} email - List of emails to send the report.
 * @property {string} endDate - End date for the report.
 * @property {string[]} fields - Fields included in the report.
 * @property {{end: string, start: string}} filterByTime - Time filter for the report.
 * @property {number[]} filterByWeekday - Weekdays to filter the report by (0 = Sunday, 6 = Saturday).
 * @property {string} format - Format of the report.
 * @property {string} frequency - Frequency of the report.
 * @property {string} id - Report ID.
 * @property {string} interval - Interval for the report.
 * @property {string} lastReportUrl - URL of the last report.
 * @property {string} lastSent - Date when the report was last sent.
 * @property {string[]} media - Media included in the report.
 * @property {string} name - Name of the report.
 * @property {string} projectId - Project ID associated with the report.
 * @property {string} startDate - Start date for the report.
 * @property {string} status - Status of the report.
 * @property {string} table - Table associated with the report.
 * @property {string} timezone - Timezone of the report.
 * @property {string} type - Type of the report.
 */

/**
 * @typedef UpdateReportObject
 * @type {Object}
 * @property {string[]} daysToSend - Days to send the report.
 * @property {string[]} email - List of emails to send the report.
 * @property {string} endTime - End time of the report.
 * @property {{end: string, start: string}} filterByTime - Time filter for the report.
 * @property {number[]} filterByWeekday - Weekdays to filter the report by (0 = Sunday, 6 = Saturday).
 * @property {string} format - Format of the report.
 * @property {string} frequency - Frequency of the report.
 * @property {string} interval - Interval for the report.
 * @property {string} name - Name of the report.
 * @property {string} project - Project associated with the report.
 * @property {string} report - Report identifier.
 * @property {string} speedUnit - Speed unit used in the report.
 * @property {string} timezone - Timezone of the report.
 * @property {string} type - Type of the report.
 */
//#endregion

//#region CREATE
/**
 * Creates a recurrent report.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {string} tid - Tablet ID.
 * @returns {{"reportUrl": "string","status": {"statusCode": "string","statusMessage": "string"}}} Recurrent report
 */
const createRecurrentReport = async (cid, pid, tid, report) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/tables/${tid}/reports`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: JSON.stringify(report)
  }).then(res => res.json());
};

/**
 * Creates a single report.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {string} tid - Tablet ID.
 * @returns {Report} Single report
 */
const createSingleReport = async (cid, pid, tid, report) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/tables/${tid}/report`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: JSON.stringify(report)
  }).then(res => res.json());
};

/**
 * Pause a recurrent report.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {string} tid - Tablet ID.
 * @param {string} rid - Report ID.
 * @returns {Report} Paused report
 */
const pauseReport = async (cid, pid, tid, rid) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/tables/${tid}/reports/${rid}:pause`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers
  }).then(res => res.json());
};

/**
 * Restart a recurrent report.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {string} tid - Tablet ID.
 * @param {string} rid - Report ID.
 * @returns {Report} Restarted report
 */
const restartReport = async (cid, pid, tid, rid) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/tables/${tid}/reports/${rid}:restart`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers
  }).then(res => res.json());
};
//#endregion

//#region READ
/**
 * List reports.
 * @param {string} cid - Company ID.
 * @param {string} pid - Site group ID.
 * @returns {{reports: Report[]}} List of reports.
 */
const getReports = async (cid, pid) => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/companies/${cid}/projects/${pid}/reports`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};
//#endregion

//#region UPDATE
/**
 * Cancel a recurrent report.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {string} rid - Report ID.
 * @param {UpdateReportObject} report - Update Report Object.
 * @returns {Report} Updated report
 */
const updateReport = async (cid, pid, rid, report) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/reports/${rid}`;

  return fetch(url, {
    method: 'PUT',
    headers: API.headers,
    body: JSON.stringify(report)
  }).then(res => res.json());
};
//#endregion

//#region DELETE
/**
 * Cancel a recurrent report.
 * @param {string} cid - Company ID.
 * @param {string} pid - Project ID.
 * @param {string} tid - Tablet ID.
 * @param {string} rid - Report ID.
 * @returns {Report} Canceled report
 */
const cancelReport = async (cid, pid, tid, rid) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/projects/${pid}/tables/${tid}/reports/${rid}`;
  return fetch(url, {
    method: 'DELETE',
    headers: API.headers
  }).then(res => res.json());
};
//#endregion

export {
  createRecurrentReport,
  createSingleReport,
  pauseReport,
  restartReport,
  getReports,
  updateReport,
  cancelReport
};
