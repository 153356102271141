import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../redux/dashboard/actions';
import { getSelectedCompany } from '../../../redux/company/actions';
import { getUser } from '../../../redux/user/actions';

import api from '../../../services/api';
import utils from '../../../utils';
import { initializeCredential } from '../../../utils/integrations';

import components from '../../../components';
const { SearchBox, CustomButton, CustomInput, Timezone, EmptyState } = components;

import CMSAccount from '../cmsAccount';

// input validations
const validations = {
  displayName: [{ key: 'required', value: true }]
};

const AccountsTab = ({ credentials, selectedIntegration, loadCredentials }) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [filteredCredentials, setFilteredCredentials] = useState(credentials);
  const [values, setValues] = useState(initializeCredential(selectedIntegration.credentialFields));
  const [displayName, setDisplayName] = useState('');
  const [errors, setErrors] = useState({ 'new-mappings-display-name': '' });

  const dispatch = useDispatch();
  const company = useSelector(getSelectedCompany);
  const user = useSelector(getUser);

  useEffect(() => {
    setFilteredCredentials(credentials);
  }, [credentials]);

  useEffect(() => {
    // Update error accumulator when updating form
    // Add values to the listeners if we need additional validation
    setErrors({
      'new-mappings-display-name': utils.fieldValidation(displayName, validations['displayName']).join(', ')
    });
  }, [displayName]);

  useEffect(() => {
    setValues(initializeCredential(selectedIntegration.credentialFields));
  }, [selectedIntegration]);

  const searchDevices = searchBy => {
    if (searchBy !== '') {
      setFilteredCredentials(
        credentials.filter(mapping => {
          const credentialHas = option => mapping[option].toUpperCase().includes(searchBy.toUpperCase());
          return credentialHas('displayName') || credentialHas('id');
        })
      );
    } else {
      setFilteredCredentials(credentials);
    }
  };

  const createAccount = () => {
    if (utils.hasErrorsCheck(errors)) {
      dispatch(
        setAlert(utils.generateAlert('Could not create Credential! Is something wrong with the form?', 'error'))
      );
    } else {
      const cmsName = selectedIntegration.cmsClient;

      api
        .createCredential(company.id, {
          displayName,
          creatorId: user.id,
          credential: { [`${cmsName}`]: values }
        })
        .then(() => {
          dispatch(setAlert(utils.generateAlert('Credential created successfuly!', 'success')));
          loadCredentials();
          setValues(initializeCredential(selectedIntegration.credentialFields));
          setDisplayName('');
          setShowCreateForm(false);
        })
        .catch(err => {
          console.error(err);
          dispatch(setAlert(utils.generateAlert('Could not create Credential! Please try again.', 'error')));
        });
    }
  };

  const updateForm = (field, val) => {
    setValues({ ...values, [field]: val });
  };

  const renderCustomFields = () => {
    return selectedIntegration.credentialFields.map(field => (
      <div className="col" key={field}>
        <div className="form-group">
          <label>{field}</label>
          {field === 'pop_timezone' ? (
            <Timezone
              onSelect={value => {
                updateForm(field, value);
              }}
            />
          ) : (
            <CustomInput
              id={`new-mappings-${field}`}
              type="text"
              value={values[field]}
              isEditable
              onChange={e => {
                updateForm(field, e.target.value);
              }}
            />
          )}
        </div>
      </div>
    ));
  };

  return (
    <div>
      <h2>New Account</h2>
      {showCreateForm ? (
        <div className="account-fields">
          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label>Display Name</label>
                <CustomInput
                  id={`new-mappings-display-name`}
                  type="text"
                  value={displayName}
                  isEditable
                  autoFocus
                  errorMessage={errors['new-mappings-display-name']}
                  onChange={e => {
                    setDisplayName(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form-row">{renderCustomFields()}</div>
          <div className="form-row justify-content-end">
            <CustomButton
              classes="btn-danger left-button"
              title="Cancel"
              handleClick={() => setShowCreateForm(!showCreateForm)}
            />
            <CustomButton classes="btn-secondary" title="Create" handleClick={createAccount} />
          </div>
        </div>
      ) : (
        <CustomButton
          classes="btn-primary"
          title="Add new Account"
          handleClick={() => {
            setShowCreateForm(!showCreateForm);
          }}
        />
      )}
      <div className="horizontal-separator" />
      {credentials.length > 0 ? (
        <>
          <h2>{`List of registered accounts (${filteredCredentials.length}/${credentials.length})`}</h2>
          <SearchBox placeholder="Search by Display Name" className="outline" onChange={searchDevices} />
          {filteredCredentials.length > 0 ? (
            <div>
              {filteredCredentials.map(credential => (
                <CMSAccount
                  key={credential.id}
                  credential={credential}
                  credentialFields={selectedIntegration.credentialFields}
                  loadCredentials={loadCredentials}
                  cmsName={selectedIntegration.cmsClient}
                />
              ))}
            </div>
          ) : (
            <div className="no-results-found">
              <p>No credentials were found</p>
            </div>
          )}
        </>
      ) : (
        <EmptyState
          icon={'addUser'}
          title={`You don't have any accounts.`}
          details={`Create your first account by clicking the button "Add new Account".`}
        />
      )}
    </div>
  );
};

export default AccountsTab;
