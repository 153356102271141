import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedProject, getProjects, selectProject } from '../../redux/projects/actions';
import { getSelectedCompany } from '../../redux/company/actions';
import InfinityLoader from '../dropdownSearch/infinityLoader';
import { normalizeProject } from '../../utils/project';
import API from '../../services/api';
import utils from '../../utils';

import './styles.scss';

const ProjectSelector = () => {
  const [currentProjects, setCurrentProjects] = useState([]);
  const [totalSiteGroups, setTotalSiteGroups] = useState(0);
  const [currentFilter, setCurrentFilter] = useState('');
  // Initial project from Redux
  const projects = useSelector(getProjects);
  const company = useSelector(getSelectedCompany);
  const selectedProject = useSelector(getSelectedProject);
  const dispatch = useDispatch();

  useEffect(() => {
    if (projects.length > 0) {
      onLoadProjects();
    }
  }, [projects]);

  const handleProjectSelector = projectId => {
    API.getSiteGroup(company.id, projectId)
      .then(project => {
        dispatch(selectProject(normalizeProject(project)));
      })
      .catch(e => {
        console.error(e);
      });
  };

  const onLoadProjects = (nextPageToken, searchingBy = '') => {
    const query = encodeURI(`displayName ILIKE "%${searchingBy}%" OR id ILIKE "%${searchingBy}%"`);
    const filter = searchingBy !== '' ? query : '';

    if (filter !== currentFilter) {
      setCurrentProjects([]);
    }

    return API.getSiteGroups(40, nextPageToken, filter).then(res => {
      setTotalSiteGroups(res.totalSize);
      const fetchedProjects = res.projects.map(project => ({
        label: `${project.displayName} - ${project.id}`,
        value: project.id
      }));

      // Reset list when filter changes
      if (filter !== currentFilter) {
        setCurrentFilter(filter);
        setCurrentProjects(fetchedProjects);
      } else {
        const newProjects = utils.mergeArraysByField(fetchedProjects, currentProjects, 'value');
        setCurrentProjects(newProjects);
      }

      return res.nextPageToken;
    });
  };

  if (projects && selectedProject) {
    return (
      <div className="project-selector">
        <InfinityLoader
          title={'Search Project'}
          label="Site Group"
          leftAlign
          outline={false}
          selected={{
            label: `${selectedProject.displayName} - ${selectedProject.id}`,
            value: selectedProject.id
          }}
          onSelect={handleProjectSelector}
          onLoading={onLoadProjects}
          totalSize={totalSiteGroups}
          options={currentProjects}
        />
      </div>
    );
  }

  return null;
};

export default ProjectSelector;
